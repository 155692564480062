<template>
  <div class="p-6 space-y-6">
    <analytics-container-wrapper class="space-y-6">
      <filter-wrapper
        @apply="getItems"
        @reset="handleReset"
        ref="filterWrapper"
      >
        <div>
          <t-datepicker
            :placeholder="`From - To`"
            multiple
            :monthsPerView="2"
            v-model="pagination.date"
            range
            :clearable="false"
            userFormat="Y-m-d"
            :weekStart="6"
          />
        </div>
      </filter-wrapper>
      <Table
        title="Item sales"
        :records="`${pagination.total || 0} records`"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
        has_export
        has_import
        has_download_pdf
        :exportParams="pagination"
        :fields="fields"
      >
        <template #head>
          <th>Product</th>
          <th>Sold</th>
          <th>Item code</th>
          <th>Unit price</th>
          <th>Total price</th>
          <!-- <th>Expiry date</th> -->
          <!-- <th>Production date</th> -->
        </template>
        <template #tbody v-if="!load">
          <TableLoad :rows="5" v-for="i in 4" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length">
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <router-link
                :to="`/products/${(item.product || {}).id}-${
                  (item.product || {}).name_ar ||
                  (item.product || {}).name_en ||
                  `product-${(item.product || {}).created_at}`
                }`"
                class="flex items-center gap-3"
              >
                <div class="w-10 h-10">
                  <img
                    :src="
                      (item.product || {}).image ||
                      require('@/assets/images/placeholder.png')
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    alt="product image"
                    class="w-full h-full rounded-md border object-cover border-gray-100"
                  />
                </div>
                <div class="flex-1 max-w-sm">
                  <p class="line-clamp-2 whitespace-normal">
                    {{ (item.product || {})["name_" + $i18n.locale] }}
                  </p>
                  <p>
                    {{ (item.product || {}).barcode || "-" }}
                  </p>
                </div>
              </router-link>
            </td>

            <td>
              {{
                (item.product || {}).unit_type === "weight"
                  ? GenerateUnit(item.total_weight || 0)
                  : (item.total_qty || 0) + " Piece"
              }}
            </td>
            <td>{{ (item.product || {}).item_code || "-" }}</td>
            <td>{{ item.price || 0 }} SAR</td>
            <td>{{ item.total_price || 0 }} SAR</td>

            <!-- <td v-html="createTime((item.product || {}).exp_date)"></td>
            <td v-html="createTime((item.product || {}).prod_date)"></td> -->
          </tr>
        </template>

        <template #tbody v-else>
          <tr>
            <td colspan="5" class="!text-center">There are no data display</td>
          </tr>
        </template>
      </Table>
    </analytics-container-wrapper>
  </div>
</template>

<script>
import FilterWrapper from "@/components/basics/FilterWrapper.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import AnalyticsContainerWrapper from "@/components/AnalyticsContainerWrapper.vue";

export default {
  components: { FilterWrapper, AnalyticsContainerWrapper },
  name: "InvoiceProducts",

  data() {
    return {
      items: [],
      load: false,

      pagination: {
        page: 1,
        total: 0,
        total_pages: 0,
        date: [],
      },
      disables: {},
      fields: [],
    };
  },
  async created() {
    this.$set(this.pagination, "date", [
      this.$route.query.date ||
        this.$moment().add(-1, "days").format("YYYY-MM-DD"),
      this.$route.query.date || this.$moment().format("YYYY-MM-DD"),
    ]);
    Promise.all([await this.getItems()]).finally(() => (this.load = true));
  },
  watch: {
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
    "pagination.page"() {
      this.getItems();
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getItems() {
      try {
        const { total, total_pages, date, ...others } = this.pagination;
        const { result } = await this.getRoute({
          name: "invoices/products",
          options: {
            ...others,
            search_key: this.search,
            from: date?.[0],
            to: date?.[1],
            store_id: this.store_id,
          },
        });
        const { data, pagination } = result.products;
        this.fields = result.export_fields;

        this.items = data;
        this.$set(this.pagination, "total", pagination?.total);
        this.$set(this.pagination, "total_pages", pagination?.total_pages);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        const filterWrapper = this.$refs.filterWrapper;
        filterWrapper?.resetAll();
        this.changeIsCalled(false);
      }
    },
    handleReset() {
      const { page, ...others } = this.pagination;

      for (const key in others) {
        const item = others[key];
        if (typeof item === "number") {
          this.$set(this.pagination, key, 0);
        } else if (Array.isArray(item)) {
          this.$set(this.pagination, key, []);
        } else {
          this.$set(this.pagination, key, null);
        }
      }

      this.getItems();
    },
  },
};
</script>
