<template>
  <div class="p-6 pt-0">
    <Table
      title="Ledger report"
      :records="`${pagination.total || 0} records`"
      :pagination="pagination"
      :total_pages="pagination.total_pages"
      v-model="pagination.page"
    >
      <template #head>
        <tr>
          <th class="!w-10 !px-2"></th>
          <th>Name</th>
          <th>Type</th>
          <th>Debit</th>
          <th>Credit</th>
          <th>Balance</th>
          <th>Actions</th>
        </tr>
      </template>
      <template #tbody v-if="!load">
        <TableLoad :rows="7" v-for="i in 4" :key="i" />
      </template>
      <template #tbody v-else-if="items.length">
        <ledger-item-tree v-for="item in items" :key="item.id" :item="item" />
      </template>
      <template #tbody v-else>
        <tr>
          <td colspan="100%" class="!text-center">There are no data display</td>
        </tr>
      </template>
    </Table>
  </div>
</template>

<script>
import AddAccount from "@/components/accounting/AddAccount.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import LedgerItemTree from "@/components/accounting/LedgerItemTree.vue";

export default {
  components: { AddAccount, LedgerItemTree },
  name: "LedgerReports",
  data() {
    return {
      load: false,
      items: [],
      pagination: { page: 1 },
    };
  },
  created() {
    Promise.all([this.getItems()]).finally(() => {
      this.load = true;
    });
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),

    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;

        const { result } = await this.getRoute({
          name: "accounts",
          options: {
            ...others,
            is_active: others.is_active
              ? others.is_active === "active"
                ? 1
                : 0
              : null,
            search_key: this.search,
            store_id: this.store_id,
          },
        });
        const { data, pagination } = result.accounts;
        this.items = data.map((e) => ({
          ...e,
          padding: 0,
        }));
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.changeIsCalled(false);
      }
    },
  },
};
</script>
