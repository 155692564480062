<template>
  <div>
    <SubPagesHeader title="New purchase order">
      <section>
        <main class="w-full">
          <div class="space-y-6" v-if="load">
            <div class="flex-1 relative">
              <input
                type="text"
                name="search"
                id="search"
                class="w-full bg-white border border-gray-200 h-12 rounded-md px-4 text-sm pl-10 rtl:pl-auto rtl:pr-10"
                placeholder="Search in products..."
                v-model="search_key"
                @keyup.enter.stop="getItems"
              />
              <span
                class="absolute top-0 left-0 rtl:left-auto rtl:right-0 h-full w-10 flex items-center justify-center text-gray-500 text-sm"
              >
                <i
                  class="fa-solid fa-spinner fa-spin"
                  v-if="search_disabled"
                ></i>
                <i class="fa-solid fa-search" v-else></i>
              </span>
            </div>
            <div class="w-full grid gap-4 grid-wrapping">
              <figure
                class="w-full rounded-md bg-white p-3 space-y-4 flex flex-col"
                v-for="(item, idx) in items"
                :key="idx"
              >
                <figcaption class="w-full flex items-center justify-center">
                  <img
                    :src="
                      item.image || require('@/assets/images/placeholder.png')
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    alt="product image"
                    class="rounded-md w-full h-36 object-contain align-middle bg-gray-100"
                  />
                </figcaption>
                <blockquote class="flex-1 space-y-2">
                  <h3
                    class="text-sm text-gray-800 font-semibold rtl:font-bold line-clamp-2"
                  >
                    {{ item["name_" + $i18n.locale] }}
                  </h3>
                  <ul
                    class="list-none grid grid-cols-2 gap-2"
                    v-if="item.is_meat"
                  >
                    <!-- <li>
                    <p
                      class="
                        text-xs text-gray-500
                        font-medium
                        rtl:font-semibold
                      "
                    >
                      Weight:
                    </p>
                  </li>
                  <li>
                    <p
                      class="
                        text-sm text-gray-600
                        font-semibold
                        text-right
                        rtl:text-left
                      "
                    >
                      {{ GenerateUnit(item.approx_weight) }}
                    </p>
                  </li> -->
                    <li>
                      <p
                        class="text-xs text-gray-500 font-medium rtl:font-semibold"
                      >
                        Price per kilo:
                      </p>
                    </li>
                    <li>
                      <p
                        class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                      >
                        {{ GeneratePriceWithoutCode(item.price) }} SAR
                      </p>
                    </li>
                    <!-- <li>
                    <p
                      class="
                        text-xs text-gray-500
                        font-medium
                        rtl:font-semibold
                      "
                    >
                      Total price:
                    </p>
                  </li>
                  <li>
                    <p
                      class="
                        text-sm text-gray-600
                        font-semibold
                        text-right
                        rtl:text-left
                      "
                    >
                      {{ getApproxPrice(item.price, 1, item.approx_weight) }}
                      SAR
                    </p>
                  </li> -->
                  </ul>
                  <ul class="list-none grid grid-cols-2 gap-2" v-else>
                    <!-- <li>
                    <p
                      class="
                        text-xs text-gray-500
                        font-medium
                        rtl:font-semibold
                      "
                    >
                      Quantity:
                    </p>
                  </li>
                  <li>
                    <p
                      class="
                        text-sm text-gray-600
                        font-semibold
                        text-right
                        rtl:text-left
                      "
                    >
                      {{ item.qty }}
                    </p>
                  </li> -->
                    <li>
                      <p
                        class="text-xs text-gray-500 font-medium rtl:font-semibold"
                      >
                        Price:
                      </p>
                    </li>
                    <li>
                      <p
                        class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                      >
                        {{ GeneratePriceWithoutCode(item.price) }} SAR
                      </p>
                    </li>
                    <!-- <li>
                    <p
                      class="
                        text-xs text-gray-500
                        font-medium
                        rtl:font-semibold
                      "
                    >
                      Total price:
                    </p>
                  </li>
                  <li>
                    <p
                      class="
                        text-sm text-gray-600
                        font-semibold
                        text-right
                        rtl:text-left
                      "
                    >
                      {{ getApproxPrice(item.price, 1, item.qty) }} SAR
                    </p>
                  </li> -->
                  </ul>
                </blockquote>
                <button
                  class="w-full py-2 px-4 rounded bg-primary/10 text-primary whitespace-nowrap text-center text-sm font-medium rtl:font-semibold"
                  @click="addToCart(item.id)"
                >
                  <i
                    class="fa-solid fa-shopping-cart mr-0 rtl:mr-0 rtl:ml-1"
                  ></i>
                  Add to cart
                </button>
              </figure>
            </div>
          </div>
          <div v-if="!load" class="w-full grid grid-wrapping gap-4">
            <figure v-for="l in 6" :key="l" class="w-full">
              <figcaption
                class="w-full h-60 bg-gray-200 animate-pulse"
              ></figcaption>
              <blockquote class="pt-4">
                <div
                  class="w-4/5 h-4 rounded bg-gray-200 animate-pulse mb-4"
                ></div>
                <div class="w-1/5 h-3 rounded bg-gray-200 animate-pulse"></div>
              </blockquote>
            </figure>
          </div>
          <div v-if="load && items.length === 0" class="w-full space-y-6">
            <img
              src="@/assets/images/empty-products.webp"
              alt="mo products image"
              class="w-full max-w-sm table mx-auto"
            />
            <p class="text-lg font-semibold text-gray-600 text-center">
              There are no products yet
            </p>
          </div>
        </main>
      </section>
      <div
        class="w-full max-w-sm bg-white h-full fixed top-0 right-0 z-20 hidden transition cart-container"
        ref="cart"
        @click="closeCart"
      >
        <div class="sticky top-0 h-screen flex flex-col z-[5] bg-white">
          <div>
            <header
              class="w-full border-b border-gray-100 p-4 flex items-center justify-between"
            >
              <div class="flex items-center gap-3">
                <h3
                  class="text-sm text-gray-800 font-semibold rtl:font-bold truncate"
                >
                  PO cart
                </h3>
              </div>
              <div class="actions-group items-center">
                <div>
                  <button
                    class="text-red-500 lg:hidden w-9 h-9 flex items-center justify-center rounded-full"
                    @click="openCloseCart"
                  >
                    <i class="fa-solid fa-times"></i>
                  </button>
                </div>
              </div>
            </header>
          </div>
          <div class="grid flex-1 p-4 w-full overflow-y-auto">
            <div class="">
              <div
                class="flex items-start gap-3 mt-4 border-b border-gray-100 py-4"
                v-for="(item, index) in cart || []"
                :key="index"
              >
                <div>
                  <div class="w-14 h-14">
                    <img
                      :src="
                        item.image || require('@/assets/images/placeholder.png')
                      "
                      @error="
                        $event.taregt.src = require('@/assets/images/placeholder.png')
                      "
                      alt="profile image"
                      class="w-full h-full object-cover rounded-md border border-gray-200"
                    />
                  </div>
                </div>
                <div class="flex-1">
                  <div class="flex items-center gap-2">
                    <div class="flex-1">
                      <h3
                        class="text-sm text-gray-800 font-semibold rtl:font-bold line-clamp-2"
                      >
                        {{ item.name_en }}
                      </h3>
                    </div>
                    <div class="self-start">
                      <button
                        class="p-1 text-xs text-red-500 font-medium rtl:font-semibold"
                        @click.prevent="deleteItem(item.id)"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                  <ul
                    class="list-none grid grid-cols-2 gap-1 mt-4"
                    v-if="item.is_meat"
                  >
                    <li>
                      <p
                        class="text-xs text-gray-500 font-medium rtl:font-semibold"
                      >
                        Total weight:
                      </p>
                    </li>
                    <li>
                      <input
                        type="number"
                        :name="'product-weight-' + index"
                        :id="'product-weight-' + index"
                        class="w-14 text-xs pr-0 rtl:pl-0 rtl:pr-4 px-4 h-8 rounded bg-gray-100 text-center [margin-inline-start:auto] block"
                        v-model.number="item.approx_weight"
                        min="1"
                        v-if="true"
                      />
                      <!-- v-if="$route.query.supplier_type" -->
                      <p
                        class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                        v-else
                      >
                        {{ GenerateUnit(item.approx_weight) }}
                      </p>
                    </li>
                    <li>
                      <p
                        class="text-xs text-gray-500 font-medium rtl:font-semibold"
                      >
                        Unit price:
                      </p>
                    </li>
                    <li>
                      <input
                        type="number"
                        :name="'product-price-' + index"
                        :id="'product-price-' + index"
                        class="w-14 text-xs pr-0 rtl:pl-0 rtl:pr-4 px-4 h-8 rounded bg-gray-100 text-center [margin-inline-start:auto] block"
                        v-model.number="item.price"
                        min="1"
                        v-if="true"
                      />
                      <!-- v-if="$route.query.supplier_type" -->
                      <p
                        class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                        v-else
                      >
                        {{ GeneratePriceWithoutCode(item.price) }} SAR
                      </p>
                    </li>
                    <li>
                      <p
                        class="text-xs text-gray-500 font-medium rtl:font-semibold"
                      >
                        Total price:
                      </p>
                    </li>
                    <li>
                      <p
                        class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                      >
                        <!-- // $route.query.supplier_type ? 1 : item.qty, -->
                        {{
                          GeneratePriceWithoutCode(
                            getApproxPrice(item.price, 1, item.approx_weight)
                          )
                        }}
                        SAR
                      </p>
                    </li>
                  </ul>
                  <ul class="list-none grid grid-cols-2 gap-1 mt-4" v-else>
                    <li>
                      <p
                        class="text-xs text-gray-500 font-medium rtl:font-semibold"
                      >
                        Total QTY
                      </p>
                    </li>
                    <li>
                      <p
                        class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                      >
                        {{ item.qty }}
                      </p>
                    </li>
                    <li>
                      <p
                        class="text-xs text-gray-500 font-medium rtl:font-semibold"
                      >
                        Unit price:
                      </p>
                    </li>
                    <li>
                      <input
                        type="number"
                        :name="'product-weight-' + index"
                        :id="'product-weight-' + index"
                        class="w-14 text-xs pr-0 rtl:pl-0 rtl:pr-4 px-4 h-8 rounded bg-gray-100 text-center [margin-inline-start:auto] block"
                        v-model.number="item.price"
                        min="1"
                        v-if="true"
                      />
                      <!-- v-if="$route.query.supplier_type" -->
                      <p
                        class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                        v-else
                      >
                        {{ GeneratePriceWithoutCode(item.price) }} SAR
                      </p>
                    </li>
                    <li>
                      <p
                        class="text-xs text-gray-500 font-medium rtl:font-semibold"
                      >
                        Total price:
                      </p>
                    </li>
                    <li>
                      <p
                        class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                      >
                        {{
                          GeneratePriceWithoutCode(
                            getApproxPrice(item.price, item.qty, 1)
                          )
                        }}
                        SAR
                      </p>
                    </li>
                  </ul>

                  <div class="mt-2 table">
                    <div class="flex items-center gap-2">
                      <div>
                        <button
                          class="w-8 h-8 rounded bg-primary text-white"
                          @click="controlItemQty('increase', item.id)"
                        >
                          <i class="fa-solid fa-plus text-xs"></i>
                        </button>
                      </div>
                      <div>
                        <input
                          type="number"
                          name="proitem"
                          id="proitem"
                          class="w-14 text-xs pr-0 rtl:pl-0 rtl:pr-4 px-4 h-8 rounded bg-gray-100"
                          v-model.number="item.qty"
                          min="1"
                        />
                      </div>
                      <div>
                        <button
                          class="w-8 h-8 rounded bg-primary text-white"
                          @click="controlItemQty('decrease', item.id)"
                        >
                          <i class="fa-solid fa-minus text-xs"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer class="w-full p-4 pb-0">
            
            <div class="w-full p-4 border-t border-gray-200">
              <button
                class="w-full py-3 px-4 rounded-md bg-primary text-white text-center font-medium rtl:font-semibold text-sm"
                @click.prevent="openDoOrder"
                :disabled="!(cart || []).length"
              >
                Checkout {{ getTotal() }} SAR
              </button>
            </div>
          </footer>
        </div>
      </div>
      <button
        class="py-2 px-4 rounded bg-primary text-white border border-gray-200 fixed bottom-5 right-5 z-[5] text-sm gap-2 inline-flex items-center justify-center"
        @click="openCloseCart"
      >
        <span
          class="absolute -top-2 -left-2 rtl:-right-2 rtl:left-auto py-0.5 px-1.5 rounded-full text-xs font-medium text-white bg-red-500 ring-2 ring-gray-100"
        >
          {{ (cart || []).length || 0 }}
        </span>
        <i class="fa-solid fa-shopping-bag"></i>
        <span>View cart</span>
      </button>
      <Modal
        v-if="order_active"
        :title="`Checkout - ${getTotal() || 0} SAR`"
        @close="
          (e) => {
            order_active = e;
            add_errors = {};
          }
        "
      >
        <template>
          <div class="w-full space-y-4 p-6">
            <div class="w-full space-y-3">
              <div class="flex items-center gap-3 justify-between">
                <label
                  for="store"
                  class="font-medium rtl:font-semibold text-gray-600 text-sm"
                >
                  Reference no
                </label>
                <a
                  href="javascript:void(0)"
                  class="flex items-center gap-2 text-teal-600 font-medium text-xs group"
                  @click="GenerateNewRefNumber"
                >
                  <span class="transition group-hover:rotate-2">
                    <i class="fa-solid fa-sync"></i>
                  </span>
                  <span>Generate new</span>
                </a>
              </div>
              <input
                type="text"
                name="ref_no"
                id="ref_no"
                class="text-xs p-4 rounded bg-gray-100 block w-full"
                v-model.number="add_data.ref_no"
                placeholder="000000"
                autofocus
              />

              <span class="error">
                {{ (add_errors.ref_no || []).join(" ") }}
              </span>
            </div>
             <div class="form-group space-y-3">
          <label>Order date</label>
          <input type="date" v-model="add_data.order_date" />
          <p class="form-error">{{ add_errors["order_date"] }}</p>
        </div>
            <div class="w-full space-y-3">
              <label
                for="warehouse"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                Supplier
              </label>
              <t-rich-select
                :options="suppliers"
                placeholder="Select"
                :text-attribute="'name'"
                value-attribute="id"
                :clearable="true"
                :closeOnSelect="true"
                v-model="add_data.supplier_id"
              />
              <span class="error">
                {{ (add_errors.supplier_id || []).join(" ") }}
              </span>
            </div>

            <div class="w-full" v-if="false">
              <div class="flex gap-2 flex-wrap items-center">
                <div
                  v-for="(item, index) in payments"
                  :key="index"
                  class="checkitem"
                >
                  <input
                    type="radio"
                    name="payments"
                    :id="item"
                    hidden
                    class="hidden"
                    :value="item"
                    v-model="add_data.payment_option"
                  />
                  <label :for="item" class="space-y-2 border-none w-20 h-12">
                    <img
                      :src="getPaymentImage(item)"
                      alt="payment item"
                      class="w-full h-full object-contain table mx-auto"
                    />
                  </label>
                </div>
              </div>
              <p class="error">
                {{ (add_errors.payment_option || []).join(" ") }}
              </p>
            </div>
            <div
              class="flex items-start gap-3 mt-4 border-b border-gray-100 py-4"
              v-for="(item, index) in cart || []"
              :key="index"
            >
              <div>
                <div class="w-14 h-14">
                  <img
                    :src="
                      item.image || require('@/assets/images/placeholder.png')
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    alt="profile image"
                    class="w-full h-full object-contain rounded-md border border-gray-200"
                  />
                </div>
              </div>
              <div class="flex-1">
                <div class="flex items-center gap-2">
                  <div class="flex-1">
                    <h3
                      class="text-sm text-gray-800 font-semibold rtl:font-bold line-clamp-2"
                    >
                      {{ item.name_en }}
                    </h3>
                  </div>
                  <div class="self-start">
                    <button
                      class="p-1 text-xs text-red-500 font-medium rtl:font-semibold"
                      @click.prevent="deleteItem(item.id)"
                    >
                      Delete
                    </button>
                  </div>
                </div>
                <p
                  class="text-xs text-gray-600 font-medium rtl:font-semibold mt-1 flex justify-between"
                >
                  <span class="font-medium">
                    <span>
                      {{ item.price }}
                      SAR
                    </span>
                    per
                    <span v-if="item.is_meat">{{
                      GenerateUnit(item.approx_weight)
                    }}</span>
                    <span v-else>{{ item.qty }} Piece</span>
                  </span>
                  <span>
                    {{
                      GeneratePriceWithoutCode(
                        getApproxPrice(
                          item.price,
                          item.is_meat ? 1 : item.qty,
                          item.is_meat ? item.approx_weight : 1
                        )
                      )
                    }}
                    SAR
                  </span>
                </p>

                <div class="space-y-1">
                  <p class="error" v-if="add_errors[`products.${index}.id`]">
                    {{ (add_errors[`products.${index}.id`] || []).join(" ") }}
                  </p>
                  <p
                    class="error"
                    v-if="add_errors[`products.${index}.weight`]"
                  >
                    {{
                      (add_errors[`products.${index}.weight`] || []).join(" ")
                    }}
                  </p>
                  <p class="error" v-if="add_errors[`products.${index}.qty`]">
                    {{ (add_errors[`products.${index}.qty`] || []).join(" ") }}
                  </p>
                  <p class="error" v-if="add_errors[`products.${index}.price`]">
                    {{
                      (add_errors[`products.${index}.price`] || []).join(" ")
                    }}
                  </p>
                </div>
              </div>
            </div>
            <!--    <table class="w-full">
              <tbody class="divide-y divide-dashed divide-gray-200">
                <tr>
                <td
                    class="text-sm text-gray-800 text-left font-medium rtl:font-semibold py-3"
                  >
                    Products count
                  </td>
                  <td
                    class="text-gray-400 text-sm text-right font-medium rtl:font-semibold py-3"
                  >
                    {{ (cart || []).length }}
                  </td>
                </tr>
                <tr v-if="cart.some((e) => e.is_meat)">
                  <td
                    class="text-sm text-gray-800 text-left font-medium rtl:font-semibold py-3"
                  >
                    Total weights
                  </td>
                  <td
                    class="text-gray-400 text-sm text-right font-medium rtl:font-semibold py-3"
                  >
                    {{ GenerateUnit(getProductsWeights(cart)) }}
                  </td>
                </tr>
                </tr>

                <tr>
                  <td
                    class="text-sm text-gray-800 text-left font-medium rtl:font-semibold py-3"
                  >
                    Total
                  </td>
                  <td
                    class="text-gray-400 text-sm text-right font-medium rtl:font-semibold py-3"
                  >
                    {{ getTotal() }} SAR
                  </td>
                </tr>
              </tbody>
            </table> -->
            <div class="flex items-start justify-start gap-2 flex-wrap">
              <div class="flex-1">
                <button
                  class="w-full py-3 px-4 text-sm font-medium rtl:font-semibold text-white bg-primary rounded-md"
                  :disabled="add_disabled"
                  @click.prevent="doOrder"
                >
                  <i
                    class="fa-solid fa-spinner fa-spin"
                    v-if="add_disabled"
                  ></i>
                  Request order
                </button>
              </div>
            </div>
          </div>
        </template>
      </Modal>
    </SubPagesHeader>
  </div>
</template>

<script>
import Modal from "@/components/basics/Modal.vue";
import SubPagesHeader from "@/components/basics/SubPagesHeader.vue";

import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: { Modal, SubPagesHeader },
  name: "WarehouseProducts",
  data() {
    return {
      items: [],
      load: false,
      order_active: false,
      add_data: {
        payment_option: "cash",
        order_status: "create",
        products: [],
        ref_no: undefined,
      },
      payments: ["cash", "visa", "mada"],
      add_errors: {},
      stores: [],
      suppliers: [],
      add_disabled: false,
      search_disabled: false,
      search_key: null,
      type: this.$route.query.type,
    };
  },

  watch: {
    order_active(val) {
      this.GenerateNewRefNumber();
    },
  },
  async created() {
    await Promise.all([
      this.getItems(),
      this.getStores(),
      this.getSuppliers(),
    ]).finally(() => (this.load = true));
  },
  computed: {
    ...mapGetters("warehouseCart", {
      globalCart: "wareCart",
    }),
    module_type() {
      return localStorage.getItem("store_module") === "butchery";
    },

    cart() {
      const user = this.$store.state.user;
      const module = localStorage.getItem("store_module") || null;
      const result = this.globalCart(`${user.user_type}_${user.id}`) || [];
      return result;
    },
    user() {
      return this.$store.state.user || {};
    },
    itemId() {
      const id = this.$route.params.id;

      this.add_data[
        this.type === "warehouse" ? "warehouse_id" : "wholesaler_id"
      ] = id;
      return id;
    },
  },
  methods: {
    ...mapActions("warehouseCart", [
      "addItemsInsideWareCart",
      "removeItemFromWareCart",
    ]),
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("warehouseCart", ["GENERATE_WARE_CART"]),
    async getStores() {
      try {
        const { result } = await this.getRoute("stores");
        this.stores = result.stores;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getSuppliers() {
      try {
        const { result } = await this.getRoute({
          name: "users",
          options: {
            user_type: "supplier",
          },
        });
        this.suppliers = result.users;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },

    async getItems() {
      try {
        this.search_disabled = true;
        const module = JSON.parse(localStorage.getItem("module"));
        const { result } = await this.getRoute({
          name: "products",
          options: {
            is_purchasable: 1,
            is_active: 1,
            search_key: this.search_key,
            store_id: this.store_id,
          },
        });
        this.items = result.products.map((e) => ({
          ...e,
          is_meat: e.unit_type === "weight",
          qty: e.qty ?? 1,
        }));
      } catch (error) {
        console.log(error);
        // this.createAlert(result?.message, type);
        // this.$router.push("/orders/new");
      } finally {
        this.search_disabled = false;
      }
    },
    addToCart(id) {
      if (this.hasTrialDays) {
        this.createAlert(
          "You're still in trial days can't make order at that moment, try again in live mode",
          "info"
        );
        return;
      }

      const product = this.items.find((e) => e.id === id);
      if (!product) {
        this.createAlert("Invalid product", "error");
        return;
      }

      const currentCart = this.cart;
      const currentProduct = currentCart?.find((e) => e.id === id);
      // Check the quantity of the product before adding it to the cart
      // if (currentProduct) {
      //   if (currentProduct.qty === +product.qty) {
      //     this.createAlert(`Max quantity is ${product.qty}`, "info");
      //     return;
      //   }
      // }
      this.addItemsInsideWareCart({
        product: {
          ...product,
          approx_weight: currentProduct ? currentProduct.approx_weight : 1,
          is_meat: product.unit_type === "weight",
        },
        id,
      }).then(() => {
        this.createAlert("Added to cart successfully");
      });
    },
    controlItemQty(type, id) {
      const product = this.items.find((e) => e.id === id);
      if (!product) {
        this.createAlert("Invalid product", "error");
        return;
      }

      const currentCart = this.cart;
      const currentProduct = currentCart?.find((e) => e.id === id);
      // Check the quantity of the product before adding it to the cart

      // increase
      // decrease
      if (type === "increase") {
        this.addItemsInsideWareCart({
          product: {
            ...(currentProduct ? currentProduct : product),
            qty: currentProduct ? currentProduct.qty + 1 : 1,
          },
          id,
        });

        return;
      } else if (type === "decrease") {
        if (currentProduct) {
          if (currentProduct.qty <= 1) {
            this.createAlert(`Min quantity is 1`, "info");
            return;
          }
        }
        this.addItemsInsideWareCart({
          product: {
            ...(currentProduct ? currentProduct : product),
            type: "decrease",
          },
          id,
        });
      }
    },
    deleteItem(id) {
      const product = this.items.find((e) => e.id === id);
      if (!product) {
        this.createAlert("Invalid product", "error");
        return;
      }
      this.removeItemFromWareCart(id).then((result) =>
        this.createAlert(result)
      );
    },
    getTotal() {
      let total = 0;
      this.cart &&
        this.cart.forEach((product) => {
          total += this.getApproxPrice(
            product.price,
            product.is_meat ? 1 : +product.qty,

            product.is_meat ? +product.approx_weight : 1
          );
        });

      return this.GeneratePriceWithoutCode(total);
    },
    openDoOrder() {
      this.add_data.products = this.cart;
      this.order_active = true;
    },
    async doOrder() {
      if (this.hasTrialDays) {
        this.createAlert(
          "You're still in trial days can't make order at that moment, try again in live mode",
          "info"
        );
        return;
      }
      let fd = new FormData();

      this.add_disabled = true;
      this.add_errors = {};

      Object.keys(this.add_data).forEach((key) => {
        if (key === "products") {
          this.cart.forEach((product, idx) => {
            // if (this.$route.query.supplier_type) {
            if (product.is_meat)
              fd.append(`${key}[${idx}][weight]`, product.approx_weight);
            //            }
            fd.append(`${key}[${idx}][price]`, product.price);
            fd.append(`${key}[${idx}][qty]`, product.qty);
            fd.append(`${key}[${idx}][id]`, product.id);
          });
        } else if (this.add_data[key]) {
          fd.append(key, this.add_data[key]);
        }
      });

      fd.append("store_id", this.store_id);

      try {
        const { data } = await this.axios.post("orders/new", fd);
        const user = this.$store.state.user;
        this.createAlert(data.message);
        this.GENERATE_WARE_CART(`${user.user_type}_${user.id}`);
        this.order_active = false;
        this.add_data = {
          products: [],
          payment_option: "cash",
        };
        this.$router.push("/inventory-actions");
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.add_disabled = false;
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
    openCloseCart() {
      this.$refs.cart.classList.toggle("!block");
    },
    closeCart() {
      window.addEventListener("click", (e) => {
        if (e.target === this.$refs.cart) {
          this.$refs.cart.classList.remove("!block");
        }
      });
    },
    getPaymentImage(image) {
      try {
        return require(`@/assets/images/${image}.png`);
      } catch (error) {
        console.log(error);
      }
    },
    GenerateNewRefNumber() {
      const time = new Date().getTime();
      this.$set(this.add_data, "ref_no", time);
    },
  },
};
</script>
