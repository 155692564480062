<template>
  <div class="p-6">
    <section class="w-full space-y-6">
      <Table
        title="Price list"
        :records="`${pagination.total || 0} records`"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
      >
        <template #actions>
          <div v-if="!hasTrialDays && hasPermission('add price_list')">
            <button
              class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
              @click.prevent="add_active = !add_active"
            >
              <i class="fa-solid fa-plus"></i>
              <span>New list</span>
            </button>
          </div>
        </template>
        <template #filter>
          <ul class="flex items-center">
            <li v-for="(item, index) in status" :key="index">
              <button
                class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
                @click.prevent="
                  changeFilterStatus(item === 'all' ? null : item, index)
                "
                :class="{
                  'active-tab':
                    pagination.is_active === (item === 'all' ? null : item),
                }"
                :disabled="disables[index]"
              >
                <spinner
                  size="w-4 h-4 inline-block"
                  class="mr-2 rtl:mr-0 rtl:ml-2"
                  v-if="disables[index]"
                />
                <!-- {{ $t(item.replace(/\s/g, "_")) }} -->
                {{ item }}
              </button>
            </li>
          </ul>
        </template>
        <template #head>
          <th>Name</th>
          <th>Customer groups</th>
          <th>Products</th>
          <th>Start date</th>
          <th>End date</th>
          <th>Status</th>
          <th>Latest update</th>
          <th>Actions</th>
        </template>
        <template #tbody v-if="!load">
          <TableLoad :rows="8" v-for="i in 4" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length">
          <tr v-for="(item, index) in items" :key="index">
            <td>
              {{ item["name_" + $i18n.locale] }}
            </td>
            <td>
              <div class="actions-group !flex-wrap min-w-[15rem]">
                <span
                  class="status text-gray-600 bg-gray-100"
                  v-for="(customer, index) in (
                    item.customer_groups || []
                  ).slice(0, 3)"
                  :key="index"
                  >{{ customer["name_" + $i18n.locale] || "N/A" }}</span
                >

                <span
                  class="status text-gray-600 bg-gray-100"
                  v-if="(item.customer_groups || []).length > 3"
                  >+{{ (item.customer_groups || []).length - 3 }}</span
                >
                <span
                  class="text-gray-600"
                  v-if="!(item.customer_groups || []).length"
                  >-</span
                >
              </div>
            </td>
            <td>
              <div class="actions-group !flex-wrap min-w-[15rem]">
                <span
                  class="status text-gray-600 bg-gray-100"
                  v-for="(product, index) in (item.products || []).slice(0, 3)"
                  :key="index"
                  >{{ product["name_" + $i18n.locale] || "N/A" }}</span
                >

                <span
                  class="status text-gray-600 bg-gray-100"
                  v-if="(item.products || []).length > 3"
                  >+{{ (item.products || []).length - 3 }}</span
                >
                <span class="text-gray-600" v-if="!(item.products || []).length"
                  >-</span
                >
              </div>
            </td>
            <td v-html="createTime(item.start_date)"></td>
            <td v-html="createTime(item.end_date)"></td>
            <td>
              <p class="status capitalize" :class="item_status[item.is_active]">
                {{ item.is_active === 1 ? "Active" : "Not active" }}
              </p>
            </td>

            <td v-html="createTime(item.updated_at || item.created_at)"></td>

            <td>
              <div class="actions-group">
                <div v-if="hasPermission('update price_list')">
                  <button
                    class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                    @click.prevent="changeStatus(item.id)"
                    :content="'Change status'"
                    v-tippy
                    :disabled="disables[`status_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`status_${item.id}`]"
                    />
                    <i class="fa-solid fa-right-left" v-else></i>
                  </button>
                </div>
                <div v-if="hasPermission('update price_list')">
                  <button
                    class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                    @click.prevent="showProductsList(item.id)"
                    :content="'Products'"
                    v-tippy
                    :disabled="disables[`status_${item.id}`]"
                  >
                    <i class="fa-solid fa-list"></i>
                  </button>
                </div>

                <div v-if="hasPermission('edit price_list')">
                  <button
                    class="w-8 h-8 bg-sky-500 text-white rounded"
                    @click.prevent="showUpdate(item.id)"
                    :content="'Update'"
                    v-tippy
                  >
                    <i class="fa-solid fa-pen"></i>
                  </button>
                </div>
                <div v-if="hasPermission('delete price_list')">
                  <button
                    class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                    @click.prevent="deleteItem(item.id)"
                    :disabled="disables[`delete_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`delete_${item.id}`]"
                    />
                    <span v-else> Delete </span>
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </template>

        <template #tbody v-else>
          <tr>
            <td colspan="8" class="!text-center">There are no data display</td>
          </tr>
        </template>
      </Table>
    </section>
    <Modal
      title="New list"
      v-if="add_active"
      @close="
        (e) => {
          add_active = e;
        }
      "
    >
      <template>
        <form class="p-6 space-y-4">
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors.name_ar">
                {{ add_errors.name_ar.join(" ") }}
              </p>
              <input
                type="text"
                name="name_ar"
                id="name_ar"
                placeholder="Name"
                required
                autocomplete="off"
                v-model="add_data.name_ar"
              />
              <label for="name_ar">Name | ar</label>
            </div>
          </div>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors.name_en">
                {{ add_errors.name_en.join(" ") }}
              </p>
              <input
                type="text"
                name="name_en"
                id="name_en"
                placeholder="Name"
                required
                autocomplete="off"
                v-model="add_data.name_en"
              />
              <label for="name_en">Name | en</label>
            </div>
          </div>

          <div class="w-full space-y-3">
            <label
              for="customers"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Customer groups
            </label>
            <select-with-limit
              placeholder="..."
              :text="'name_' + $i18n.locale"
              id="id"
              :clearable="true"
              :closeOnSelect="true"
              :fetch="getCustomers"
              v-model="add_data.customer_group_ids"
            />

            <span class="error">
              {{ (add_errors.customer_group_ids || []).join(" ") }}
            </span>
          </div>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors.start_date">
                {{ add_errors.start_date.join(" ") }}
              </p>
              <input
                type="date"
                name="start_date"
                id="start_date"
                autocomplete="off"
                v-model="add_data.start_date"
              />
              <label for="start_date">Start date</label>
            </div>
          </div>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors.end_date">
                {{ add_errors.end_date.join(" ") }}
              </p>
              <input
                type="date"
                name="end_date"
                id="end_date"
                autocomplete="off"
                v-model="add_data.end_date"
              />
              <label for="end_date">End date</label>
            </div>
          </div>
          <div>
            <button-with-loader
              :disabled="add_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="addItem"
            >
              Add
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
    <Modal
      :title="
        JSON.parse(
          JSON.stringify(edit_data['name_' + $i18n.locale] || 'Update group')
        )
      "
      v-if="edit_active"
      @close="
        (e) => {
          edit_active = e;
        }
      "
    >
      <template>
        <form class="p-6 space-y-4">
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors.name_ar">
                {{ edit_errors.name_ar.join(" ") }}
              </p>
              <input
                type="text"
                name="name_ar"
                id="name_ar"
                placeholder="Name"
                required
                autocomplete="off"
                v-model="edit_data.name_ar"
              />
              <label for="name_ar">Name | ar</label>
            </div>
          </div>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors.name_en">
                {{ edit_errors.name_en.join(" ") }}
              </p>
              <input
                type="text"
                name="name_en"
                id="name_en"
                placeholder="Name"
                required
                autocomplete="off"
                v-model="edit_data.name_en"
              />
              <label for="name_en">Name | en</label>
            </div>
          </div>

          <div class="w-full space-y-3">
            <label
              for="customers"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Customer groups
            </label>
            <select-with-limit
              placeholder="..."
              :text="'name_' + $i18n.locale"
              id="id"
              :clearable="true"
              :closeOnSelect="true"
              :fetch="getCustomers"
              v-model="add_data.customer_group_ids"
            />

            <span class="error">
              {{ (add_errors.customer_group_ids || []).join(" ") }}
            </span>
          </div>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors.start_date">
                {{ add_errors.start_date.join(" ") }}
              </p>
              <input
                type="date"
                name="start_date"
                id="start_date"
                autocomplete="off"
                v-model="add_data.start_date"
              />
              <label for="start_date">Start date</label>
            </div>
          </div>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors.end_date">
                {{ add_errors.end_date.join(" ") }}
              </p>
              <input
                type="date"
                name="end_date"
                id="end_date"
                autocomplete="off"
                v-model="add_data.end_date"
              />
              <label for="end_date">End date</label>
            </div>
          </div>
          <div>
            <button-with-loader
              :disabled="edit_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="editItem"
            >
              Save changes
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
    <Modal
      :title="
        JSON.parse(
          JSON.stringify(edit_data['name_' + $i18n.locale] || 'Update products')
        )
      "
      v-if="products_active"
      @close="
        (e) => {
          products_active = e;
        }
      "
      size="!max-w-screen-lg"
    >
      <template>
        <form class="p-6 space-y-4">
          <div class="w-full space-y-3">
            <label
              for="products"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Products
            </label>
            <select-with-limit
              placeholder="..."
              text="name_with_price"
              id="id"
              :clearable="true"
              :closeOnSelect="true"
              :fetch="getProductsGroup"
              v-model="products_data.product_group"
              @input="updateSelectedGroupProducts"
              ref="selectProductsGroup"
            />

            <span class="error">
              {{ (products_errors.products || []).join(" ") }}
            </span>
            <span class="error">
              {{ (products_errors.price_list_id || []).join(" ") }}
            </span>
          </div>
          <div class="grid overflow-x-auto">
            <table class="styled-table">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Price</th>
                  <th>Min value</th>
                  <th>Max value</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(product, index) in products_data.products"
                  :key="product.id"
                >
                  <td>
                    {{ product["name_" + $i18n.locale] || "-" }}
                    <p
                      class="error"
                      v-if="products_errors['products.' + index + '.id']"
                    >
                      {{
                        products_errors["products." + index + ".id"].join(" ")
                      }}
                    </p>
                  </td>
                  <td>
                    <Controllable v-model="product.price" />
                    <p
                      class="error"
                      v-if="products_errors['products.' + index + '.price']"
                    >
                      {{
                        products_errors["products." + index + ".price"].join(
                          " "
                        )
                      }}
                    </p>
                  </td>
                  <td>
                    <Controllable v-model="product.min_value" />
                    <p
                      class="error"
                      v-if="products_errors['products.' + index + '.min_value']"
                    >
                      {{
                        products_errors[
                          "products." + index + ".min_value"
                        ].join(" ")
                      }}
                    </p>
                  </td>
                  <td>
                    <Controllable v-model="product.max_value" />
                    <p
                      class="error"
                      v-if="products_errors['products.' + index + '.max_value']"
                    >
                      {{
                        products_errors[
                          "products." + index + ".max_value"
                        ].join(" ")
                      }}
                    </p>
                  </td>
                  <td>
                    <button
                      type="button"
                      class="text-red-500 font-semibold text-sm"
                      @click.prevent="removeProduct(product.id)"
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <button-with-loader
              :disabled="products_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="updateProducts"
            >
              Save changes
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>

    <!-- <AnalyticsContainerWrapper>
    </AnalyticsContainerWrapper> -->
  </div>
</template>
<script>
import Modal from "@/components/basics/Modal.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import AnalyticsContainerWrapper from "@/components/AnalyticsContainerWrapper.vue";
import Controllable from "@/components/Controllable.vue";
export default {
  name: "CustomerGroups",
  data() {
    return {
      load: false,
      items: [],
      stores: [],
      add_data: {},
      add_errors: {},
      add_disabled: false,
      add_active: false,

      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,

      products_data: {},
      products_errors: {},
      products_disabled: false,
      products_active: false,

      discount_data: {},
      discount_errors: {},
      discount_disabled: false,
      discount_active: false,

      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-500/10 text-red-500",
      },
      status: ["all", "active", "not active"],
      pagination: {
        is_active: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      disables: {},
      genders: ["male", "female"],
      customers: [],
      products: [],
      item: {
        id: null,
        discount_type: "fixed",
        discount_value: null,
        discount_start_date: null,
        discount_end_date: null,
      },
      products_group: [],
      productId: null,
    };
  },
  async created() {
    await Promise.all([
      this.getItems(),
      this.getCustomers(),
      this.getProducts(),
    ]).finally(() => (this.load = true));
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
    add_active(val, _) {
      if (!val) {
        this.add_data = {};
        this.add_errors = {};
      }
    },
    products_active(val, _) {
      if (!val) {
        this.products_data = {};
        this.products_errors = {};
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
    filterProducts() {
      const selectedProducts = this.discount_data.products;
      const mapProducts = selectedProducts.map((e) => e.id);
      const result = this.products.filter((e) => !mapProducts.includes(e.id));

      return result;
    },
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getCustomers(q) {
      try {
        const { result } = await this.getRoute({
          name: "customer_groups",
          options: {
            store_id: this.store_id,
            search_key: q,
            page: 1,
          },
        });
        const customers = result.customer_groups.data || [];
        return { results: customers };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getProducts() {
      try {
        const { result } = await this.getRoute({
          name: "products",
          options: {
            store_id: this.store_id,
          },
        });
        this.products = result.products?.map((product) => ({
          ...product,
          name_with_price:
            product["name_" + this.$i18n.locale] +
            " ( " +
            product.price +
            " SAR )",
        }));
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;

        const { result } = await this.getRoute({
          name: "price_lists",
          options: {
            ...others,
            is_active: others.is_active
              ? others.is_active === "active"
                ? 1
                : 0
              : null,
            search_key: this.search || this.$route.query.search,
            store_id: this.store_id,
          },
        });
        const { data, pagination } = result.price_lists;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.changeIsCalled(false);
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(
          `price_lists/update_is_active/${id}`
        );
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { data } = await this.axios.post(`price_lists/delete/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async addItem() {
      try {
        this.add_disabled = true;
        this.add_errors = {};

        const { data } = await this.axios.post(
          "price_lists/add",
          this.add_data
        );
        this.add_data = {};
        this.add_active = false;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.add_disabled = false;
      }
    },
    async editItem() {
      try {
        this.edit_disabled = true;
        this.edit_errors = {};

        const { data } = await this.axios.post(
          "price_lists/update",
          this.edit_data
        );
        this.edit_data = {};
        this.edit_active = false;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.edit_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.edit_disabled = false;
      }
    },
    async updateProducts() {
      try {
        this.products_disabled = true;
        this.products_errors = {};

        const { data } = await this.axios.post(
          "price_lists/update_products",
          this.products_data
        );
        this.products_data = {};
        this.products_active = false;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.products_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.products_disabled = false;
      }
    },

    async getProductsGroup(q) {
      try {
        const { result } = await this.getRoute({
          name: "products",
          options: {
            store_id: this.store_id,
            page: 1,
            search_key: q,
            is_active: 1,
          },
        });
        const products = result.products?.data?.map((product) => ({
          ...product,
          name_with_price:
            product["name_" + this.$i18n.locale] +
            " ( " +
            product.price +
            " SAR )",
        }));

        this.products_group = products;
        return { results: products };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },

    showUpdate(id) {
      const item = this.items.find((e) => e.id === id);
      if (!item) {
        this.createAlert("Item does not existed", "info");
        return;
      }
      this.edit_data = {
        ...item,
        customer_group_ids: item.customer_groups?.map((e) => e.id),
      };
      this.edit_active = true;
    },

    showProductsList(id) {
      const item = this.items.find((e) => e.id === id);
      if (!item) {
        this.createAlert("Item does not existed", "info");
        return;
      }
      this.products_data = {
        price_list_id: id,
        products: item.products || [],
        product_group: item.products.map((e) => e.id) || [],
      };
      this.products_active = true;
    },

    showProductsDiscount(id) {
      const item = this.items.find((e) => e.id === id);

      if (!item) {
        this.createAlert("Item does not existed", "info");
        return;
      }
      this.discount_data = {
        customer_group_id: id,
        products: item.products?.length
          ? item.products
          : [JSON.parse(JSON.stringify(this.item))],
      };
      this.discount_active = true;
    },

    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = "asc";
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == "asc" ? "desc" : "asc";
      }
      this.pagination.sort_key = key;
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`);
      });
    },

    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "is_active", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
    addNewItem() {
      if (!this.products.length) {
        this.createAlert("Products are not enough to add new one.", "error");
        return;
      }
      this.discount_data.products.push(JSON.parse(JSON.stringify(this.item)));
    },
    removeCurrentItem(index) {
      if (index === 0 && this.discount_data.products.length === 1) {
        this.createAlert("One products at least should be added!!", "error");
        return;
      }
      this.discount_data.products.splice(index, 1);
    },
    updateSelectedGroupProducts(values) {
      const products = this.products_data.products || [];
      const productsGroup = this.products_group || [];
      //  collect assigned products
      const filterProducts = products.filter((e) => values.includes(e.id));
      const productIds = filterProducts.map((e) => e.id);
      const newProducts = productsGroup
        .filter((e) => !productIds.includes(e.id))
        .filter((e) => values.includes(e.id))
        .map((e) => {
          const { id, name_ar, name_en, stock, price, product_type } = e;
          return {
            id,
            name_ar,
            name_en,
            min_value: stock,
            max_value: stock,
            price,
            product_type,
          };
        });

      const result = [...filterProducts, ...newProducts];
      this.products_data.products = result;
    },
    removeProduct(id) {
      const products = this.products_data.products.filter((e) => e.id !== id);
      this.products_data.product_group = products.map((e) => e.id);
      this.products_data.products = products;
    },
  },
  components: {
    Modal,
    ButtonWithLoader,
    AnalyticsContainerWrapper,
    Controllable,
  },
};
</script>
