<template>
  <div class="flex items-center flex-wrap gap-4">
    <p class="text-sm flex-1 text-gray-500">
      Show {{ pagination.current_page || 0 }} from
      {{ pagination.total_pages || 0 }} of {{ pagination.total || 0 }} item(s)
    </p>
    <div class="flex items-center gap-2">
      <button
        class="py-2 px-4 rounded bg-gray-100 text-gray-500 border border-gray-200 text-sm font-medium"
        type="button"
        :disabled="
          pagination.total_pages === 1 ||
          pagination.current_page === 1 ||
          disabled
        "
        @click.prevent="$emit('input', pagination.current_page - 1)"
      >
        <span>Previous</span>
      </button>
      <button
        class="py-2 px-4 rounded bg-primary text-white text-sm font-medium"
        type="button"
        :disabled="
          pagination.total_pages <= 1 ||
          pagination.total_pages === pagination.current_page ||
          disabled
        "
        @click.prevent="$emit('input', pagination.current_page + 1)"
      >
        <span>Next</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    pagination: {
      type: Object,
      default: {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
