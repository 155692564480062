<template>
  <div>
    <SubPagesHeader :title="'#' + item.id">
      <template #action>
        <t-dropdown
          :classes="{
            dropdown:
              'absolute right-0 rtl:left-0 rtl:right-auto w-screen !max-w-fit rounded ring-1 ring-gray-200 mt-2 overflow-hidden !top-full !bottom-auto bg-white whitespace-nowrap',
          }"
        >
          <div
            slot="trigger"
            slot-scope="{
              mousedownHandler,
              focusHandler,
              blurHandler,
              keydownHandler,
            }"
          >
            <button
              class="w-full p-4 flex items-center justify-center border border-gray-300 text-gray-600 rounded gap-3 text-sm font-semibold"
              aria-label="Actions"
              aria-haspopup="true"
              @mousedown="mousedownHandler"
              @focus="focusHandler"
              @blur="blurHandler"
              @keydown="keydownHandler"
            >
              <i class="fa-solid fa-ellipsis fa-sm"></i>
            </button>
          </div>

          <div slot-scope="{ hide }" class="!w-full !right-0 !left-0">
            <ul
              class="list-none ring-1 ring-gray-300 divide-y divide-gray-200 w-full"
            >
              <li
                class="py-2 px-4"
                v-show="
                  item.status === 'created' &&
                  item.payment_status !== 'paid' &&
                  false
                "
              >
                <a
                  href="javascript:void(0)"
                  class="inline-flex gap-4 text-gray-600 items-center text-sm"
                  @click.prevent="showPay"
                >
                  <span class="shrink-0">
                    <i class="fa-solid fa-hand-holding-dollar"></i>
                  </span>
                  <p class="font-semibold flex-1">Pay order now</p>
                </a>
              </li>
              <li
                class="py-2 px-4"
                v-show="
                  item.order_type === 'purchase' &&
                  item.status === 'received' &&
                  false
                "
              >
                <a
                  href="javascript:void(0)"
                  class="inline-flex gap-4 text-gray-600 items-center text-sm"
                  @click.prevent="showConfirmWeight"
                >
                  <span class="shrink-0">
                    <i class="fa-solid fa-weight-scale"></i>
                  </span>
                  <p class="font-semibold flex-1">Approve weights</p>
                </a>
              </li>
              <li
                class="py-2 px-4"
                v-show="
                  hasPermission('update purchase_orders') &&
                  item.order_type === 'purchase' &&
                  item.status === 'created'
                "
              >
                <a
                  href="javascript:void(0)"
                  class="inline-flex gap-4 text-gray-600 items-center text-sm"
                  @click.prevent="showConfirmWeight"
                >
                  <span class="shrink-0">
                    <i class="fa-solid fa-weight-scale"></i>
                  </span>
                  <p class="font-semibold flex-1">Update product values</p>
                </a>
              </li>
              <li
                class="py-2 px-4"
                v-show="
                  item.order_type === 'purchase' &&
                  item.status === 'received' &&
                  !item.is_closed
                "
              >
                <a
                  href="javascript:void(0)"
                  class="inline-flex gap-4 text-gray-600 items-center text-sm"
                  @click.prevent="closeItem(itemId)"
                >
                  <span class="shrink-0">
                    <i class="fa-solid fa-x"></i>
                  </span>
                  <p class="font-semibold flex-1">Close</p>
                </a>
              </li>
              <li
                class="py-2 px-4"
                v-show="
                  item.status === 'approved' &&
                  (hasPermission('update receive_purchase_orders') ||
                    hasPermission('update receive_transfer_orders'))
                "
              >
                <a
                  href="javascript:void(0)"
                  class="inline-flex gap-4 text-gray-600 items-center text-sm"
                  @click.prevent="changeStatus('received')"
                  :disabled="disables['received']"
                >
                  <span class="shrink-0">
                    <i class="fa-solid fa-check"></i>
                  </span>
                  <p class="font-semibold flex-1">Receive order</p>
                </a>
              </li>
              <li
                class="py-2 px-4"
                v-show="
                  item.order_type === 'purchase' &&
                  item.status === 'received' &&
                  !item.is_closed &&
                  hasPermission('add refund_orders')
                "
              >
                <a
                  href="javascript:void(0)"
                  class="inline-flex gap-4 text-gray-600 items-center text-sm"
                  @click.prevent="showRefund"
                >
                  <span class="shrink-0">
                    <i class="fa-solid fa-arrow-right-arrow-left"></i>
                  </span>
                  <p class="font-semibold flex-1">Refund</p>
                </a>
              </li>
              <li
                class="py-2 px-4"
                v-show="item.status !== 'canceled' && !item.is_closed"
              >
                <a
                  href="javascript:void(0)"
                  class="inline-flex gap-4 text-gray-600 items-center text-sm"
                  @click="printPDF"
                  :disabled="print_disabled"
                >
                  <span class="shrink-0">
                    <i class="fa-solid fa-print"></i>
                  </span>
                  <p class="font-semibold flex-1">Print PDF</p>
                </a>
              </li>
            </ul>
          </div>
        </t-dropdown>
      </template>
      <div v-if="load" class="mb-4">
        <div
          class="flex items-center justify-end gap-2 flex-wrap"
          v-if="item.status === 'created'"
        >
          <button
            type="button"
            class="py-2 px-3 rounded-full bg-teal-600 text-white font-medium text-sm flex items-center gap-2"
            @click.prevent="changeStatus('approved')"
            :disabled="disables['approved']"
            v-if="
              hasPermission('update approve_purchase_orders') ||
              hasPermission('update approve_refund_orders') ||
              hasPermission('update approve_transfer_orders')
            "
          >
            <i class="fa-solid fa-check fa-sm"></i>
            <span>Approve</span>
          </button>
          <button
            type="button"
            class="py-2 px-3 rounded-full bg-red-600 text-white font-medium text-sm flex items-center gap-2"
            @click.prevent="cancelOrder"
            :disabled="cancel_disabled"
            v-if="hasPermission('update cancel_purchase_orders')"
          >
            <i class="fa-solid fa-ban fa-sm"></i>
            <span>Cancel</span>
          </button>
        </div>
      </div>
      <!-- class="w-full flex flex-col md:flex-row gap-4 items-start p-6 rounded-xl bg-gray-100" -->
      <section
        class="w-full flex flex-col lg:flex-row gap-4 items-start"
        v-if="load"
      >
        <aside class="w-full lg:w-1/3 2xl:max-w-sm space-y-4">
          <card title="Status tracking">
            <ul class="divide-y divide-gray-200">
              <li
                class="py-3 first:pt-0 last:pb-0"
                v-for="(status, index) in item.tracking"
                :key="index"
              >
                <div class="inline-flex gap-4 items-center">
                  <span
                    class="w-6 h-6 rounded-full flex items-center justify-center shrink-0"
                    :class="
                      status.created_at
                        ? 'bg-teal-700 text-white'
                        : 'bg-gray-200 text-gray-600'
                    "
                  >
                    <i
                      class="fa-solid fa-xs align-middle"
                      :class="
                        status.created_at ? 'fa-check' : 'fa-hourglass-end'
                      "
                    ></i>
                  </span>
                  <div class="flex-1">
                    <p class="text-gray-800 font-semibold text-sm capitalize">
                      {{ status["status_" + $i18n.locale] }}
                    </p>
                    <slide-transition>
                      <div v-show="status.created_at">
                        <div
                          class="inline-flex gap-2 items-center flex-wrap text-gray-500"
                        >
                          <p
                            class="inline-flex gap-1 items-center text-gray-500 text-xs"
                          >
                            <i class="fa-solid fa-user fa-sm"></i>
                            <span>{{ status.user?.name || "N/A" }}</span>
                          </p>
                          <p
                            class="inline-flex gap-1 items-center text-gray-500 text-xs"
                          >
                            <i class="fa-solid fa-clock fa-sm"></i>
                            <span>{{
                              $moment(status.created_at).format("lll")
                            }}</span>
                          </p>
                        </div>
                      </div>
                    </slide-transition>
                  </div>
                </div>
              </li>
            </ul>
          </card>
          <card title="Transaction details">
            <ul class="list-none w-full divide-y divide-gray-200">
              <li class="grid grid-cols-2 pb-3">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Total products
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ item.products.length }}
                  </p>
                </div>
              </li>

              <li class="grid grid-cols-2 py-3">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Vat
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ item.vat }} SAR
                  </p>
                </div>
              </li>

              <li class="grid grid-cols-2 py-3">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Subtotal
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ item.sub_total }} SAR
                  </p>
                </div>
              </li>
              <li class="grid grid-cols-2 py-3 pb-0">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Total
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ item.total }} SAR
                  </p>
                </div>
              </li>
            </ul>
          </card>
          <card title="Payments">
            <template #actions>
              <button
                type="button"
                class="py-2 px-3 text-blue-600 bg-blue-50 border border-blue-200 text-xs rounded"
                @click="showAddPayment"
              >
                Add payment
              </button>
            </template>
            <ul class="list-none w-full divide-y divide-gray-200">
              <li v-if="!item.payments?.length">
                <p class="text-center text-gray-600 text-sm">
                  No payments added
                </p>
              </li>
              <li
                class="grid grid-cols-2 py-3 first:pt-0 last:pb-0"
                v-for="payment in item.payments"
                :key="payment.id"
              >
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ payment.payment_option }}
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ generateCurrency(payment.amount || 0) }}
                  </p>
                </div>
              </li>
            </ul>
          </card>

          <card title="Order details">
            <ul class="list-none w-full divide-y divide-gray-200">
              <li class="grid grid-cols-2 pb-3">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Shipping address
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ item.shipping_address || "-" }}
                  </p>
                </div>
              </li>
              <li class="grid grid-cols-2 py-3">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Status
                  </p>
                </div>
                <div>
                  <p
                    class="status capitalize"
                    :class="
                      item_status[item.is_closed ? 'closed' : item.status]
                    "
                  >
                    {{ item.is_closed ? "Closed" : item.status }}
                  </p>
                </div>
              </li>
              <!-- <li class="grid grid-cols-2 py-3">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Payment status
                  </p>
                </div>
                <div class="w-full">
                  <p
                    class="status"
                    :class="
                      item.is_paid
                        ? 'bg-teal-600/20 text-teal-600'
                        : 'bg-red-500/20 text-red-500'
                    "
                  >
                    {{ item.is_paid ? "Paid" : "Not paid" }}
                  </p>
                </div>
              </li> -->
              <li class="grid grid-cols-2 py-2">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Order date
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm inline-flex gap-2 items-center">
                    <span
                      class="text-gray-600 font-semibold rtl:font-bold inline-block"
                      >{{ item.order_date }}</span
                    >
                    <a
                      href="javascript:void(0)"
                      @click="update_order_date = !update_order_date"
                      :class="
                        update_order_date ? 'text-red-600' : 'text-sky-600'
                      "
                    >
                      <i
                        class="fa-solid fa-x fa-sm"
                        v-if="update_order_date"
                      ></i>
                      <i class="fa-solid fa-pen fa-sm" v-else></i>
                    </a>
                  </p>
                </div>
                <slide-transition>
                  <div class="col-span-2 mt-4" v-if="update_order_date">
                    <div class="form-group space-y-2">
                      <input
                        type="date"
                        v-model="order_date"
                        :disabled="disables['order_date']"
                        @keydown.stop.enter="updateOrderDate"
                      />
                      <p class="text-xs text-gray-600 font-medium">
                        Press enter to update new value
                      </p>
                      <p v-if="errors.order_date" class="error">
                        {{ errors?.order_date?.join(" ") }}
                      </p>
                    </div>
                  </div>
                </slide-transition>
              </li>
              <li class="grid grid-cols-2 pt-3">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Created at
                  </p>
                </div>
                <div
                  class="text-sm text-gray-600 font-semibold rtl:font-bold"
                  v-html="createTime(item.created_at)"
                ></div>
              </li>
            </ul>
          </card>
        </aside>
        <main class="w-full lg:w-2/3 2xl:flex-1 space-y-4">
          <card title="Supplier details">
            <ul class="list-none w-full divide-y divide-gray-200">
              <li class="grid grid-cols-2 pb-3">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Name
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ item.warehouse?.supplier?.name || "N/A" }}
                  </p>
                </div>
              </li>
              <li class="grid grid-cols-2 py-3">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    E-mail address
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ item.warehouse?.supplier?.email || "N/A" }}
                  </p>
                </div>
              </li>
              <li class="grid grid-cols-2 pt-3">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Phone number
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ item.warehouse?.supplier?.mobile || "N/A" }}
                  </p>
                </div>
              </li>
            </ul>
          </card>
          <card title="Store details">
            <ul class="list-none w-full divide-y divide-gray-200">
              <li class="grid grid-cols-2 pb-3">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Name
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ (item.store || {})["name_" + $i18n.locale] || "-" }}
                  </p>
                </div>
              </li>
              <li class="grid grid-cols-2 pt-3">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Address
                  </p>
                </div>
                <div class="w-full">
                  <a
                    :href="`https://maps.google.com/?q=${
                      (item.store || {}).lat
                    },${(item.store || {}).lng}`"
                    class="text-sm text-sky-600 font-semibold rtl:font-bold"
                    v-if="(item.store || {}).lat && (item.store || {}).lng"
                  >
                    {{ (item.store || {}).address || "-" }}
                  </a>
                  <span v-else>Location isn't not available</span>
                </div>
              </li>
            </ul>
          </card>
          <card title="Transferred store" v-if="item.order_type === 'transfer'">
            <ul class="list-none w-full divide-y divide-gray-200">
              <li class="grid grid-cols-2 pb-3">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Name
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ (item.to_store || {})["name_" + $i18n.locale] || "-" }}
                  </p>
                </div>
              </li>
              <li class="grid grid-cols-2 pt-3">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Address
                  </p>
                </div>
                <div class="w-full">
                  <a
                    :href="`https://maps.google.com/?q=${
                      (item.to_store || {}).lat
                    },${(item.to_store || {}).lng}`"
                    class="text-sm text-sky-600 font-semibold rtl:font-bold"
                    v-if="
                      (item.to_store || {}).lat && (item.to_store || {}).lng
                    "
                  >
                    {{ (item.to_store || {}).address || "-" }}
                  </a>
                  <span v-else>Location isn't not available</span>
                </div>
              </li>
            </ul>
          </card>

          <figure
            class="w-full rounded bg-white ring-1 ring-gray-200 p-6"
            v-for="(product, index) in item.products"
            :key="index"
          >
            <blockquote class="flex flex-col sm:flex-row gap-6 items-start">
              <div>
                <div>
                  <img
                    :src="
                      product.image
                        ? product.image
                        : require('@/assets/images/placeholder.png')
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    alt="product image"
                    class="w-20 h-20 rounded-full object-contain"
                  />
                </div>
              </div>
              <div class="flex-1">
                <ul class="list-none space-y-1">
                  <li>
                    <p class="text-primary font-semibold">
                      {{ product["name_" + $i18n.locale] }}
                    </p>
                  </li>

                  <li>
                    <ul
                      class="list-none grid grid-cols-2 gap-2 mt-4"
                      v-if="product.unit_type === 'weight'"
                    >
                      <li>
                        <p
                          class="text-xs text-gray-500 font-medium rtl:font-semibold"
                        >
                          Weight:
                        </p>
                      </li>
                      <li>
                        <p
                          class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                        >
                          {{
                            GenerateUnit(
                              product.weight
                                ? product.weight
                                : (product.qty
                                    ? product.qty
                                    : product.required_qty || 1) *
                                    (product.delivered_weight ||
                                      product.approx_weight)
                            )
                          }}
                        </p>
                      </li>
                      <li>
                        <p
                          class="text-xs text-gray-500 font-medium rtl:font-semibold"
                        >
                          Quantity:
                        </p>
                      </li>
                      <li>
                        <p
                          class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                        >
                          {{
                            product.qty
                              ? product.qty
                              : product.required_qty || 1
                          }}
                        </p>
                      </li>
                      <li>
                        <p
                          class="text-xs text-gray-500 font-medium rtl:font-semibold"
                        >
                          Price per kilo:
                        </p>
                      </li>
                      <li>
                        <p
                          class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                        >
                          {{ product.price }} SAR
                        </p>
                      </li>
                      <li>
                        <p
                          class="text-xs text-gray-500 font-medium rtl:font-semibold"
                        >
                          Total price:
                        </p>
                      </li>
                      <li>
                        <p
                          class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                        >
                          {{
                            getApproxPrice(
                              product.price,
                              product.weight
                                ? 1
                                : product.qty
                                ? product.qty
                                : product.required_qty,
                              product.weight
                                ? product.weight
                                : product.delivered_weight ||
                                    product.approx_weight
                            )
                          }}
                          SAR
                        </p>
                      </li>
                    </ul>
                    <ul class="list-none grid grid-cols-2 gap-2 mt-4" v-else>
                      <li>
                        <p
                          class="text-xs text-gray-500 font-medium rtl:font-semibold"
                        >
                          Quantity:
                        </p>
                      </li>
                      <li>
                        <p
                          class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                        >
                          {{ product.qty ? product.qty : product.required_qty }}
                        </p>
                      </li>
                      <li>
                        <p
                          class="text-xs text-gray-500 font-medium rtl:font-semibold"
                        >
                          Price:
                        </p>
                      </li>
                      <li>
                        <p
                          class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                        >
                          {{ product.price }} SAR
                        </p>
                      </li>
                      <li>
                        <p
                          class="text-xs text-gray-500 font-medium rtl:font-semibold"
                        >
                          Total price:
                        </p>
                      </li>
                      <li>
                        <p
                          class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                        >
                          {{
                            getApproxPrice(
                              product.price,
                              product.qty ? product.qty : product.required_qty,
                              1
                            )
                          }}
                          SAR
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </blockquote>
          </figure>
        </main>
      </section>
      <section class="w-full p-6 flex flex-col md:flex-row gap-4" v-else>
        <aside class="w-full lg:w-1/3 2xl:max-w-sm space-y-4">
          <figure
            class="w-full bg-white rounded ring-1 ring-gray-200"
            v-for="l in 3"
            :key="l"
          >
            <header class="p-4 border-b border-b-gray-100">
              <div class="w-1/2 h-4 rounded animate-pulse bg-gray-200"></div>
            </header>
            <blockquote class="p-4 space-y-4">
              <ul class="list-none w-full divide-y divide-gray-200">
                <li class="grid grid-cols-2 py-3" v-for="i in 2" :key="i">
                  <div class="w-full">
                    <div
                      class="w-2/3 h-4 rounded bg-gray-200 animate-pulse"
                    ></div>
                  </div>
                  <div class="w-full">
                    <div
                      class="w-2/3 h-4 rounded bg-gray-200 animate-pulse"
                    ></div>
                  </div>
                </li>
              </ul>
            </blockquote>
          </figure>
        </aside>
        <main class="w-full lg:w-2/3 2xl:flex-1 space-y-4">
          <figure class="w-full bg-white rounded ring-1 ring-gray-200">
            <header class="p-4 border-b border-b-gray-100">
              <div class="w-1/2 h-4 rounded animate-pulse bg-gray-200"></div>
            </header>
            <blockquote class="p-4 space-y-4">
              <ul class="list-none w-full divide-y divide-gray-200">
                <li class="grid grid-cols-2 py-3" v-for="i in 2" :key="i">
                  <div class="w-full">
                    <div
                      class="w-2/3 h-4 rounded bg-gray-200 animate-pulse"
                    ></div>
                  </div>
                  <div class="w-full">
                    <div
                      class="w-2/3 h-4 rounded bg-gray-200 animate-pulse"
                    ></div>
                  </div>
                </li>
              </ul>
            </blockquote>
          </figure>
        </main>
      </section>

      <transition name="scale">
        <modal
          title="Pay now"
          v-if="pay_active"
          @close="
            (e) => {
              pay_active = e;
              pay_data = {};
              pay_errors = {};
            }
          "
        >
          <template>
            <div class="p-4">
              <form
                v-if="has_form"
                :action="`/checkout/?payment=${pay_data.payment_option}&payment_option=${pay_data.payment_option}`"
                class="paymentWidgets"
                :data-brands="
                  pay_data.payment_option === 'visa'
                    ? 'VISA MASTER'
                    : pay_data.payment_option === 'mada'
                    ? 'MADA'
                    : ''
                "
              ></form>
              <form class="space-y-4" v-else>
                <div class="form-group">
                  <div class="with-label">
                    <p class="error">
                      {{ (pay_errors["payment_option"] || []).join(" ") }}
                    </p>
                    <ul class="list-none space-y-3">
                      <li>
                        <div class="actions-group items-center">
                          <div
                            class="check-with-content"
                            v-for="(payment, index) in payments"
                            :key="index"
                          >
                            <input
                              type="radio"
                              name="payment_option"
                              :id="payment"
                              :value="payment"
                              v-model="pay_data.payment_option"
                              hidden
                              aria-hidden="true"
                              class="hidden"
                            />
                            <label :for="payment">
                              <img
                                :src="
                                  require(`@/assets/images/payments/${payment}.png`)
                                "
                                @error="
                                  $event.target.parentElement.parentElement.remove()
                                "
                                alt="payment icon"
                                title="payment icon"
                            /></label>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <label for="payment_option"> Payment option </label>
                  </div>
                </div>
                <div>
                  <button-with-loader
                    :disabled="pay_disabled"
                    btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
                    @click="PayNow"
                  >
                    Submit
                  </button-with-loader>
                </div>
              </form>
            </div>
          </template>
        </modal>
      </transition>
    </SubPagesHeader>

    <Refund :item="item" ref="refundModal" />
    <ApproveAndUpdateValues :item="item" ref="updateWeights" />
    <AddPayment :id="this.itemId" :refetch="getItem" ref="addPayment" />
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import Modal from "@/components/basics/Modal.vue";
import SubPagesHeader from "@/components/basics/SubPagesHeader.vue";
import AddPayment from "@/components/orders/AddPayment.vue";
import ApproveAndUpdateValues from "@/components/orders/ApproveAndUpdateValues.vue";
import Refund from "@/components/orders/Refund.vue";
import { mapActions } from "vuex";
export default {
  components: {
    ButtonWithLoader,
    Modal,
    SubPagesHeader,
    Refund,
    ApproveAndUpdateValues,
    Card,
    AddPayment,
  },
  name: "InvoiceDetails",
  data() {
    return {
      item: {},
      load: false,

      locale_statuses: [
        {
          title: "Created",
          value: "created",
        },
        {
          title: "Approve",
          value: "approved",
        },
        {
          title: "Received",
          value: "received",
        },
      ],
      item_status: {
        created: "bg-sky-500/10 text-sky-500",
        accepted: "bg-gray-500/10 text-gray-500",
        approved: "bg-gray-500/10 text-gray-500",
        cancelled: "bg-red-600/10 text-red-600",
        closed: "bg-red-600/10 text-red-600",
        delivered: "bg-gray-500/10 text-gray-500",
        in_transit: "bg-yellow-500/10 text-yellow-500",
      },
      payments: ["visa", "mada", "stc"],
      disables: {},
      add_errors: {},
      confirm_products: [],
      confirm_errors: {},
      add_disabled: false,
      confirm_active: false,
      pay_data: {},
      pay_errors: {},
      pay_disabled: false,
      pay_active: false,
      has_form: false,
      print_disabled: false,
      close_disabled: false,
      cancel_disabled: false,
      status: null,
      order_date: null,
      update_order_date: false,
      errors: {},
    };
  },
  computed: {
    itemId() {
      return this.$route.params.id;
    },
    getApproxPriceThroughQty() {
      return (id) => {
        let result = 0;
        const product = this.confirm_products.find((e) => e.id === id);
        if (!product) return 0;

        result = +product.price * +product.weights.reduce((a, b) => a + b, 0);

        return result || 0;
      };
    },
    totalWeights() {
      return (weights = []) => {
        let total = 0;
        total = weights?.reduce((a, b) => +a + +b, 0);

        return total;
      };
    },
  },
  created() {
    this.getItem();
  },
  watch: {
    confirm_active(val) {
      if (!val) {
        this.confirm_products = [];
        this.confirm_errors = {};
      }
    },
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    async getItem() {
      try {
        let status;
        const { result } = await this.getRoute(`orders/${this.itemId}`);
        this.item = {
          ...result,
          is_meat: result.store?.module?.module_type === "butchery",
        };
        status = result.status;

        this.status = result.local_supplier
          ? ["accepted"].includes(status)
            ? "approved"
            : ["is_transit", "delivered"].includes(status)
            ? "received"
            : status
          : status;
        this.order_date = this.item?.order_date;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
        this.$router.push("/inventory-actions");
      } finally {
        this.load = true;
      }
    },
    async changeStatus(status) {
      const id = this.itemId;
      this.$set(this.disables, status, true);
      try {
        const { isConfirmed } = await this.$swal.fire({
          theme: "bulma",
          title: "Are you sure you want to change status?",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCancelButton: true,
        });
        if (!isConfirmed) return;
        const { data } = await this.axios.post(`orders/update_status`, {
          id,
          status,
        });
        this.createAlert(data.message);
        this.getItem();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async updateOrderDate() {
      try {
        const { isConfirmed } = await this.$swal.fire({
          theme: "bulma",
          title: "Are you sure you want to do this process?",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCancelButton: true,
        });
        if (!isConfirmed) return;
        this.$set(this.disables, "order_date", true);
        const { data } = await this.axios.post("orders/update", {
          order_date: this.order_date,
          id: this.itemId,
        });
        this.createAlert(data.message);
        this.getItem();
      } catch (error) {
        const err = error?.response?.data;
        const message = err.message;
        if (message instanceof Object) {
          this.errors = message;
        } else {
          this.createAlert(message);
        }
      } finally {
        this.disables = {};
      }
    },
    async PayNow() {
      try {
        this.pay_disabled = true;
        this.pay_errors = {};

        const { data } = await this.axios.post("orders/pay", this.pay_data);
        if (data.result) {
          this.has_form = true;
          const externalScript = document.createElement("script");
          const scriptStyle = document.createElement("script");
          externalScript.setAttribute("src", `${data.result.checkout_url}`);
          scriptStyle.innerHTML = `var wpwlOptions = {
                          style: "plain",
                          validation: true
                      }
                        `;
          document.head.appendChild(externalScript);
          document.head.appendChild(scriptStyle);
          this.createAlert(data?.message);
          return;
        }
        this.pay_data = {};
        this.pay_active = false;
        this.createAlert(data?.message);
        this.getItem();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.pay_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.pay_disabled = false;
      }
    },

    showPay() {
      this.pay_data = {
        order_id: this.itemId,
        payment_option: "visa",
      };
      this.pay_errors = {};
      this.pay_active = true;
    },

    async printPDF() {
      this.print_disabled = true;
      try {
        const { data } = await this.axios.post(
          "orders/export_pdf/" + this.itemId,
          {},
          {
            responseType: "blob",
            headers: {
              "Content-type": "blob",
            },
          }
        );
        let blob = new Blob([data]);
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.itemId}-${new Date().getTime()}.pdf`;
        link.click();
      } catch (err) {
        console.log(err);
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        // this.createAlert(res?.data?.message, 'error')
        this.createAlert(
          "You cannot print current item at that moment, please try again.",
          "error"
        );
      } finally {
        this.print_disabled = false;
      }
    },
    async closeItem() {
      try {
        this.close_disabled = true;
        const { isConfirmed } = await this.$swal.fire({
          theme: "bulma",
          title: "Are you sure you want to close this order?",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCancelButton: true,
        });
        if (!isConfirmed) return;
        const { data } = await this.axios.post(`orders/close/${this.itemId}`);
        this.createAlert(data.message);
        this.getItem();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.close_disabled = false;
      }
    },
    async cancelOrder() {
      try {
        this.cancel_disabled = true;
        const { isConfirmed } = await this.$swal.fire({
          theme: "bulma",
          title: "Are you sure you want to cancel this order?",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCancelButton: true,
        });
        if (!isConfirmed) return;
        const { data } = await this.axios.post(`orders/cancel/${this.itemId}`);
        this.createAlert(data.message);
        this.getItem();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.cancel_disabled = false;
      }
    },
    showRefund() {
      this.$refs.refundModal.show();
    },
    showConfirmWeight() {
      this.$refs.updateWeights.show();
    },
    showAddPayment() {
      this.$refs.addPayment.show();
    },
  },
};
</script>
