<template>
  <section class="w-full p-6 space-y-6">
    <analytics-container-wrapper class="space-y-6">
      <filter-wrapper
        @apply="getItems"
        @reset="handleReset"
        ref="filterWrapper"
      >
        <div>
          <t-datepicker
            :placeholder="`From - To`"
            multiple
            :monthsPerView="2"
            v-model="pagination.date"
            range
            :clearable="false"
            userFormat="Y-m-d"
            :weekStart="6"
          />
        </div>
      </filter-wrapper>
      <Table
        title="Inventory"
        :records="`${pagination.total || 0} records`"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
        has_export
        :exportParams="pagination"
        :fields="fields"
      >
        <template #head>
          <th>Product</th>
          <th>Stock</th>
          <th>Sold</th>
          <th>Total (SAR)</th>
          <th>Actions</th>
        </template>
        <template #tbody v-if="!load">
          <TableLoad :rows="5" v-for="i in 4" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length">
          <tr v-for="(product, index) in items" :key="index">
            <td>{{ product["name_" + $i18n.locale] }}</td>
            <td>
              {{
                product.unit_type === "weight"
                  ? GenerateUnit(product.stock || 0)
                  : (product.stock || 0) + " Piece"
              }}
            </td>
            <td>
              {{
                product.unit_type === "weight"
                  ? GenerateUnit(product.sold || 0)
                  : (product.sold || 0) + " Piece"
              }}
            </td>
            <td>{{ product.total_price || 0 }}</td>
            <td>
              <button
                class="inline-block w-8 h-8 rounded bg-sky-500 text-white text-sm"
                @click.prevent="() => showItems(product)"
              >
                <i class="fa-solid fa-eye"></i>
              </button>
            </td>
          </tr>
        </template>

        <template #tbody v-else>
          <tr>
            <td colspan="5" class="!text-center">
              There are no data to display
            </td>
          </tr>
        </template>
      </Table>
    </analytics-container-wrapper>
    <modal
      :title="item['name_' + $i18n.locale]"
      v-if="show_item"
      @close="
        () => {
          item = {};
          show_item = false;
        }
      "
    >
      <template>
        <div class="overflow-auto">
          <table class="styled-table overflow-hidden">
            <thead>
              <tr>
                <th>Name</th>
                <th>Sold</th>
                <th>Total price</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!(item.items || []).length">
                <td colspan="100%" class="!text-center">
                  There are no data to display
                </td>
              </tr>
              <tr
                v-for="(product, index) in (item || []).items"
                :key="index"
                v-else
              >
                <td>{{ product["name_" + $i18n.locale] }}</td>
                <td>
                  <span v-if="product.unit_type === 'weight'">
                    {{ GenerateUnit(product.sold || 0) }}
                  </span>
                  <span v-else> {{ product.sold || 0 }} Piece </span>
                </td>
                <td>{{ product.total_price }} SAR</td>
              </tr>
            </tbody>
            <!-- <tfoot class="bg-slate-100">
              <tr>
                <td>Total</td>
                <td>
                  <span v-if="item.unit_type === 'weight'">
                    {{ GenerateUnit(item.sold || 0) }}
                  </span>
                  <span v-else> {{ item.sold || 0 }} Piece </span>
                </td>
                <td>{{ item.total_price }} SAR</td>
              </tr>
            </tfoot> -->
          </table>
        </div>
      </template>
    </modal>
  </section>
</template>
<script>
import Modal from "@/components/basics/Modal.vue";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import AnalyticsContainerWrapper from "@/components/AnalyticsContainerWrapper.vue";
import FilterWrapper from "@/components/basics/FilterWrapper.vue";
export default {
  name: "InvoicesInventory",
  data() {
    return {
      load: false,
      items: [],
      currentPage: 1,
      rows: 0,
      totalPages: 0,
      perPage: 1,
      active: false,
      product_id: null,
      delete_disabled: false,
      status_disabled: [],
      error: "",
      search_key: "",
      search_disabled: false,
      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-500/10 text-red-500",
      },
      add_data: {
        is_inventory: 1,
      },
      add_errors: {},
      add_error: null,
      add_disabled: false,
      show_active: false,
      status: ["all", "active", "not active"],
      pagination: {
        is_active: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      disables: {},
      indexes: [],
      wastage_data: {},
      wastage_errors: {},
      wastage_active: false,
      wastage_disabled: false,
      wastages: [],
      item: {},
      show_item: false,
      fields: [],
    };
  },
  async created() {
    this.$set(this.pagination, "date", [
      this.$route.query.date ||
        this.$moment().add(-1, "days").format("YYYY-MM-DD"),
      this.$route.query.date || this.$moment().format("YYYY-MM-DD"),
    ]);
    await Promise.all([this.getItems()]);
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),

    async getItems() {
      try {
        const { total, total_pages, date, ...others } = this.pagination;
        const user = this.$store.state.user;

        const { result } = await this.getRoute({
          name: "invoices/inventory",
          options: {
            ...others,
            search_key: this.search || this.$route.query.search,
            vendor_id: user.user_type === "vendor" ? user?.id : null,
            // store_id: user.user_type === "cashier" ? user?.id : null,
            store_id: this.store_id,
            from: date?.[0],
            to: date?.[1],
          },
        });

        const { data, pagination } = result.products;
        this.fields = result.export_fields;

        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch (error) {
        console.log(error);
        // this.createAlert(result?.message, type);
      } finally {
        this.load = true;
        const filterWrapper = this.$refs.filterWrapper;
        filterWrapper?.resetAll();
        this.changeIsCalled(false);
      }
    },
    handleReset() {
      const { page, ...others } = this.pagination;

      for (const key in others) {
        const item = others[key];
        if (typeof item === "number") {
          this.$set(this.pagination, key, 0);
        } else if (Array.isArray(item)) {
          this.$set(this.pagination, key, []);
        } else {
          this.$set(this.pagination, key, null);
        }
      }

      this.getItems();
    },
    showItems(product) {
      this.item = product;
      this.show_item = true;
    },
  },
  components: {
    Modal,
    ButtonWithLoader,
    AnalyticsContainerWrapper,
    FilterWrapper,
  },
};
</script>
