<template>
  <div>
    <div class="p-8">
      <figure
        class="w-full max-w-lg bg-white ring-1 ring-gray-200 rounded-md p-5 mx-auto"
      >
        <figcaption>
          <div
            class="flex items-center justify-center w-14 h-14 bg-gray-100 text-gray-600 rounded-full mx-auto"
          >
            <i class="fa-regular fa-hourglass-half fa-spin"></i>
          </div>
        </figcaption>
        <blockquote class="mt-4">
          <h1
            class="text-lg text-primary text-center font-semibold rtl:font-bold"
          >
            The payment is being verified..
          </h1>
        </blockquote>
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  name: "Checkout",
  async created() {
    await this.checkPaymentStatus();
  },
  methods: {
    async checkPaymentStatus() {
      try {
        const { payment, id } = this.$route.query;
        const { data } = await this.axios.post("check_payment_status", {
          payment_option: payment,
          checkout_id: id,
        });
        const { code, access_token, ...others } = data.result;
        const token = this.$cookies.get("token");

        if (token) {
          this.$router.go(-1);
          return;
        }

        if (["000.000.000", "000.100.110"].includes(code)) {
          if (token) {
            this.$router.go(-1);
            return;
          }
        }

        if (access_token && others.id) {
          this.createAlert(data.message);
          // http://localhost:8080/checkout/?payment=visa&payment_option=visa&id=74F098AEA3B0ABE330F9EDE244F3DE8A.uat01-vm-tx03&resourcePath=%2Fv1%2Fcheckouts%2F74F098AEA3B0ABE330F9EDE244F3DE8A.uat01-vm-tx03%2Fpayment
          localStorage.setItem(
            "register-info",
            JSON.stringify({ access_token, id: others.id })
          );
          this.$router.push("/account/register");
          return;
        }

        this.$router.push("/account/register");
      } catch (error) {
        console.log(error);
        // this.$router.push({
        //   name: "PageNotFound",
        // });
      }
    },
  },
};
</script>
