var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{staticClass:"px-6 space-y-4"},[_c('slide-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.add_discounts_active),expression:"add_discounts_active"}],staticClass:"form-group"},[_c('div',{staticClass:"with-label"},[(_vm.add_errors['products'])?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.add_errors["products"].join(" "))+" ")]):_vm._e(),_c('select-with-limit',{ref:"productsRef",attrs:{"fetch":_vm.getProducts,"placeholder":"Select","text":'name_' + _vm.$i18n.locale,"id":"id"},on:{"input":_vm.onProductsSelected},model:{value:(_vm.product_ids),callback:function ($$v) {_vm.product_ids=$$v},expression:"product_ids"}}),_c('label',{attrs:{"for":"product_ids"}},[_vm._v("Items")])],1)])]),_c('div',[_c('Table',{attrs:{"title":"Products","records":`${_vm.selected_products.length || 0} records`,"has_search":false},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('th',[_vm._v("Product")]),_c('th',[_vm._v("Price (SAR)")]),_c('th',[_vm._v("Discount")]),_c('th',[_vm._v("Start date")]),_c('th',[_vm._v("End date")]),_c('th',[_vm._v("Actions")])]},proxy:true},{key:"actions",fn:function(){return [(_vm.hasPermission('add discounts'))?_c('div',[_c('button',{staticClass:"py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold",on:{"click":function($event){$event.preventDefault();_vm.add_discounts_active = !_vm.add_discounts_active}}},[(_vm.add_discounts_active)?[_c('i',{staticClass:"fa-solid fa-eye"}),_c('span',[_vm._v("Show products")])]:[_c('i',{staticClass:"fa-solid fa-plus"}),_c('span',[_vm._v("New discount")])]],2)]):_vm._e()]},proxy:true},(_vm.selected_products.length)?{key:"tbody",fn:function(){return _vm._l((_vm.selected_products),function(product,index){return _c('tr',{key:product.id},[_c('td',[_c('router-link',{staticClass:"flex items-center gap-3",attrs:{"to":_vm.hasPermission('edit inventory')
                    ? {
                        name: 'UpdateInventoryProduct',
                        params: {
                          slug: product.product.main_product?.id,
                        },
                      }
                    : '#'}},[_c('div',[_c('div',{staticClass:"w-10 h-10"},[_c('img',{staticClass:"w-full h-full rounded-md border object-cover border-gray-100",attrs:{"src":product.product.image ||
                        require('@/assets/images/placeholder.png'),"alt":"product image"},on:{"error":function($event){$event.target.src = require('@/assets/images/placeholder.png')}}})])]),_c('div',{staticClass:"flex-1 max-w-fit"},[_c('p',{staticClass:"line-clamp-2 whitespace-normal text-sky-600"},[_vm._v(" "+_vm._s(product.product["name_" + _vm.$i18n.locale])+" ")]),_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s((_vm.add_errors["products." + index + ".id"] || []).join( " " ))+" ")])])])],1),_c('td',[_c('span',{class:product.discount_value ? 'line-through text-red-500' : ''},[_vm._v(_vm._s(_vm.generateCurrency(product.product.price)))]),(product.discount_value)?_c('span',[_vm._v(" "+_vm._s(_vm.generateCurrency( _vm.getDiscountPrice( product.product.price, product.discount_value, product.discount_type ) )))]):_vm._e()]),_c('td',[_c('div',{staticClass:"flex gap-2"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(product.discount_value),expression:"product.discount_value",modifiers:{"number":true}}],staticClass:"text-sm",staticStyle:{"width":"fit-content"},attrs:{"type":"number","placeholder":"0","min":"0","max":"100","required":""},domProps:{"value":(product.discount_value)},on:{"blur":[function($event){$event.preventDefault();return ((e) => {
                        if (product.discount_type === 'percentage') {
                          product.discount_value =
                            e.target.valueAsNumber >= 100
                              ? 100
                              : e.target.value;
                        }
                      }).apply(null, arguments)},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing)return;_vm.$set(product, "discount_value", _vm._n($event.target.value))}}})]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(product.discount_type),expression:"product.discount_type"}],staticClass:"custom-select !w-fit",attrs:{"required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(product, "discount_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","selected":"","disabled":""}},[_vm._v("-- select --")]),_c('option',{attrs:{"value":"fixed"}},[_vm._v("Fixed")]),_c('option',{attrs:{"value":"percentage"}},[_vm._v("Percentage")])])]),_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(( _vm.add_errors["products." + index + ".discount_value"] || _vm.add_errors["products." + index + ".discount_type"] || [] ).join(" "))+" ")])]),_c('td',[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(product.discount_start_date),expression:"product.discount_start_date"}],staticClass:"text-sm",attrs:{"type":"date","min":new Date().toISOString().substring(0, 10)},domProps:{"value":(product.discount_start_date)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(product, "discount_start_date", $event.target.value)}}}),_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(( _vm.add_errors[ "products." + index + ".discount_start_date" ] || [] ).join(" "))+" ")])])]),_c('td',[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(product.discount_end_date),expression:"product.discount_end_date"}],staticClass:"text-sm",attrs:{"type":"date","min":product.discount_start_date ||
                    new Date().toISOString().substring(0, 10)},domProps:{"value":(product.discount_end_date)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(product, "discount_end_date", $event.target.value)}}}),_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(( _vm.add_errors[ "products." + index + ".discount_end_date" ] || [] ).join(" "))+" ")])])]),_c('td',[_c('div',{staticClass:"flex gap-2"},[_c('button-with-loader',{attrs:{"disabled":_vm.add_disabled,"btnClass":"bg-primary p-3 py-2 rounded text-xs font-medium rtl:font-semibold text-white"},on:{"click":function($event){return _vm.updateProduct(product.id)}}},[_vm._v(" Save ")]),(!_vm.add_discounts_active)?_c('button-with-loader',{attrs:{"disabled":_vm.add_disabled,"btnClass":"bg-red-500 p-3 py-2 rounded text-xs font-medium rtl:font-semibold text-white"},on:{"click":function($event){return _vm.cancelProduct(product.id)}}},[_vm._v(" Cancel ")]):_vm._e()],1)])])})},proxy:true}:{key:"tbody",fn:function(){return [_c('tr',[_c('td',{staticClass:"!text-center",attrs:{"colspan":"100%"}},[_vm._v("There are no products")])])]},proxy:true}],null,true)})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }