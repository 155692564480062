<template>
  <div>
    <div class="w-full space-y-4">
      
      <figure class="w-full rounded-xl bg-white">
        <header class="w-full border-b border-b-gray-100 p-5">
          <p class="text-sm font-semibold rtl:font-bold text-gray-600">
            Notifications
          </p>
        </header>
        <blockquote class="p-5">
          <ul class="space-y-6">
            <li>
              <div class="checkbox !gap-4 !items-start">
                <input
                  type="checkbox"
                  name="notify_alert"
                  id="notify_alert"
                  class="!mt-1"
                  v-model="reset_notifications"
                  :disabled="reset_notifications"
                />
                <label for="notify_alert" class="flex-1 space-y-1 block">
                  <p class="font-semibold rtl:font-bold">
                    Enable notifications
                  </p>
                  <p
                    class="text-sm font-medium rtl:font-semibold text-gray-600"
                  >
                    New notifications will be notified by sending via desktop
                    notifications if you want to reset it click on SECURE icon
                    next to URL then click on Reset permissions
                  </p>
                </label>
              </div>
            </li>
            <li>
              <div class="checkbox !gap-4 !items-start">
                <input
                  type="checkbox"
                  name="newsletter"
                  id="newsletter"
                  class="!mt-1"
                />
                <label for="newsletter" class="flex-1 space-y-1 block">
                  <p class="font-semibold rtl:font-bold">
                    Subscribe in our newsletter
                  </p>
                  <p
                    class="text-sm font-medium rtl:font-semibold text-gray-600"
                  >
                    Subscribe to our newsletter. Our latest news will be sent to
                    you via the email registered with us, and we will send you
                    our latest updates
                  </p>
                </label>
              </div>
            </li>
          </ul>
        </blockquote>
      </figure>
      <figure class="w-full rounded-xl bg-white">
        <header class="w-full border-b border-b-gray-100 p-5">
          <p class="text-sm font-semibold rtl:font-bold text-gray-600">
            Application
          </p>
        </header>
        <blockquote class="p-4 space-y-2">
          <h1 class="font-semibold rtl:font-bold">
            Download Nyqat application
          </h1>
          <p class="text-sm font-medium rtl:font-semibold text-gray-600">
            The orders and tracking the validity of your products become easier
            and at a click of a button from your mobile for shopkeepers to track
            their sales, attention to their orders from suppliers, income, and
            profits
          </p>
          <div class="flex items-start gap-2 flex-wrap !mt-6">
            <div>
              <a href="#" class="w-auto max-w-[150px] inline-block"
                ><img
                  src="@/assets/images/google-store.png"
                  alt="Google store logo"
                  class="max-w-full max-h-10"
              /></a>
            </div>
            <div>
              <a href="#" class="w-auto max-w-[150px] inline-block"
                ><img
                  src="@/assets/images/app-store.png"
                  alt="App store logo"
                  class="max-w-full max-h-10"
              /></a>
            </div>
          </div>
        </blockquote>
      </figure>
      <figure class="w-full rounded-xl bg-white">
        <header class="w-full border-b border-b-gray-100 p-5">
          <p class="text-sm font-semibold rtl:font-bold text-gray-600">
            Account
          </p>
        </header>
        <blockquote class="p-5 space-y-6">
          <div class="space-y-2">
            <h1 class="font-semibold rtl:font-bold text-red-500">
              Delete my account
            </h1>
            <p class="text-sm font-medium rtl:font-semibold text-gray-600">
              If you really want to delete your account, this means that all the
              data you have and the activities stored in your account will be
              deleted and that data will not be retrieved again, if you still
              want to do so, you can send a request to the administration first
              to delete your account. Our databases, thank you
            </p>
            <button
              class="
                py-1.5
                px-3
                text-sm
                rounded
                bg-red-500
                table
                text-white
                !mt-6
              "
            >
              Yes, delete my account
            </button>
          </div>
        </blockquote>
      </figure>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  name: "ResetFactory",
  data() {
    return {
      reset_notifications: this.$store.getters["root/enabled_notifications"],
      
    };
  },

  watch: {
    reset_notifications(newVal, _) {
      this.CHANGE_NOTIFICATIONS_ENABLED(newVal);
      if (newVal) {
        this.checkPermissions({title: "Notifications enabled"});
      }
    },
   
  },
  created() {
    this.getStores();
  },
  methods: {
    ...mapMutations("root", ["CHANGE_NOTIFICATIONS_ENABLED"]),
    ...mapActions("getting", ["getRoute"]),
    
  },
};
</script>
