<template>
  <div class="p-6">
    <Table
      title="Cost centers"
      :records="`${pagination.total || 0} records`"
      :pagination="pagination"
      :total_pages="pagination.total_pages"
      v-model="pagination.page"
    >
      <template #actions>
        <div v-if="hasPermission('add accounting_cost')">
          <button
            class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
            @click.prevent="add_active = !add_active"
          >
            <i class="fa-solid fa-plus"></i>
            <span>New cost</span>
          </button>
        </div>
      </template>
      <template #filter>
        <ul class="flex items-center">
          <li v-for="(item, index) in status" :key="index">
            <button
              class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
              @click.prevent="
                changeFilterStatus(item === 'all' ? null : item, index)
              "
              :class="{
                'active-tab':
                  pagination.is_active === (item === 'all' ? null : item),
              }"
              :disabled="disables[index]"
            >
              <spinner
                size="w-4 h-4 inline-block"
                class="mr-2 rtl:mr-0 rtl:ml-2"
                v-if="disables[index]"
              />
              <!-- {{ $t(item.replace(/\s/g, "_")) }} -->
              {{ item }}
            </button>
          </li>
        </ul>
      </template>
      <template #head>
        <th>Name</th>
        <th>Options</th>
        <th>Status</th>
        <th>Latest update</th>
        <th>Actions</th>
      </template>
      <template #tbody v-if="!load">
        <TableLoad :rows="5" v-for="i in 4" :key="i" />
      </template>
      <template #tbody v-else-if="load && items.length">
        <tr v-for="(item, index) in items" :key="index">
          <td>
            {{ item.name }}
          </td>
          <td>
            <div class="actions-group !flex-wrap min-w-[15rem]">
              <span
                class="status text-gray-600 bg-gray-100"
                v-for="(center, index) in item.cost_centers"
                :key="index"
                >{{ center.name }}</span
              >

              <span class="text-gray-600" v-if="!item.cost_centers.length"
                >-</span
              >
            </div>
          </td>
          <td>
            <p class="status capitalize" :class="item_status[item.is_active]">
              {{ item.is_active === 1 ? "Active" : "Not active" }}
            </p>
          </td>

          <td v-html="createTime(item.updated_at || item.created_at)"></td>

          <td>
            <div class="actions-group">
              <div v-if="hasPermission('update accounting_cost')">
                <button
                  class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                  @click.prevent="changeStatus(item.id)"
                  :content="'Change status'"
                  v-tippy
                  :disabled="disables[`status_${item.id}`]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    v-if="disables[`status_${item.id}`]"
                  />
                  <i class="fa-solid fa-right-left" v-else></i>
                </button>
              </div>
              <div v-if="hasPermission('update accounting_cost')">
                <button
                  class="w-8 h-8 bg-sky-500 text-white rounded"
                  @click.prevent="showUpdate(item.id)"
                  :content="'Update'"
                  v-tippy
                >
                  <i class="fa-solid fa-pen"></i>
                </button>
              </div>
              <div v-if="hasPermission('delete accounting_cost')">
                <button
                  class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                  @click.prevent="deleteItem(item.id)"
                  :disabled="disables[`delete_${item.id}`]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    v-if="disables[`delete_${item.id}`]"
                  />
                  <span v-else> Delete </span>
                </button>
              </div>
            </div>
          </td>
        </tr>
      </template>

      <template #tbody v-else>
        <tr>
          <td colspan="5" class="!text-center">There are no data display</td>
        </tr>
      </template>
    </Table>
    <Modal
      title="New cost center"
      v-if="add_active"
      @close="
        (e) => {
          add_active = e;
        }
      "
      size="!max-w-screen-md"
    >
      <template>
        <form class="p-6 space-y-4">
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div class="form-group">
              <div class="with-label">
                <p class="error" v-if="add_errors.name">
                  {{ add_errors.name.join(" ") }}
                </p>
                <input
                  type="text"
                  name="name"
                  id="name"
                  required
                  autocomplete="off"
                  v-model="add_data.name"
                />
                <label for="name">Name | ar</label>
              </div>
            </div>

            <div class="form-group">
              <div class="with-label">
                <p class="error" v-if="add_errors.alt_name">
                  {{ add_errors.alt_name.join(" ") }}
                </p>

                <input
                  type="text"
                  name="alt_name"
                  id="alt_name"
                  required
                  autocomplete="off"
                  v-model="add_data.alt_name"
                />
                <label for="alt_name">Name | en</label>
              </div>
            </div>
          </div>

          <p class="text-sm text-gray-600">Options</p>
          <div
            class="grid grid-cols-1 sm:grid-cols-2 gap-4"
            v-for="(center, index) in add_data.cost_centers"
            :key="index"
          >
            <div class="form-group">
              <div class="with-label">
                <p class="form-error">
                  {{ add_errors[`cost_centers.${index}.name`] }}
                </p>
                <input
                  type="text"
                  name="name"
                  id="name"
                  required
                  autocomplete="off"
                  v-model="center.name"
                />
                <label for="name">Name | ar</label>
              </div>
            </div>

            <div class="form-group">
              <div class="with-label">
                <p class="form-error">
                  {{ add_errors[`cost_centers.${index}.alt_name`] }}
                </p>

                <div class="flex gap-4 items-center">
                  <input
                    type="text"
                    name="alt_name"
                    id="alt_name"
                    required
                    autocomplete="off"
                    v-model="center.alt_name"
                    class="flex-1"
                  />
                  <button
                    type="button"
                    v-if="index + 1 === add_data.cost_centers.length"
                    @click.prevent="
                      () => {
                        add_data.cost_centers.push({
                          name: null,
                          alt_name: null,
                        });
                      }
                    "
                  >
                    <i class="fa-solid fa-plus fa-sm"></i>
                  </button>
                  <button
                    type="button"
                    v-else
                    @click.prevent="
                      () => {
                        add_data.cost_centers.splice(index, 1);
                      }
                    "
                  >
                    <i class="fa-solid fa-trash fa-sm text-red-500"></i>
                  </button>
                </div>
                <label for="alt_name">Name | en</label>
              </div>
            </div>
          </div>

          <div>
            <button-with-loader
              :disabled="add_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="addItem"
            >
              Add
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
    <Modal
      :title="JSON.parse(JSON.stringify(edit_data.name))"
      v-if="edit_active"
      @close="
        (e) => {
          edit_active = e;
        }
      "
      size="!max-w-screen-md"
    >
      <template>
        <form class="p-6 space-y-4">
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div class="form-group">
              <div class="with-label">
                <p class="error" v-if="edit_errors.name">
                  {{ edit_errors.name.join(" ") }}
                </p>
                <input
                  type="text"
                  name="name"
                  id="name"
                  required
                  autocomplete="off"
                  v-model="edit_data.name"
                />
                <label for="name">Name | ar</label>
              </div>
            </div>

            <div class="form-group">
              <div class="with-label">
                <p class="error" v-if="edit_errors.alt_name">
                  {{ edit_errors.alt_name.join(" ") }}
                </p>

                <input
                  type="text"
                  name="alt_name"
                  id="alt_name"
                  required
                  autocomplete="off"
                  v-model="edit_data.alt_name"
                />
                <label for="alt_name">Name | en</label>
              </div>
            </div>
          </div>

          <p class="text-sm text-gray-600">Options</p>
          <div
            class="grid grid-cols-1 sm:grid-cols-2 gap-4"
            v-for="(center, index) in edit_data.cost_centers"
            :key="index"
          >
            <div class="form-group">
              <div class="with-label">
                <p class="form-error">
                  {{ edit_errors[`cost_centers.${index}.name`] }}
                </p>
                <input
                  type="text"
                  name="name"
                  id="name"
                  required
                  autocomplete="off"
                  v-model="center.name"
                />
                <label for="name">Name | ar</label>
              </div>
            </div>

            <div class="form-group">
              <div class="with-label">
                <p class="form-error">
                  {{ edit_errors[`cost_centers.${index}.alt_name`] }}
                </p>

                <div class="flex gap-4 items-center">
                  <input
                    type="text"
                    name="alt_name"
                    id="alt_name"
                    required
                    autocomplete="off"
                    v-model="center.alt_name"
                    class="flex-1"
                  />
                  <button
                    type="button"
                    v-if="index + 1 === edit_data.cost_centers.length"
                    @click.prevent="
                      () => {
                        edit_data.cost_centers.push({
                          name: null,
                          alt_name: null,
                        });
                      }
                    "
                  >
                    <i class="fa-solid fa-plus fa-sm"></i>
                  </button>
                  <button
                    type="button"
                    v-else
                    @click.prevent="
                      () => {
                        edit_data.cost_centers.splice(index, 1);
                      }
                    "
                  >
                    <i class="fa-solid fa-trash fa-sm text-red-500"></i>
                  </button>
                </div>
                <label for="alt_name">Name | en</label>
              </div>
            </div>
          </div>

          <div>
            <button-with-loader
              :disabled="edit_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="editItem"
            >
              Save changes
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
  </div>
</template>
<script>
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import Modal from "@/components/basics/Modal.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "CostCenters",
  data() {
    return {
      load: false,
      items: [],
      modules: [],
      add_data: {
        cost_centers: [{ name: null, alt_name: null }],
      },
      add_errors: {},
      add_disabled: false,
      add_active: false,

      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,

      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-500/10 text-red-500",
      },
      status: ["all", "active", "not active"],
      pagination: {
        is_active: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      disables: {},
    };
  },
  async created() {
    Promise.all([this.getItems()]).finally(() => (this.load = true));
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
    add_active(val, _) {
      if (!val) {
        this.add_data = {
          cost_centers: [{ name: null, alt_name: null }],
        };
        this.add_errors = {};
      }
    },
    edit_active(val, _) {
      if (!val) {
        this.edit_data = {
          cost_centers: [{ name: null, alt_name: null }],
        };
        this.edit_errors = {};
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),

    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;

        const { result } = await this.getRoute({
          name: "cost_center_groups",
          options: {
            ...others,
            is_active: others.is_active
              ? others.is_active === "active"
                ? 1
                : 0
              : null,
            search_key: this.search,
            store_id: this.store_id,
          },
        });
        const { data, pagination } = result.cost_center_groups;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.changeIsCalled(false);
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(
          `cost_center_groups/update_is_active/${id}`
        );
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { data } = await this.axios.post(
          `cost_center_groups/delete/${id}`
        );
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async addItem() {
      try {
        this.add_disabled = true;
        this.add_errors = {};

        const { data } = await this.axios.post(
          "cost_center_groups/add",
          this.add_data
        );
        this.add_active = false;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.add_disabled = false;
      }
    },
    async editItem() {
      try {
        this.edit_disabled = true;
        this.edit_errors = {};

        const { data } = await this.axios.post(
          "cost_center_groups/update",
          this.edit_data
        );
        this.createAlert(data?.message);
        this.edit_active = false;
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.edit_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.edit_disabled = false;
      }
    },
    clearImage(type) {
      const uploader = this.$refs.uploader;
      if (!uploader) return;
      uploader.value = null;
      this.$set(this[type], "image", null);
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id);
      if (!item) {
        this.createAlert("Item does not existed", "info");
        return;
      }
      this.edit_data = {
        ...item,
        cost_centers: !!item?.cost_centers?.length
          ? item.cost_centers
          : [
              {
                name: null,
                alt_name: null,
              },
            ],
      };
      this.edit_active = true;
    },

    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = "asc";
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == "asc" ? "desc" : "asc";
      }
      this.pagination.sort_key = key;
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`);
      });
    },

    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "is_active", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
  },
  components: { Modal, ButtonWithLoader },
};
</script>
