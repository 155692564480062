<template>
  <div class="p-6">
    <form class="space-y-4">
      <Card class="space-y-4">
        <ul class="divide-y divide-gray-200">
          <li
            v-for="role in roles"
            :key="role.key"
            class="py-3 first:pt-0 last:pb-0"
          >
            <div class="form-group-with-check">
              <label :for="role.key" class="block space-y-1 cursor-pointer">
                <p class="font-bold !text-base">{{ role.title }}</p>
                <p class="text-sm text-gray-600">
                  {{ role.content }}
                </p>
              </label>
              <input
                type="checkbox"
                data-type="switch"
                :id="role.key"
                :true-value="1"
                :false-value="0"
                v-model="add_data[role.key]"
              />
            </div>
          </li>
        </ul>
      </Card>
      <button-with-loader
        btnClass="bg-primary py-2 px-4 rounded text-xs font-medium text-white"
        @click="updateSalesSettings"
        :disabled="add_disabled"
      >
        <span>Save changes</span>
      </button-with-loader>
    </form>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import { mapActions } from "vuex";

export default {
  name: "SalesSettings",
  data() {
    return {
      roles: [
        {
          title: "Sell when quantity > available",
          content:
            "Product can be sold even if the order quantity is more than stock location quantity.",
          key: "sell_when_is_available",
        },
        {
          title: "Sell when price < cost",
          content: "Product can be sold even if its price less than the cost",
          key: "sell_when_price_less_than_cost",
        },
      ],
      add_data: {},
      add_disabled: false,
      add_errors: {},
    };
  },
  created() {
    this.getSalesSettings();
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),

    async getSalesSettings() {
      try {
        const { result } = await this.getRoute("settings");
        this.add_data = result.settings;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async updateSalesSettings() {
      try {
        this.add_disabled = true;
        this.add_errors = {};

        const { data } = await this.axios.post(
          "settings/update",
          this.add_data
        );

        this.add_data = {};
        this.createAlert(data?.message);
        this.getSalesSettings();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.add_disabled = false;
      }
    },
  },
  components: { Card, ButtonWithLoader },
};
</script>
