<template>
  <div>
    <section class="w-full p-6 space-y-6">
      <Table
        title="Users"
        :records="`${pagination.total || 0} records`"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
        has_export
      >
        <template #actions>
          <div v-if="!hasTrialDays && hasPermission('add users')">
            <button
              class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
              @click.prevent="add_active = !add_active"
            >
              <i class="fa-solid fa-plus"></i>
              <span>New user</span>
            </button>
          </div>
        </template>
        <template #filter>
          <ul class="flex items-center">
            <li v-for="(item, index) in status" :key="index">
              <button
                class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
                @click.prevent="
                  changeFilterStatus(item === 'all' ? null : item, index)
                "
                :class="{
                  'active-tab':
                    pagination.is_active === (item === 'all' ? null : item),
                }"
                :disabled="disables[index]"
              >
                <spinner
                  size="w-4 h-4 inline-block"
                  class="mr-2 rtl:mr-0 rtl:ml-2"
                  v-if="disables[index]"
                />
                <!-- {{ $t(item.replace(/\s/g, "_")) }} -->
                {{ item }}
              </button>
            </li>
          </ul>
        </template>
        <template #head>
          <th>user</th>
          <th>Mobile</th>
          <th>E-mail address</th>
          <th>Max discount</th>
          <th>Type</th>
          <th>Status</th>
          <th>Latest update</th>
          <th>Actions</th>
        </template>
        <template #tbody v-if="!load">
          <TableLoad :rows="8" v-for="i in 4" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length">
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <div class="flex items-center gap-3">
                <div class="w-10 h-10">
                  <img
                    :src="
                      item.image || require('@/assets/images/placeholder.png')
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    alt="item image"
                    class="w-full h-full rounded-md border object-cover border-gray-100"
                  />
                </div>
                <span class="inline-block max-w-sm whitespace-normal">
                  {{ item.name }} <br />
                  <span
                    class="bg-gray-100 text-gray-600 text-xs py-1 px-2 rounded !mt-1"
                    v-if="item.pos_device"
                  >
                    POS: {{ item?.pos_device?.code }}
                  </span>
                </span>
              </div>
            </td>
            <td>
              <a :href="`tel:${item.mobile}`" class="text-sky-600 text-sm">{{
                item.mobile
              }}</a>
            </td>
            <td>
              <a :href="`mailto:${item.email}`" class="text-sky-600 text-sm">{{
                item.email
              }}</a>
            </td>
            <td>
              {{ item.max_discount_percentage || "N/A" }}
            </td>
            <td>
              {{ item.user_type || "N/A" }}
              <span
                class="bg-gray-100 text-gray-600 text-xs py-1 px-2 rounded"
                v-if="item.user_type === 'cashier'"
              >
                PIN: {{ item.pin || "N/A" }}
              </span>
            </td>

            <td>
              <p class="status capitalize" :class="item_status[item.is_active]">
                {{ item.is_active === 1 ? "Active" : "Not active" }}
              </p>
            </td>

            <td v-html="createTime(item.updated_at || item.created_at)"></td>

            <td>
              <div class="actions-group">
                <div v-if="hasPermission('delete users')">
                  <button
                    class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                    @click.prevent="deleteItem(item.id)"
                    :disabled="disables[`delete_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`delete_${item.id}`]"
                    />
                    <span v-else> Delete </span>
                  </button>
                </div>
                <div v-if="hasPermission('update users')">
                  <button
                    class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                    @click.prevent="changeStatus(item.id)"
                    :content="'Change status'"
                    v-tippy
                    :disabled="disables[`status_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`status_${item.id}`]"
                    />
                    <i class="fa-solid fa-right-left" v-else></i>
                  </button>
                </div>
                <div v-if="hasPermission('edit users')">
                  <button
                    class="w-8 h-8 bg-sky-500 text-white rounded"
                    @click.prevent="showUpdate(item.id)"
                    :content="'Update'"
                    v-tippy
                  >
                    <i class="fa-solid fa-pen"></i>
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </template>

        <template #tbody v-else>
          <tr>
            <td colspan="8" class="!text-center">There are no data display</td>
          </tr>
        </template>
      </Table>
    </section>
    <Modal
      title="Add user"
      v-if="add_active"
      @close="
        (e) => {
          add_active = e;
        }
      "
    >
      <template>
        <form class="p-6 space-y-4">
          <figure class="space-y-4">
            <slide-transition>
              <figcaption v-if="add_data.image" class="relative table">
                <img
                  :src="
                    add_data.image
                      ? convertImage(add_data.image)
                      : require('@/assets/images/placeholder.png')
                  "
                  @error="
                    (e) =>
                      (e.target.src = require('@/assets/images/placeholder.png'))
                  "
                  alt="branch image"
                  class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
                />
                <button
                  class="w-6 h-6 rounded-full flex items-center justify-center bg-red-500 text-white absolute top-0 right-0 rtl:right-auto rtl:left-0 ring-2 ring-white"
                  type="button"
                  @click.prevent="() => clearImage('add_data')"
                >
                  <i class="fa-solid fa-xmark fa-sm"></i>
                </button>
              </figcaption>
            </slide-transition>
            <blockquote class="space-y-2">
              <label class="block">
                <span class="sr-only">Choose image</span>
                <input
                  type="file"
                  accept="image/*"
                  class="custom-file"
                  ref="uploader"
                  @change="(e) => $set(add_data, 'image', e.target.files[0])"
                />
              </label>
              <p class="error" v-if="add_errors.image">
                {{ add_errors.image.join(" ") }}
              </p>
            </blockquote>
          </figure>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors.name">
                {{ add_errors.name.join(" ") }}
              </p>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Name"
                required
                autocomplete="off"
                v-model="add_data.name"
              />
              <label for="name">Name </label>
            </div>
          </div>
          <div class="w-full space-y-3">
            <label
              for="email"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              E-mail address
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="example@example.com"
              class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded text-sm"
              autocomplete="off"
              v-model="add_data.email"
            />
            <span class="error">
              {{ (add_errors.email || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="mobile"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Mobile number
            </label>
            <vue-tel-input
              id="mobile"
              ref="customerMobile"
              v-model="add_data.mobile"
              @open="handleOpen('customerMobile')"
            ></vue-tel-input>
            <span class="error">
              {{ (add_errors.mobile || []).join(" ") }}
            </span>
          </div>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors.max_discount_percentage">
                {{ add_errors.max_discount_percentage.join(" ") }}
              </p>
              <input
                type="number"
                min="0"
                max="100"
                step="any"
                name="max_discount_percentage"
                id="max_discount_percentage"
                placeholder="0.00"
                autocomplete="off"
                v-model="add_data.max_discount_percentage"
              />
              <label for="max_discount_percentage">Max discount (%)</label>
            </div>
          </div>
          <div class="w-full space-y-3">
            <label
              for="user_type"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Type
            </label>
            <select v-model="add_data.user_type" class="custom-select text-sm">
              <option value="" selected disabled>-- select --</option>
              <option value="cashier">Cashier</option>
              <option value="vendor_user">Portal user</option>
            </select>
            <span class="error">
              {{ (add_errors.user_type || []).join(" ") }}
            </span>
          </div>
          <div class="space-y-4" v-if="add_data.user_type === 'cashier'">
            <div class="form-group">
              <div class="with-label">
                <p class="error" v-if="add_errors.pin">
                  {{ add_errors.pin.join(" ") }}
                </p>
                <input
                  type="number"
                  min="0"
                  max="100"
                  step="any"
                  name="pin"
                  id="pin"
                  placeholder="0000"
                  autocomplete="off"
                  v-model="add_data.pin"
                />
                <label for="pin">PIN</label>
              </div>
            </div>
            <div class="w-full space-y-3">
              <label
                for="device"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                POS Device
              </label>
              <multiselect
                v-model="add_data.pos_device_id"
                :placeholder="'search'"
                :label="'code'"
                track-by="id"
                :options="devices"
                :hideSelected="false"
                :clearOnSelect="true"
                :disabled="devices.length === 0"
              ></multiselect>
              <span class="error">
                {{ (add_errors.pos_device_id || []).join(" ") }}
              </span>
            </div>
          </div>
          <div class="space-y-4" v-else>
            <div class="w-full space-y-3">
              <label
                for="password"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                Password
              </label>
              <div class="relative">
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium pr-12 rtl:pr-4 rtl:pl-4 rounded text-sm"
                  autocomplete="off"
                  v-model="add_data.password"
                />
                <button
                  class="w-12 h-full flex items-center justify-center text-gray-500 text-sm absolute top-0 right-0 rtl:left-0 rtl:right-auto"
                  type="button"
                  @click.prevent="textPasswordType"
                >
                  <i class="fa-solid fa-eye"></i>
                </button>
              </div>

              <span class="error">
                {{ (add_errors.password || []).join(" ") }}
              </span>
            </div>
            <div class="w-full space-y-3">
              <label
                for="role_id"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                Role
              </label>
              <select v-model="add_data.role_id" class="custom-select text-sm">
                <option value="" selected disabled>-- select --</option>
                <option v-for="role in roles" :key="role.id" :value="role.id">
                  {{ role.name || role.alt_name }}
                </option>
              </select>

              <span class="error">
                {{ (add_errors.role_id || []).join(" ") }}
              </span>
            </div>
          </div>

          <!-- <div class="w-full space-y-3">
            <label
              for="branch"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Branch
            </label>
            <multiselect
              v-model="add_data.store_id"
              :placeholder="'search'"
              :label="`name_${$i18n.locale}`"
              track-by="id"
              :options="stores"
              :hideSelected="false"
              :clearOnSelect="true"
              :disabled="stores.length === 0"
            ></multiselect>
            <span class="error">
              {{ (add_errors.store_id || []).join(" ") }}
            </span>
          </div> -->
          <div>
            <button-with-loader
              :disabled="add_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="addItem"
            >
              Add
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
    <Modal
      :title="JSON.parse(JSON.stringify(edit_data.name))"
      v-if="edit_active"
      @close="
        (e) => {
          edit_active = e;
        }
      "
    >
      <template>
        <form class="p-6 space-y-4">
          <figure class="space-y-4">
            <slide-transition>
              <figcaption v-if="edit_data.image" class="relative table">
                <img
                  :src="
                    edit_data.image
                      ? typeof edit_data.image === 'object'
                        ? convertImage(edit_data.image)
                        : edit_data.image
                      : require('@/assets/images/placeholder.png')
                  "
                  @error="
                    (e) =>
                      (e.target.src = require('@/assets/images/placeholder.png'))
                  "
                  alt="branch image"
                  class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
                />
                <button
                  class="w-6 h-6 rounded-full flex items-center justify-center bg-red-500 text-white absolute top-0 right-0 rtl:right-auto rtl:left-0 ring-2 ring-white"
                  type="button"
                  @click.prevent="() => clearImage('edit_data')"
                >
                  <i class="fa-solid fa-xmark fa-sm"></i>
                </button>
              </figcaption>
            </slide-transition>
            <blockquote class="space-y-2">
              <label class="block">
                <span class="sr-only">Choose image</span>
                <input
                  type="file"
                  accept="image/*"
                  class="custom-file"
                  ref="uploader"
                  @change="(e) => $set(edit_data, 'image', e.target.files[0])"
                />
              </label>
              <p class="error" v-if="edit_errors.image">
                {{ edit_errors.image.join(" ") }}
              </p>
            </blockquote>
          </figure>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors.name">
                {{ edit_errors.name.join(" ") }}
              </p>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Name"
                required
                autocomplete="off"
                v-model="edit_data.name"
              />
              <label for="name">Name </label>
            </div>
          </div>
          <div class="w-full space-y-3">
            <label
              for="email"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              E-mail address
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="example@example.com"
              class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded text-sm"
              autocomplete="off"
              v-model="edit_data.email"
            />
            <span class="error">
              {{ (edit_errors.email || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="mobile"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Mobile number
            </label>
            <vue-tel-input
              id="mobile"
              ref="customerMobile"
              v-model="edit_data.mobile"
              @open="handleOpen('customerMobile')"
            ></vue-tel-input>
            <span class="error">
              {{ (edit_errors.mobile || []).join(" ") }}
            </span>
          </div>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors.max_discount_percentage">
                {{ edit_errors.max_discount_percentage.join(" ") }}
              </p>
              <input
                type="number"
                min="0"
                max="100"
                step="any"
                name="max_discount_percentage"
                id="max_discount_percentage"
                placeholder="0.00"
                autocomplete="off"
                v-model="edit_data.max_discount_percentage"
              />
              <label for="max_discount_percentage">Max discount (%)</label>
            </div>
          </div>
          <div class="space-y-4" v-if="edit_data.user_type === 'cashier'">
            <div class="form-group">
              <div class="with-label">
                <p class="error" v-if="edit_errors.pin">
                  {{ edit_errors.pin.join(" ") }}
                </p>
                <input
                  type="number"
                  min="0"
                  max="100"
                  step="any"
                  name="pin"
                  id="pin"
                  placeholder="0000"
                  autocomplete="off"
                  v-model="edit_data.pin"
                />
                <label for="pin">PIN</label>
              </div>
            </div>
            <div class="w-full space-y-3">
              <label
                for="device"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                POS Device
              </label>
              <multiselect
                v-model="edit_data.pos_device_id"
                :placeholder="'search'"
                :label="'code'"
                track-by="id"
                :options="devices"
                :hideSelected="false"
                :clearOnSelect="true"
                :disabled="devices.length === 0"
              ></multiselect>
              <span class="error">
                {{ (edit_errors.pos_device_id || []).join(" ") }}
              </span>
            </div>
          </div>
          <div class="space-y-4" v-else>
            <div class="w-full space-y-3">
              <label
                for="password"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                Password
              </label>
              <div class="relative">
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium pr-12 rtl:pr-4 rtl:pl-4 rounded text-sm"
                  autocomplete="off"
                  v-model="edit_data.password"
                />
                <button
                  class="w-12 h-full flex items-center justify-center text-gray-500 text-sm absolute top-0 right-0 rtl:left-0 rtl:right-auto"
                  type="button"
                  @click.prevent="textPasswordType"
                >
                  <i class="fa-solid fa-eye"></i>
                </button>
              </div>

              <span class="error">
                {{ (edit_errors.password || []).join(" ") }}
              </span>
            </div>
            <div class="w-full space-y-3">
              <label
                for="role_id"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                Role
              </label>
              <select v-model="edit_data.role_id" class="custom-select text-sm">
                <option value="" selected disabled>-- select --</option>
                <option v-for="role in roles" :key="role.id" :value="role.id">
                  {{ role.name || role.alt_name }}
                </option>
              </select>

              <span class="error">
                {{ (edit_errors.role_id || []).join(" ") }}
              </span>
            </div>
          </div>

          <!-- <div class="w-full space-y-3">
            <label
              for="branch"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Branch
            </label>
            <multiselect
              v-model="edit_data.store_id"
              :placeholder="'search'"
              :label="`name_${$i18n.locale}`"
              track-by="id"
              :options="stores"
              :hideSelected="false"
              :clearOnSelect="true"
              :disabled="stores.length === 0"
            ></multiselect>
            <span class="error">
              {{ (edit_errors.store_id || []).join(" ") }}
            </span>
          </div> -->
          <div>
            <button-with-loader
              :disabled="edit_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="editItem"
            >
              Save changes
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
  </div>
</template>
<script>
import Modal from "@/components/basics/Modal.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
export default {
  name: "Users",
  data() {
    return {
      load: false,
      items: [],
      stores: [],
      add_data: {
        pos_device_id: null,
        user_type: "",
      },
      add_errors: {},
      add_disabled: false,
      add_active: false,

      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,

      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-500/10 text-red-500",
      },
      status: ["all", "active", "not active"],
      pagination: {
        is_active: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      disables: {},
      devices: [],
      roles: [],
    };
  },
  async created() {
    await Promise.all([
      this.getStores(),
      this.getItems(),
      this.getDevices(),
      this.getRoles(),
    ]).finally(() => (this.load = true));
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
    add_active(val, _) {
      if (!val) {
        const uploader = this.$refs.uploader;
        this.add_data = {};
        this.add_errors = {};
        uploader.value = null;
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getStores() {
      try {
        const { result } = await this.getRoute("stores");
        this.stores = result.stores;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getRoles() {
      try {
        const { result } = await this.getRoute("roles");
        this.roles = result.roles;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getDevices() {
      try {
        const { result } = await this.getRoute("pos_devices");
        this.devices = result.pos_devices?.map((ev) => ({
          ...ev,
        }));
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;

        const { result } = await this.getRoute({
          name: "users",
          options: {
            ...others,
            is_active: others.is_active
              ? others.is_active === "active"
                ? 1
                : 0
              : null,
            search_key: this.search,
            user_type: ["cashier", "vendor_user"],
          },
        });
        const { data, pagination } = result.users;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.changeIsCalled(false);
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(`users/update_is_active/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { data } = await this.axios.post(`users/delete/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async addItem() {
      try {
        this.add_disabled = true;
        this.add_errors = {};
        let fd = new FormData();

        for (const key in this.add_data) {
          const item = this.add_data[key];
          if (item) {
            if (["store_id", "pos_device_id"].includes(key)) {
              fd.append(key, item?.id);
            } else if (key === "image") {
              if (item) {
                fd.append(key, item, item?.name);
              }
            } else if (key === "mobile") {
              fd.append(key, item?.replace(/[+|\s|()|-]/g, ""));
            } else if (key === "user_type") {
              fd.append(key, item);
              if (this.add_data.role_id) fd.delete("role_id");
            } else {
              fd.append(key, item);
            }
          }
        }

        const { data } = await this.axios.post("users/add", fd);
        this.add_data = {};
        this.add_active = false;
        this.$refs.uploader.value = null;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.add_disabled = false;
      }
    },
    async editItem() {
      try {
        this.edit_disabled = true;
        this.edit_errors = {};
        let fd = new FormData();

        for (const key in this.edit_data) {
          const item = this.edit_data[key];
          if (item) {
            if (["store_id", "pos_device_id"].includes(key)) {
              fd.append(key, item?.id);
            } else if (key === "image") {
              if (item && typeof item === "object") {
                fd.append(key, item, item?.name);
              } else {
                fd.delete(key);
              }
            } else if (key === "mobile") {
              fd.append(key, item?.replace(/[+|\s|()|-]/g, ""));
            } else {
              fd.append(key, item);
            }
          }
        }

        const { data } = await this.axios.post("users/update", fd);
        this.edit_data = {};
        this.edit_active = false;
        this.$refs.uploader.value = null;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.edit_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.edit_disabled = false;
      }
    },
    clearImage(type) {
      const uploader = this.$refs.uploader;
      if (!uploader) return;
      uploader.value = null;
      this.$set(this[type], "image", null);
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id);
      if (!item) {
        this.createAlert("Item does not existed", "info");
        return;
      }
      this.edit_data = {
        ...item,
        // store_id: item?.branch,
        role_id: item?.role?.id,
        pos_device_id: {
          ...item?.pos_device,
        },
      };
      this.edit_active = true;
    },

    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = "asc";
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == "asc" ? "desc" : "asc";
      }
      this.pagination.sort_key = key;
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`);
      });
    },

    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "is_active", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
  },
  components: { Modal, ButtonWithLoader },
};
</script>
