<template>
  <section class="w-full p-6 space-y-6">
    <analytics-container-wrapper class="space-y-6">
      <filter-wrapper
        @apply="getItems"
        @reset="handleReset"
        ref="filterWrapper"
      >
        <div>
          <t-datepicker
            :placeholder="`From - To`"
            multiple
            :monthsPerView="2"
            v-model="pagination.date"
            range
            :clearable="false"
            userFormat="Y-m-d"
            :weekStart="6"
          />
        </div>
      </filter-wrapper>
      <Table
        title="Expired"
        :records="`${pagination.total || 0} records`"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
      >
        <template #filter>
          <ul class="flex items-center">
            <li v-for="(item, index) in pages" :key="index">
              <router-link
                :to="{
                  path: item.path,
                }"
                class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize block"
                exact-active-class="active-tab"
              >
                {{ item.name }}
              </router-link>
            </li>
          </ul>
        </template>
        <template #head>
          <th>Product</th>
          <th>Price (SAR)</th>
          <!-- <th>SKU</th> -->
          <!-- <th>Barcode</th> -->
          <th>Qty | Weight</th>
          <th>Status</th>
          <!-- <th>Production date</th> -->
          <!-- <th>Expiry date</th> -->
          <th>Latest update</th>
          <!-- <th>Actions</th> -->
        </template>
        <template #tbody v-if="!load">
          <TableLoad :rows="5" v-for="i in 4" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length">
          <tr v-for="(product, index) in items" :key="index">
            <td>
              <div class="flex items-center gap-3">
                <div class="w-10 h-10">
                  <img
                    :src="
                      product.image ||
                      require('@/assets/images/placeholder.png')
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    alt="product image"
                    class="w-full h-full rounded-md border object-cover border-gray-100"
                  />
                </div>
                <span
                  class="inline-block line-clamp-2 max-w-sm whitespace-normal"
                >
                  {{ product["name_" + $i18n.locale] }}
                </span>
              </div>
            </td>

            <td>{{ product.price }}</td>

            <!-- <td>{{ product.sku || "-" }}</td>
            <td>{{ product.barcode || "-" }}</td> -->
            <td>
              <span v-if="product.unit_type === 'weight'">
                {{ GenerateUnit(product.weight || 0) }}
              </span>
              <span v-else> {{ product.qty || 0 }} Piece </span>
            </td>
            <td>
              <p
                class="status capitalize"
                :class="item_status[product.is_active]"
              >
                {{ product.is_active === 1 ? "Active" : "Not active" }}
              </p>
            </td>

            <!-- <td v-html="createTime(product.prod_date)"></td>
            <td v-html="createTime(product.exp_date)"></td> -->
            <td
              v-html="createTime(product.updated_at || product.created_at)"
            ></td>

            <!-- <td>
              <div class="flex items-center gap-2">
                <div>
                  <button
                    class="
                      px-2
                      py-1.5
                      text-sm
                      rounded
                      text-white
                      font-medium
                      rtl:font-semibold
                      bg-red-500
                    "
                  >
                    Remove from stock
                  </button>
                </div>
              </div>
            </td> -->
          </tr>
        </template>

        <template #tbody v-else>
          <tr>
            <td colspan="5" class="!text-center">There are no products</td>
          </tr>
        </template>
      </Table>
    </analytics-container-wrapper>
  </section>
</template>
<script>
import Modal from "@/components/basics/Modal.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import AnalyticsContainerWrapper from "@/components/AnalyticsContainerWrapper.vue";
import FilterWrapper from "@/components/basics/FilterWrapper.vue";
export default {
  name: "NearExpiry",
  data() {
    return {
      load: false,
      items: [],
      currentPage: 1,
      rows: 0,
      totalPages: 0,
      perPage: 1,
      active: false,
      product_id: null,
      delete_disabled: false,
      status_disabled: [],
      error: "",
      search_key: "",
      search_disabled: false,
      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-500/10 text-red-500",
      },
      status: ["all", "active", "not active"],
      pagination: {
        is_active: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      disables: {},
      pages: [
        {
          name: "OOS",
          path: "/analytics/alerts",
        },
        // {
        //   name: "Near Expiry",
        //   path: "/analytics/alerts/near-expiry",
        // },
        {
          name: "Expired",
          path: "/analytics/alerts/expired",
        },
      ],
    };
  },
  created() {
    this.$set(this.pagination, "date", [
      this.$moment().add(-1, "days").format("YYYY-MM-DD"),
      this.$moment().format("YYYY-MM-DD"),
    ]);
    this.getItems();
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getItems() {
      try {
        const { total, total_pages, date, ...others } = this.pagination;

        const { result } = await this.getRoute({
          name: "products",
          options: {
            ...others,
            is_active: others.is_active
              ? others.is_active === "active"
                ? 1
                : 0
              : null,
            search_key: this.search,
            from: date?.[0],
            to: date?.[1],
            is_inventory: 1,
            alert_type: "expired",
            store_id: this.store_id,
          },
        });
        const { data, pagination } = result.products;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch (error) {
        console.log(error);
        // this.createAlert(result?.message, type);
      } finally {
        this.load = true;
        this.changeIsCalled(false);
        const filterWrapper = this.$refs.filterWrapper;
        filterWrapper?.resetAll();
      }
    },
    handleReset() {
      const { page, ...others } = this.pagination;

      for (const key in others) {
        const item = others[key];
        if (typeof item === "number") {
          this.$set(this.pagination, key, 0);
        } else if (Array.isArray(item)) {
          this.$set(this.pagination, key, []);
        } else {
          this.$set(this.pagination, key, null);
        }
      }

      this.getItems();
    },
    addToCart(id) {
      let item = this.items.find((product) => product.id == id),
        cart = JSON.parse(localStorage.getItem("cart")) || [],
        isSame = cart.some((ca) => ca.id == id);
      if (isSame) {
        let product = cart.find((p) => p.id == id);
        Object.assign(product, {
          qty: product.qty + 1,
        });
        Object.assign(cart[cart.findIndex((i) => i.id == id)], product);
      } else {
        cart.push({
          ...item,
          qty: 1,
        });
      }
      localStorage.setItem("cart", JSON.stringify([...new Set(cart)]));
      this.$store.commit("SET_CART", JSON.parse(localStorage.getItem("cart")));
      this.createAlert("Product added to cart successfully");
      // this.createAlert(
      //
      //   this.$i18n.locale == "ar"
      //     ? "تم اضافة المنتج في السلة بنجاح"
      //     : "Product added to cart successfully"
      // );
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = "asc";
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == "asc" ? "desc" : "asc";
      }
      this.pagination.sort_key = key;
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`);
      });
    },

    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "is_active", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
  },
  components: { Modal, AnalyticsContainerWrapper, FilterWrapper },
};
</script>
