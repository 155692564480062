<template>
  <fragment>
    <tr>
      <td class="!px-0">
        <button
          class="w-10 text-gray-600 flex items-center justify-center"
          @click.prevent="show_active = !show_active"
          v-show="!!item.sub_accounts?.length"
        >
          <i class="fa-solid fa-angle-down" v-if="show_active"></i>
          <span v-else>
            <i class="fa-solid fa-angle-right rtl:hidden"></i>
            <i class="fa-solid fa-angle-left hidden rtl:inline"></i>
          </span>
        </button>
      </td>
      <td
        :style="{
          'padding-inline-start': item.padding + 'px',
        }"
      >
        {{ item.code }} - {{ item.name }}
      </td>
      <td>{{ item.type || "-" }}</td>
      <td>{{ generateCurrency(item.balance || 0) }}</td>
      <td>
        <p class="status capitalize" :class="item_status[item.is_active]">
          {{ item.is_active === 1 ? "Active" : "Not active" }}
        </p>
      </td>
      <td v-html="createTime(item.updated_at || item.created_at)"></td>
      <td>
        <div class="actions-group items-center">
          <div v-if="hasPermission('add accounting_chart')">
            <button
              type="button"
              class="flex items-center gap-2 text-sm text-gray-600 font-medium"
              @click.prevent="openModal"
            >
              <i class="fa-solid fa-plus"></i>
              New account
            </button>
          </div>
          <div v-if="hasPermission('update accounting_chart')">
            <button
              class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
              @click.prevent="changeStatus(item.id)"
              :content="'Change status'"
              v-tippy
              :disabled="disables[`status_${item.id}`]"
            >
              <spinner
                size="w-4 h-4 inline-block"
                v-if="disables[`status_${item.id}`]"
              />
              <i class="fa-solid fa-right-left" v-else></i>
            </button>
          </div>
          <div v-if="hasPermission('edit accounting_chart')">
            <button
              class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
              @click.prevent="openUpdateModal"
              :content="'Edit account'"
              v-tippy
            >
              <i class="fa-solid fa-pen"></i>
            </button>
          </div>
          <div>
            <router-link
              :to="'/accounting/chart/' + item.id + '/details'"
              class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded flex items-center justify-center"
            >
              <i class="fa-solid fa-eye"></i>
            </router-link>
          </div>

          <!-- <div v-if="hasPermission('delete accounting_chart') || true">
            <button
              class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
              @click.prevent="deleteItem(item.id)"
              :disabled="disables[`delete_${item.id}`]"
            >
              <spinner
                size="w-4 h-4 inline-block"
                v-if="disables[`delete_${item.id}`]"
              />
              <span v-else> Delete </span>
            </button>
          </div> -->
        </div>
      </td>
    </tr>
    <template v-if="show_active">
      <ItemTree
        v-for="account in accounts"
        :key="account.id"
        :item="account"
        :getItems="getItems"
      />
    </template>
    <add-account
      :id="item.id"
      :title="`${item.name} - new account`"
      @change="getItems"
      ref="AddAccount"
    />
    <update-account
      :title="`${item.name}`"
      ref="UpdateAccount"
      :item="JSON.parse(JSON.stringify(edit_data))"
      @change="getItems"
    />
  </fragment>
</template>

<script>
import AddAccount from "./AddAccount.vue";
import UpdateAccount from "./UpdateAccount.vue";
export default {
  components: { AddAccount, UpdateAccount },
  name: "ItemTree",
  props: {
    item: {
      type: Object,
      default: {},
    },
    getItems: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      show_active: false,
      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-500/10 text-red-500",
      },
      disables: {},
      edit_data: {},
    };
  },
  computed: {
    accounts() {
      const data = this.item.sub_accounts || [];
      return data.map((e) => ({
        ...e,
        padding: this.item.padding ? 2 * this.item.padding : 16,
      }));
    },
  },
  methods: {
    openModal() {
      this.$refs.AddAccount.show();
    },
    openUpdateModal() {
      this.edit_data = this.item;
      this.$refs.UpdateAccount.show();
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(
          `accounts/update_is_active/${id}`
        );
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { data } = await this.axios.post(`accounts/delete/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
  },
};
</script>
