import _ from "lodash";
import { serialize } from "object-to-formdata";
import { v4 as uuid } from "uuid";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      subProducts: [],
      concatOptions: [],
      tab: "basic",
      tabs: [
        {
          title: "Basic information",
          name: "basic",
        },
        {
          title: "Prices",
          name: "prices",
        },
      ],
      pack: "",
      packages: [],
      final_packages: [],
      final_options: [],
      basic_product_data: {
        name_ar: null,
        name_en: null,
        is_sellable: 1,
        is_purchasable: 1,
        tracking_stock: 1,
        cost_price_includes_vat: 1,
        sale_price_includes_vat: 1,
      },
    };
  },
  watch: {
    basic_product_data: {
      handler() {
        this.displayPrices();
      },
      deep: true,
      immediate: true,
    },

    "add_data.products_of_bundle": {
      handler() {
        let total = 0;
        if (this.product_type !== "bundle") return;
        this.add_data.products_of_bundle?.forEach((product) => {
          total += product.price || 0;
        });
        this.add_data.cost_price = total;
        this.basic_product_data.cost_price = total;
      },
      deep: true,
      immediate: true,
    },
    packages: {
      handler() {
        this.final_packages = this.packages.map((pack) => {
          const package_size = Number(pack.text);
          const id = uuid();

          const existed = this.final_packages.find(
            (e) => e.package_size == package_size
          );
          return existed
            ? existed
            : {
                package_size,
                id: pack.id || id,
                type: "package",
              };
        });

        this.displayPrices();
      },
      deep: true,
      immediate: true,
    },
    options: {
      handler() {
        const values = new Array().concat(
          ...this.options.map((option) =>
            option.values.map((value) => ({
              ...value,
              option_id: option.id,
              value_id: value.id,
            }))
          )
        );

        this.final_options = values.map((value) => {
          const existed = this.final_options.find((e) => e.id == value.id);
          const option = this.options.find((opt) => opt.id == value.option_id);
          const name_ar = `${this.basic_product_data.name_ar} ${option.name_ar} ${value.name_ar}`;
          const name_en = `${this.basic_product_data.name_en} ${option.name_en} ${value.name_en}`;
          const option_value = {
            name_ar: option.name_ar,
            name_en: option.name_en,
            value_ar: value.name_ar,
            value_en: value.name_en,
          };

          return existed
            ? {
                ...existed,
                name_ar,
                name_en,
                option_values: [option_value],
              }
            : {
                ...value,
                name_ar,
                name_en,
                option_values: [option_value],
                type: "child",
              };
        });

        this.displayPrices();
      },
      deep: true,
      immediate: true,
    },

    product_type() {
      if (this.product_type === "bundle") {
        this.add_data.is_sellable = 1;
        this.basic_product_data.is_sellable = 1;
        this.add_data.unit_type = "normal";
        return;
      }

      this.options = [];
      this.packages = [];
      this.subProducts = [];
    },
  },
  computed: {
    packagesErrors() {
      return (index, storeIndex) => {
        const errors = this.add_errors;
        const basicKeys = [
          "name_ar",
          "name_en",
          "desc_ar",
          "desc_en",
          "image",
          "is_purchasable",
          "is_sellable",
          "sku",
          "barcode",
          "item_code",
          "stores",
        ].map((key) => "sub_products." + index + "." + key);
        const stores = [
          "cost_price",
          "cost_price_includes_vat",
          "discount_type",
          "discount_value",
          "id",
          "initial_stock",
          "sale_price",
          "sale_price_includes_vat",
          "service_fees_type",
          "service_fees_value",
        ].map(
          (key) => "sub_products." + index + ".stores." + storeIndex + "." + key
        );

        const concatErrors = [...basicKeys, ...stores];
        const errorKeys = new Array().concat(
          ...Object.entries(errors)
            .filter(([key]) => concatErrors.includes(key))
            .map(([key, value]) => value)
        );
        return errorKeys;
      };
    },
    productsPrice() {
      let total = 0;
      if (this.product_type !== "bundle") return;
      this.add_data.products_of_bundle.forEach((product) => {
        total += product.price || 0;
      });
      return total;
    },
    productTypeTitle() {
      return this.types.find((type) => this.product_type == type.key)?.title;
    },
  },
  async created() {
    await Promise.all([
      this.getStores(),
      this.getSuppliers(),
      this.getCategories(),
      this.getBrands(),
    ]).finally(() => {
      this.loaded = false;
    });
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),

    async getStores() {
      try {
        const { result } = await this.getRoute({
          name: "stores",
        });
        const stores = result.stores;
        this.stores = stores;
        this.selected_stores = stores.map((e) => e.id);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getSuppliers() {
      try {
        const { result } = await this.getRoute({
          name: "users",
          options: { user_type: "supplier" },
        });
        this.suppliers = result.users;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getCategories() {
      try {
        const { result } = await this.getRoute({
          name: "categories",
        });
        const categories = result.categories;
        this.categories = categories;
        // this.selected_stores = stores.map((e) => e.id);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getBrands() {
      try {
        const { result } = await this.getRoute({
          name: "brands",
        });
        const brands = result.brands;
        this.brands = brands;
        // this.selected_stores = stores.map((e) => e.id);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },

    convertImage(e) {
      return URL.createObjectURL(e);
    },
    clearImage(type, ref) {
      const uploader = this.$refs[ref];
      if (!uploader) return;
      uploader.value = null;
      this.$set(this[type], "image", null);
    },

    addNewOption() {
      const length = this.options.length;
      const size = length ? length + 1 : 1;
      const item = {
        image: null,
        name_ar: ["اسم الخيار", size].join(" "),
        name_en: ["Option name", size].join(" "),
        values: [],
        id: uuid(),
      };
      // this.add_option_active = true;
      this.options.push(item);
    },
    async addNewValue(index) {
      const values = this.options[index].values || [];
      const length = values.length;
      const size = length ? length + 1 : 1;
      const value = {
        name_ar: ["اسم القيمة", size].join(" "),
        name_en: ["Value name", size].join(" "),
        id: uuid(),
      };
      this.$set(this.options[index], "values", [...values, value]);

      // item.values.push(value);
    },
    async removeValue(option, value) {
      const options = this.options.map((e, i) => {
        if (option == i) {
          return {
            ...e,
            values: e.values.splice(0, value),
          };
        }
        return e;
      });
      this.options = options;
    },
    async removeOption(id) {
      const filtered = this.options.filter((e, i) => e.id !== id);
      this.options = filtered;
    },
    generateItemCode() {
      const time = Date.now().toString();
      return time.slice(time.length - 5, time.length);
    },
    async generateSubProductsCode(index) {
      try {
        const type = this.add_data.unit_type;
        let key = type === "weight" ? "item_code" : "barcode";
        const { result } = await this.getRoute({
          name: "products/generate-code",
          options: { type: key },
        });
        // this.$set(this.basic_product_data, "sku", result.code);
        this.$set(this.subProducts[index], key, result.code);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async generateSKU() {
      try {
        const { result } = await this.getRoute({
          name: "products/generate-code",
          options: { type: "sku" },
        });
        this.$set(this.basic_product_data, "sku", result.code);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async generateItemCodeAndBarcode(key) {
      // const time = Date.now().toString();
      // let value;

      // value =
      //   key === "item_code" ? time.slice(time.length - 5, time.length) : time;

      // this.$set(this.basic_product_data, key, value);
      try {
        const { result } = await this.getRoute({
          name: "products/generate-code",
          options: { type: key },
        });
        this.$set(this.basic_product_data, key, result.code);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async generateSubProductsSKU(index) {
      try {
        const { result } = await this.getRoute({
          name: "products/generate-code",
          options: { type: "sku" },
        });
        // this.$set(this.basic_product_data, "sku", result.code);
        this.$set(this.subProducts[index], "sku", result.code);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    collectProductData() {
      return new Promise((resolve) => {
        let result = {};
        const data = this.add_data;
        const basic = this.basic_product_data;
        const stores = this.selected_stores;
        const products = this.subProducts.map((product) => ({
          ...product,
          name_ar: product.name,
          name_en: product.name,
        }));

        const options = this.options;
        const sub_products = [...products];
        result = {
          ...data,
          ...basic,
          ...(sub_products.length && { sub_products }),
          ...(options.length && { options }),
          ...(stores.length && { store_ids: stores }),
          product_type: this.product_type,
        };

        let fd = serialize(result, {
          indices: true,
          booleansAsIntegers: true,
          nullsAsUndefineds: true,
        });

        const keys = [];
        for (const key of fd.keys()) {
          keys.push(key);
        }

        const findType = keys.find((key) => /unit_type/gi.test(key));
        const unitType = fd.get(findType);
        const filterKeys = keys.filter((key) =>
          unitType === "weight"
            ? /barcode/gi.test(key)
            : /item_code/gi.test(key)
        );
        filterKeys.forEach((key) => fd.delete(key));

        console.log(unitType, filterKeys);

        resolve(fd);
      });
    },

    async addProduct() {
      try {
        this.add_disabled = true;
        const product = await this.collectProductData();
        const { data } = await this.axios.post("products/add", product);
        this.createAlert(data.message);
        this.$router.push("/inventory-setup");
      } catch (error) {
        console.log(error);
        const err = error.response.data;
        const { result, message } = err;
        const isObject = message instanceof Object;
        if (isObject) {
          this.add_errors = message;
          return;
        }
        this.createAlert(message, "error");
      } finally {
        this.add_disabled = false;
      }
    },

    // upload images

    // upload images
    showImageUploader(item, ref) {
      const uploader = this.$refs[ref];
      if (!uploader)
        return this.createAlert("Uploader is not available.", "error");
      uploader.show();
      this.change_image_data = item;
    },

    onUploaderChangeImage(data, key) {
      const id = this.change_image_data.id;
      this[key] = this[key].map((e) => {
        const isSame = e.id === id;
        if (isSame) {
          return {
            ...e,
            image: data,
          };
        }
        return e;
      });

      this.change_image_data = {};
    },
    async getOtherProducts(q) {
      try {
        const { result } = await this.getRoute({
          name: "products",
          options: {
            search_key: q,
            page: 1,
            store_id: this.store_id,
          },
        });
        const products = result.products.data;
        // const addProducts = this.add_data?.products_of_bundle?.map((e) => e.id);
        // const filterProducts = products.filter(
        //   (e) => !addProducts.includes(e.id) && e.id !== productID
        // );
        this.bundle_products = products;
        return { results: products };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    handleOtherProductsChange(id) {
      let result = [];
      const products = this.add_data.products_of_bundle || [];
      const isExisted = products.find((e) => e.id == id);

      if (isExisted) return;

      const product = this.bundle_products.find((e) => e.id == id);
      const item = {
        name_ar: product?.name_ar,
        name_en: product?.name_en,
        image: product?.image,
        price: product?.price,
        unit_type: product?.unit_type,
        id,
        value: null,
      };
      result = [...products, item];
      this.$set(this.add_data, "products_of_bundle", result);
      this.createAlert(
        "Product added ( " + item["name_" + this.$i18n.locale] + " )"
      );

      // this.product_id = null;
    },
    removeBundleProduct(index) {
      this.add_data.products_of_bundle =
        this.add_data.products_of_bundle.filter((e, i) => i !== index);
    },
    collectPriceViaVat(value = 0, included = false) {
      let total = 0;
      let vat = 1.15;
      let total_with_vat = 0;
      let result;
      const price = Number(value);
      if (included) {
        total = (price - price) / vat;
        total_with_vat = total + price;
      } else {
        total = (price * 15) / 100 + price;
      }

      result = parseFloat(
        ((included ? total_with_vat : total) || 0).toFixed(2)
      );
      return result;
    },

    collectPriceViaServices(
      value = 0,
      price = 0,
      included = false,
      type = "fixed"
    ) {
      let result;
      let total = 0;
      let services;
      const val = Number(value);
      const amount = this.collectPriceViaVat(price, included);

      if (type === "percentage") {
        services = (amount * val) / 100;
        total = amount + services;
      } else {
        services = val;
        total = amount + val;
      }
      result = {
        services: parseFloat((services || 0).toFixed(2)),
        total: parseFloat((total || 0).toFixed(2)),
      };

      return result;
    },

    displayPrices() {
      let result = [];
      const packages = this.final_packages;
      const options = this.final_options;
      const type = this.product_type;
      const subProducts = this.subProducts;
      const product = this.basic_product_data;
      const getBasicDataFromBasicProduct = _.pick(product, [
        "is_sellable",
        "is_purchasable",
        "tracking_stock",
        "cost_price_includes_vat",
        "sale_price_includes_vat",
      ]);

      const object = {
        ...getBasicDataFromBasicProduct,
      };

      switch (type) {
        case "simple":
          if (!(product.name_ar || product.name_en)) return;
          result = packages.map((pack) => {
            const existed = subProducts.find((e) => e.id == pack.id);
            const name_ar = `${pack.package_size}x ${product.name_ar}`;
            const name_en = `${pack.package_size}x ${product.name_en}`;
            const name = `${pack.package_size}x`;
            console.log(pack.id, subProducts, existed);
            return existed
              ? {
                  ...existed,
                  ...pack,
                  name_ar,
                  name_en,
                }
              : {
                  ...product,
                  ...pack,
                  ...object,
                  name_ar,
                  name_en,
                  name,
                };
          });
          break;
        case "variable":
          if (!(options.length || packages.length)) return;

          const mergeOptionsWithPackages = new Array().concat(
            ...packages.map((pack) => {
              return options.map((option) => {
                const id = uuid();
                // const omitOptionData = _.omit( option, [ 'id' ] );
                const existed = subProducts.find(
                  (e) =>
                    (e.value_id || e.id) == option.id &&
                    e.type == "package" &&
                    e.package_size == pack.package_size
                );
                const name_ar = `${pack.package_size}x ${option.name_ar}`;
                const name_en = `${pack.package_size}x ${option.name_en}`;
                const name = name_ar || name_en;
                const optionObj = Object.assign({}, option, pack, object, {
                  name_ar,
                  name_en,
                  id,
                  name,
                });

                return existed
                  ? {
                      ...option,
                      ...existed,
                      name_ar,
                      name_en,
                    }
                  : optionObj;
              });
            })
          );
          const updatedOptions = options.map((option) => {
            const existed = subProducts.find((sub) => sub.id == option.id);
            const finalOption = Object.assign(
              {},
              option,
              existed ? existed : { ...existed, ...object },
              {
                name: option.name_ar || option.name_en,
              }
            );
            return finalOption;
          });

          result = [...updatedOptions, ...mergeOptionsWithPackages];
          break;
        case "bundle":
          break;
      }

      this.subProducts = result;
    },
  },
};
