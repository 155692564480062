<template>
  <div class="p-6">
    <card title="Store details">
      <div class="grid grid-cols-2 gap-4" v-if="load">
        <div class="col-span-2">
          <img
            class="w-20 h-20 rounded-lg bg-gray-100 object-cover"
            :src="store.logo || require('@/assets/images/placeholder.png')"
            @error="
              $event.target.src = require('@/assets/images/placeholder.png')
            "
          />
        </div>
        <div class="col-span-2 space-y-1">
          <p class="text-xs text-gray-500 font-medium">Name</p>
          <p class="text-sm font-semibold">
            {{ store["name_" + $i18n.locale] || "N/A" }}
          </p>
        </div>

        <div class="space-y-1">
          <p class="text-xs text-gray-500 font-medium">Module</p>
          <p class="text-sm font-semibold">
            {{ store.module?.["name_" + $i18n.locale] || "N/A" }}
          </p>
        </div>
        <div class="space-y-1">
          <p class="text-xs text-gray-500 font-medium">Region</p>
          <p class="text-sm font-semibold">
            {{ store.region?.["name_" + $i18n.locale] || "N/A" }}
          </p>
        </div>
        <div class="space-y-1">
          <p class="text-xs text-gray-500 font-medium">City</p>
          <p class="text-sm font-semibold">
            {{ store.city?.["name_" + $i18n.locale] || "N/A" }}
          </p>
        </div>
        <div class="space-y-1">
          <p class="text-xs text-gray-500 font-medium">District</p>
          <p class="text-sm font-semibold">
            {{ store.district?.["name_" + $i18n.locale] || "N/A" }}
          </p>
        </div>
        <div class="space-y-1 col-span-2">
          <p class="text-xs text-gray-500 font-medium">Address</p>
          <p class="text-sm font-semibold">{{ store.address || "N/A" }}</p>
        </div>
        <div class="space-y-1">
          <p class="text-xs text-gray-500 font-medium">Products</p>
          <router-link
            :to="{
              path: '/inventory-setup',
              query: {
                location: store.id,
              },
            }"
            class="inline-flex gap-2 items-center text-sm font-semibold text-blue-600"
          >
            <i class="fa-solid fa-eye fa-sm align-middle"></i>
            <span>View all</span>
          </router-link>
        </div>
        <div class="space-y-1">
          <p class="text-xs text-gray-500 font-medium">Orders</p>
          <router-link
            :to="{
              path: '/inventory-actions',
              query: {
                location: store.id,
              },
            }"
            class="inline-flex gap-2 items-center text-sm font-semibold text-blue-600"
          >
            <i class="fa-solid fa-eye fa-sm align-middle"></i>
            <span>View all</span>
          </router-link>
        </div>
        <div class="space-y-1">
          <p class="text-xs text-gray-500 font-medium">Sales</p>
          <router-link
            :to="{
              path: '/analytics/invoices',
              query: {
                location: store.id,
              },
            }"
            class="inline-flex gap-2 items-center text-sm font-semibold text-blue-600"
          >
            <i class="fa-solid fa-eye fa-sm align-middle"></i>
            <span>View all</span>
          </router-link>
        </div>
        <div class="space-y-1">
          <p class="text-xs text-gray-500 font-medium">Devices</p>
          <router-link
            :to="{
              path: '/settings/devices',
              query: {
                location: store.id,
              },
            }"
            class="inline-flex gap-2 items-center text-sm font-semibold text-blue-600"
          >
            <i class="fa-solid fa-eye fa-sm align-middle"></i>
            <span>View all</span>
          </router-link>
        </div>
        <div class="space-y-1">
          <p class="text-xs text-gray-500 font-medium">Status</p>
          <p class="status capitalize" :class="item_status[store.is_active]">
            {{ store.is_active === 1 ? "Active" : "Not active" }}
          </p>
        </div>
        <div class="space-y-1">
          <p class="text-xs text-gray-500 font-medium">Created date</p>
          <p class="text-sm font-semibold">
            {{ store.created_at | moment("lll") }}
          </p>
        </div>
      </div>
      <div class="mx-auto table" v-else>
        <spinner />
      </div>
    </card>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
import { mapActions } from "vuex";
export default {
  components: { Card },
  name: "StoreDetails",
  data() {
    return {
      load: false,
      store: {},
      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-500/10 text-red-500",
      },
    };
  },
  created() {
    this.getStore();
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    async getStore() {
      try {
        const id = this.store_id;
        if (!id) return this.$router.push("/settings/profile");
        const { result } = await this.getRoute("stores/" + id);
        const { store } = result;
        this.store = store;
      } catch ({ result, type }) {
        this.createAlert(result, type);
      } finally {
        this.load = true;
      }
    },
  },
};
</script>
