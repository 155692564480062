<template>
  <form class="w-full space-y-6">
    <figure class="w-full bg-white rounded-xl">
      <header class="w-full p-4 border-b border-gray-100">
        <h1 class="text-base font-semibold rtl:font-bold text-primary">
          Personal account settings
        </h1>
        <p class="mt-1 font-medium rtl:font-semibold text-sm text-gray-600">
          You can update you information [name, e-mail address, phone number]
          from this page.
        </p>
      </header>
      <blockquote class="w-full p-6 space-y-6" v-if="load">
        <div class="w-full flex gap-3 bg-yellow-400/30 rounded p-4">
          <div>
            <div
              class="w-8 h-8 rounded-full flex items-center justify-center bg-yellow-400/50"
            >
              <i class="fa-solid fa-bolt-lightning text-yellow-600 fa-sm"></i>
            </div>
          </div>
          <div class="flex-1 self-center">
            <p
              class="mt-1 font-medium rtl:font-semibold text-sm text-yellow-600"
            >
              You will be logged out of that account if your contact details
              such as email or mobile number have been changed
            </p>
          </div>
        </div>
        <p
          class="text-center font-semibold"
          v-if="error || success"
          :class="error ? 'text-red-500' : 'text-primary'"
        >
          {{ error || success }}
        </p>
        <div class="w-full space-y-3">
          <label
            for="name"
            class="font-medium rtl:font-semibold text-gray-600 table text-sm"
          >
            Full name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            class="w-full py-3 px-4 rounded-md bg-gray-100 placeholder:text-gray-500"
            v-model="add_data.name"
          />
          <p class="error" v-if="add_errors.name">
            {{ (add_errors.name || []).join(" ") }}
          </p>
        </div>
        <div class="w-full space-y-3">
          <label
            for="email"
            class="font-medium rtl:font-semibold text-gray-600 table text-sm"
          >
            E-mail address
          </label>
          <input
            type="text"
            name="email"
            id="email"
            class="w-full py-3 px-4 rounded-md bg-gray-100 placeholder:text-gray-500"
            v-model="add_data.email"
          />
          <p class="error" v-if="add_errors.email">
            {{ (add_errors.email || []).join(" ") }}
          </p>
        </div>
        <div class="w-full space-y-3">
          <label
            for="tel"
            class="font-medium rtl:font-semibold text-gray-600 table text-sm"
          >
            Phone number
          </label>
          <vue-tel-input
            id="mobile"
            ref="registerMobile"
            v-model="add_data.mobile"
            @open="handleOpen('registerMobile')"
          ></vue-tel-input>
          <p class="error" v-if="add_errors.mobile">
            {{ (add_errors.mobile || []).join(" ") }}
          </p>
        </div>
        <div class="flex w-full flex-col sm:flex-row gap-4">
          <div class="w-full space-y-3">
            <label
              for="confirmation_password"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              Current password
            </label>
            <input
              type="password"
              name="confirmation_password"
              id="confirmation_password"
              placeholder="••••••••"
              class="w-full py-3 px-4 rounded-md bg-gray-100 placeholder:text-gray-500"
              v-model="add_data.c_password"
            />
            <p class="error" v-if="add_errors.c_password">
              {{ (add_errors.c_password || []).join(" ") }}
            </p>
          </div>
          <div class="w-full space-y-3">
            <label
              for="password"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              New password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="••••••••"
              class="w-full py-3 px-4 rounded-md bg-gray-100 placeholder:text-gray-500"
              v-model="add_data.password"
            />
            <p class="error" v-if="add_errors.password">
              {{ (add_errors.password || []).join(" ") }}
            </p>
          </div>
        </div>
      </blockquote>
      <div class="p-6 space-y-5 max-w-screen-xl" v-else>
        <div
          class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
          v-for="i in 3"
          :key="i"
        >
          <div class="w-full space-y-3">
            <div class="w-1/5 h-4 rounded bg-gray-200 animate-pulse"></div>
            <div class="w-full h-10 rounded bg-gray-200 animate-pulse"></div>
          </div>
        </div>
      </div>
    </figure>
    <button-with-loader
      btnClass="w-auto py-3 px-4 rounded bg-primary
          text-white text-sm"
      :disabled="disabled"
      @click="updateProfile"
      >Save changes</button-with-loader
    >
  </form>
</template>
<script>
import { mapActions } from "vuex";
import ButtonWithLoader from "../basics/ButtonWithLoader.vue";
export default {
  components: { ButtonWithLoader },
  name: "PersonalAccount",
  data() {
    return {
      add_data: {},
      load: false,
      add_errors: {},
      error: "",
      success: "",
      disabled: false,
      items: [],
      assign_data: {
        store_id: parseInt(localStorage.getItem("store")) || null,
      },
    };
  },
  async created() {
    await Promise.all([this.getProfile(), this.getStores()]).finally(() => {
      this.load = true;
    });
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  watch: {
    "assign_data.store_id"(val) {
      if (!val) {
        this.createAlert("Invalid store", "error");
        return;
      }
      const store = this.items.find((e) => e.id === val);
      localStorage.setItem("store", val);
      localStorage.setItem("store_module", store?.module?.module_type);
      localStorage.setItem("module", JSON.stringify(store?.module));
      this.createAlert("Location updated successfully");
      window.location.reload();
    },
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    async getProfile() {
      try {
        const { result } = await this.getRoute("profile");
        this.add_data = result.profile;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
        this.$router.push("/");
      }
    },
    async getStores() {
      try {
        const { result } = await this.getRoute({
          name: "stores",
          options: {
            is_active: 1,
          },
        });
        this.items = result.stores;
      } catch (error) {}
    },
    async updateProfile() {
      try {
        this.disabled = true;
        this.add_errors = {};
        this.add_error = {};
        let updateData = {
          ...this.add_data,
          mobile: this.add_data.mobile.replace(/[+|\s|()|-]/g, ""),
        };
        const { data } = await this.axios.post("profile/update", updateData);
        /**
         * ! check if contact details such as email or mobile number have been changed to log out
         */
        const isDataChanged = Object.keys(this.user)
          .filter((e) => /(email|mobile)/g.test(e))
          .some((e) => this.user[e] !== data.result.profile[e]);
        if (isDataChanged) {
          this.logout();
          return;
        }
        /**
         * ? Store profile data if contact details have not changed
         */
        const access_token = data.result.access_token;

        this.$cookies.set("token", access_token);
        this.$store.commit("SET_TOKEN", access_token);

        this.add_data = data.result.profile;
        this.createAlert(data?.message);
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.disabled = false;
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
  },
};
</script>
