<template>
  <AlertsContainerWrapper class="p-6 space-y-6">
    <section class="w-full space-y-6">
      <div>
        <div class="form-group space-y-3 max-w-sm">
          <!-- <label for="inventory_products"> Inventory products </label> -->
          <!-- <t-rich-select
            placeholder="Search in inventory products"
            :text-attribute="'name_' + $i18n.locale"
            value-attribute="id"
            :clearable="true"
            :closeOnSelect="true"
            :fetch-options="getInventoryProducts"
            v-model="inventoryId"
            @change="getItems"
          /> -->
        </div>
      </div>
      <Table
        :title="'Inventory processing'"
        :records="`${items.length || 0} records`"
        :has_download_pdf="!!inventoryId"
        :has_search="false"
      >
        <!-- :exportPathname="
          'products/processing_tracks/' + inventoryId + '/export_pdf'
        " -->
        <template #actions>
          <div>
            <button
              class="inline-flex gap-2 items-center py-2 px-3 rounded bg-transparent text-sm text-gray-600"
              @click.prevent="process_active = !process_active"
            >
              <i class="fa-solid fa-plus fa-sm"></i>
              <span>New processing</span>
            </button>
          </div>
        </template>
        <template #head>
          <th>Product</th>
          <th>User</th>
          <th>Processed value</th>
          <th>Wastages value</th>
          <th>Created date</th>
          <th>Actions</th>
        </template>

        <template #tbody v-if="!load">
          <TableLoad :rows="6" v-for="i in 4" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length">
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <router-link
                :to="
                  hasPermission('edit inventory')
                    ? {
                        name: 'UpdateInventoryProduct',
                        params: {
                          slug: item.product?.id,
                        },
                      }
                    : '#'
                "
                class="flex items-center gap-3"
              >
                <div>
                  <div class="w-10 h-10">
                    <img
                      :src="
                        item.product?.image ||
                        require('@/assets/images/placeholder.png')
                      "
                      @error="
                        $event.target.src = require('@/assets/images/placeholder.png')
                      "
                      alt="item.product? image"
                      class="w-full h-full rounded-md border object-cover border-gray-100"
                    />
                  </div>
                </div>
                <div class="flex-1 max-w-fit">
                  <p class="line-clamp-2 whitespace-normal text-sky-600">
                    {{ item.product?.["name_" + $i18n.locale] }}
                  </p>
                  <p v-if="item.product?.sku">{{ item.product?.sku }}</p>
                </div>
              </router-link>
            </td>
            <td>
              {{ item.user?.name || "N/A" }}
            </td>
            <td>
              {{ item.processed_value || item.products_value || 0 }}
              {{ item.product?.unit_type === "weight" ? "KG" : "Piece" }}
            </td>

            <td>
              {{ item.wastages_value || 0 }}
              {{ item.product?.unit_type === "weight" ? "KG" : "Piece" }}
            </td>
            <td v-html="createTime(item.created_at)"></td>
            <td>
              <button
                type="button"
                class="w-8 h-8 rounded bg-white text-gray-600 border border-gray-200 flex justify-center items-center"
                @click.prevent="ShowProducts(item)"
                :disabled="disables['view-details-' + item.id]"
                v-tippy
                content="View details"
              >
                <i class="fa-solid fa-eye fa-sm"></i>
              </button>
            </td>
          </tr>
        </template>

        <template #tbody v-else>
          <tr>
            <td colspan="100%" class="!text-center">
              There are no data to display
            </td>
          </tr>
        </template>
      </Table>
      <modal
        title="View tracking"
        v-if="show_active"
        @close="
          () => {
            products = [];
            show_active = false;
          }
        "
        size="!max-w-screen-lg"
      >
        <template>
          <div class="space-y-4 p-6">
            <p class="text-sm text-gray-500">Tracking summary</p>
            <div class="grid grid-wrapper gap-4">
              <div
                class="bg-gray-100 rounded-lg p-6 flex flex-col items-center justify-center text-center gap-1"
              >
                <p class="text-base text-gray-800 font-semibold">
                  <span>{{
                    GenerateUnit(process_product.details?.processed_value || 0)
                  }}</span>
                </p>
                <p class="text-sm text-gray-500">Processed value</p>
              </div>
              <div
                class="bg-gray-100 rounded-lg p-6 flex flex-col items-center justify-center text-center gap-1"
              >
                <p class="text-base text-gray-800 font-semibold">
                  <span>{{
                    GenerateUnit(process_product.details?.stock || 0)
                  }}</span>
                </p>
                <p class="text-sm text-gray-500">Stock</p>
              </div>
              <div
                class="bg-gray-100 rounded-lg p-6 flex flex-col items-center justify-center text-center gap-1"
              >
                <p class="text-base text-gray-800 font-semibold">
                  <span>{{
                    GenerateUnit(process_product.details?.wastages_value || 0)
                  }}</span>
                </p>
                <p class="text-sm text-gray-500">Wastages</p>
              </div>
              <div
                class="bg-gray-100 rounded-lg p-6 flex flex-col items-center justify-center text-center gap-1"
              >
                <p class="text-base text-gray-800 font-semibold">
                  <span>{{
                    GenerateUnit(process_product.details?.sold_value || 0)
                  }}</span>
                </p>
                <p class="text-sm text-gray-500">Sold</p>
              </div>
              <div
                class="bg-gray-100 rounded-lg p-6 flex flex-col items-center justify-center text-center gap-1"
              >
                <p class="text-base text-gray-800 font-semibold">
                  {{ process_product.details?.sold_price || 0 }} SAR
                </p>
                <p class="text-sm text-gray-500">Sold price</p>
              </div>
            </div>
            <p class="text-sm text-gray-500">Products</p>
            <ul class="flex items-center justify-start gap-2 flex-wrap">
              <li
                v-for="product in process_product.details?.products"
                :key="product.id"
              >
                <div
                  class="inline-flex gap-2 py-2 px-3 rounded bg-gray-100 text-sm font-semibold items-center cursor-pointer transition peer-checked:bg-primary peer-checked:text-white"
                >
                  <img
                    :src="
                      product.image ||
                      require('@/assets/images/placeholder.png')
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    class="w-6 h-6 rounded-full object-cover"
                    alt="product image"
                  />
                  <div>
                    <p class="line-clamp-1">
                      {{ product["name_" + $i18n.locale] }}
                    </p>
                    <p class="text-xs text-gray-500">
                      {{ GenerateUnit(product.value) }}
                    </p>
                  </div>
                </div>
              </li>
              <li v-if="!process_product.details?.products?.length">
                <p class="text-sm text-gray-500 italic">no products added</p>
              </li>
            </ul>
            <p class="text-sm text-gray-500">Wastages</p>
            <ul class="flex items-center justify-start gap-2 flex-wrap">
              <li
                v-for="wastage in process_product.details?.wastages"
                :key="wastage.id"
              >
                <div
                  class="inline-flex gap-2 py-2 px-3 rounded bg-gray-100 text-sm font-semibold items-center cursor-pointer transition peer-checked:bg-primary peer-checked:text-white"
                >
                  <div>
                    <p class="line-clamp-1">
                      {{ wastage["name_" + $i18n.locale] }}
                    </p>
                    <p class="text-xs text-gray-500">
                      {{ GenerateUnit(wastage.value) }}
                    </p>
                  </div>
                </div>
              </li>
              <li v-if="!process_product.details?.wastages?.length">
                <p class="text-sm text-gray-500 italic">no wastages added</p>
              </li>
            </ul>
            <!-- <Table
              :title="process_product['name_' + $i18n.locale]"
              :records="`${process_product.tracking?.length || 0} records`"
              has_download_pdf
              :has_search="false"
              :exportPathname="
                'processed_products/' +
                process_product.processed_product?.id +
                '/export_pdf'
              "
            >
              <template #head>
                <th>Time</th>
                <th>Processed</th>
                <th>Stock</th>
                <th>Wastages</th>
                <th>Sold</th>
                <th>Sold price</th>
              </template>
              <template #tbody>
                <tr
                  v-for="(track, index) in process_product.tracking"
                  :key="index"
                >
                  <td v-html="createTime(track.time)"></td>
                  <td>
                    {{
                      process_product.unit_type === "weight"
                        ? GenerateUnit(track.processed_value || 0)
                        : track.processed_value + " Piece"
                    }}
                  </td>
                  <td>
                    {{
                      process_product.unit_type === "weight"
                        ? GenerateUnit(track.stock || 0)
                        : track.stock + " Piece"
                    }}
                  </td>
                  <td>
                    {{
                      process_product.unit_type === "weight"
                        ? GenerateUnit(track.wastages_value || 0)
                        : track.wastages_value + " Piece"
                    }}
                  </td>
                  <td>
                    {{
                      process_product.unit_type === "weight"
                        ? GenerateUnit(track.sold_value || 0)
                        : track.sold_value + " Piece"
                    }}
                  </td>
                  <td>{{ track.sold_price }} SAR</td>
                </tr>
                <tr v-if="!process_product.tracking?.length">
                  <td colspan="100%" class="!text-center">
                    There are no data to display
                  </td>
                </tr>
              </template>
            </Table> -->
          </div>
        </template>
      </modal>
      <Modal
        title="New processing"
        v-if="process_active"
        @close="process_active = $event"
      >
        <template>
          <form class="p-6 space-y-4">
            <ul class="space-y-4">
              <li>
                <div class="form-group space-y-3">
                  <label>Inventory product </label>
                  <t-rich-select
                    placeholder="Search in inventory products"
                    :text-attribute="'name_' + $i18n.locale"
                    value-attribute="id"
                    :clearable="true"
                    :closeOnSelect="true"
                    :fetch-options="getInventoryProducts"
                    v-model="process_data.product_id"
                    @change="checkSelectedInventoryProduct"
                  />
                </div>
              </li>
              <li>
                <p class="text-sm text-gray-600 bg-gray-100 py-2 px-3 rounded">
                  Remaining stock
                  <span class="text-yellow-600">{{ remainingStock() }}</span>
                  based on total product stock
                  <span class="text-teal-600">{{
                    inventoryProduct?.stock || 0
                  }}</span>
                </p>
              </li>
              <slide-transition>
                <li v-if="process_data.product_id">
                  <div class="form-group space-y-3">
                    <label for="product_id"> Processing products </label>
                    <t-rich-select
                      :fetch-options="getOtherProducts"
                      placeholder="Select"
                      :text-attribute="'name_' + $i18n.locale"
                      value-attribute="id"
                      :clearable="true"
                      :closeOnSelect="false"
                      @change="handleOtherProductsChange"
                      v-model="product_id"
                    />
                    <!-- <p class="text-sm text-gray-600">
                      Remaining stock is ({{ remainingStock() }})
                    </p> -->
                  </div>
                </li>
              </slide-transition>

              <li v-for="item in process_data.to_products || []" :key="item.id">
                <div class="flex items-center gap-4">
                  <button
                    class="w-8 h-8 rounded-full bg-red-200 text-red-500 flex items-center justify-center"
                    @click="DeleteProduct(item.id)"
                    type="button"
                  >
                    <i class="fa-solid fa-trash fa-sm"></i>
                  </button>
                  <img
                    :src="
                      item.image || require('@/assets/images/placeholder.png')
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    class="w-10 h-10 rounded-full object-cover shrink-0"
                    alt="product image"
                  />
                  <div class="flex-1 space-y-1">
                    <p class="text-sm font-medium line-clamp-1">
                      {{ item["name_" + $i18n.locale] }}
                    </p>
                    <slide-transition>
                      <p class="text-sm font-medium" v-if="item.value">
                        {{
                          item.unit_type === "weight"
                            ? GenerateUnit(item.value || 0)
                            : item.value + " Piece"
                        }}
                      </p>
                    </slide-transition>
                  </div>
                  <div class="form-group">
                    <input
                      type="number"
                      min="1"
                      placeholder="Value"
                      v-model="item.value"
                      autocomplete="off"
                      step="any"
                    />
                  </div>
                </div>
              </li>
              <li>
                <details class="w-full border border-gray-200 rounded p-4">
                  <summary class="text-sm font-semibold text-gray-600">
                    Wastages
                  </summary>
                  <ul class="list-none space-y-4 mt-4">
                    <li
                      class="space-y-4"
                      v-for="(wastage, index) in process_data.wastages"
                      :key="index"
                    >
                      <div class="form-group space-y-3">
                        <label for="wastage_id"> Wastage </label>
                        <t-rich-select
                          :options="filterWastages"
                          placeholder="Select"
                          :text-attribute="'name_' + $i18n.locale"
                          value-attribute="id"
                          :clearable="true"
                          :closeOnSelect="true"
                          v-model="process_data.wastages[index].id"
                        />
                        <p class="error">
                          {{
                            (process_errors[`wastages.${index}.id`] || []).join(
                              " "
                            )
                          }}
                        </p>
                      </div>
                      <div class="form-group space-y-3">
                        <label for="weight"> Weight (KG) </label>
                        <div class="flex items-stretch gap-3">
                          <input
                            type="number"
                            name="weight"
                            id="weight"
                            v-model="process_data.wastages[index].value"
                            placeholder="1"
                            min="1"
                            class="flex-1"
                          />
                          <button
                            class="w-10 rounded bg-red-500 text-white shrink-0"
                            @click.prevent="removeCurrentItem(index)"
                          >
                            <i class="fa-solid fa-minus fa-sm"></i>
                          </button>
                        </div>
                        <p class="error">
                          {{
                            (
                              process_errors[`wastages.${index}.value`] || []
                            ).join(" ")
                          }}
                        </p>
                      </div>
                    </li>
                    <li
                      v-if="
                        (
                          process_data.wastages[
                            process_data.wastages.length - 1
                          ] || {}
                        ).id || filterWastages.length
                      "
                      class="space-y-1"
                    >
                      <p class="text-sm text-gray-500">
                        No wastages selected to current processing, select
                        wastage then add value.
                      </p>
                      <button
                        class="bg-teal-600 text-white text-xs rounded font-semibold py-2 px-4"
                        @click.prevent="addNewItem"
                      >
                        Add new
                      </button>
                    </li>
                  </ul>
                </details>
              </li>
            </ul>
            <p
              class="error"
              v-html="Object.values(process_errors).join('<br />')"
            ></p>
            <button-with-loader
              btnClass="w-auto py-2 px-4 rounded bg-primary
          text-white text-xs [margin-inline-start:auto]"
              :disabled="process_disabled"
              @click="updateProductProcess"
              v-if="process_data?.to_products?.length"
              >Save changes</button-with-loader
            >
          </form>
        </template>
      </Modal>
    </section>
  </AlertsContainerWrapper>
</template>
<script>
import Modal from "@/components/basics/Modal.vue";
import ContainerWrapper from "@/components/Inventory/ContainerWrapper.vue";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import AlertsContainerWrapper from "@/components/Inventory/AlertsContainerWrapper.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "ProcessingTracks",
  data() {
    return {
      load: true,
      items: [],

      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-500/10 text-red-500",
      },
      show_active: false,
      products: [],
      status: ["all", "active", "not active"],
      pagination: {
        is_active: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      fields: [],
      inventory_product: {},
      disables: {},
      inventory_products: [],
      inventoryId: null,
      process_data: {
        wastages: [],
        to_products: [],
      },
      process_errors: {},
      process_products: [],
      process_active: false,
      process_disabled: false,
      wastages: [],
      product_id: null,
      process_product: {},
    };
  },
  async created() {
    await Promise.all([this.getItems(), this.getWastages()]);
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
    process_active(val) {
      if (!val) {
        this.process_data = {
          wastages: [],
          to_products: [],
        };
        this.process_errors = {};
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
    filterWastages() {
      const selectedWastages = this.process_data.wastages;
      const mapWastages = selectedWastages.map((e) => e.id);
      const result = this.wastages.filter((e) => !mapWastages.includes(e.id));

      return result;
    },
    inventoryProduct() {
      return this.inventory_products.find(
        (e) => e.id == this.process_data?.product_id
      );
    },
  },

  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    remainingStock() {
      let total = 0;
      const { to_products: products = [] } = this.process_data;
      const stock = this.inventoryProduct?.stock || 0;

      products.forEach((product) => (total += Number(product.value || 0)));
      if (stock === 0) return 0;
      return stock - total <= 0 ? 0 : stock - total;
    },
    async getProducts(q) {
      try {
        const { result } = await this.getRoute({
          name: "products",
          options: {
            search_key: q,
            page: 1,
          },
        });
        const products = result.products.data;
        if (!products.length) return { results: [] };
        return { results: products };
      } catch (error) {
        console.log(error);
      }
    },
    async getInventoryProducts(q) {
      try {
        const { result } = await this.getRoute({
          name: "products",
          options: {
            search_key: q,
            page: 1,
            store_id: this.store_id,
            unit_type: "weight",
            has_stock: 1,
          },
        });
        const products = result.products.data;
        if (!products.length) return { results: [] };
        const concatProducts = products.map((e) => {
          const concatArName =
            e.name_ar +
            (e.tracking_stock ? "" : "( قيمة المخزون " + e.stock + " )");
          const concatEnName =
            e.name_ar + (e.tracking_stock ? "" : "( " + e.stock + " stock )");
          return {
            ...e,
            concat_name_ar: concatArName,
            concat_name_en: concatEnName,
          };
        });
        this.inventory_products = concatProducts;
        return { results: concatProducts };
      } catch (error) {
        console.log(error);
      }
    },
    checkSelectedInventoryProduct(id) {
      let result = {};
      const data = this.process_data;
      const products = this.inventory_products || [];
      const product = products.find((e) => e.id == id);
      if (product?.stock === 0) {
        this.process_data.product_id = null;
        return this.createAlert(
          "Stock value is not enough to processing.",
          "info"
        );
      }
    },
    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;
        const user = this.$store.state.user;

        const { result } = await this.getRoute({
          name: "processed_products",
          options: {
            ...others,
            search_key: this.search || this.$route.query.search,
            vendor_id: user.user_type === "vendor" ? user?.id : null,
            store_id: this.store_id,
          },
        });

        const { data, pagination } = result.processed_products;
        this.fields = result.export_fields;

        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch ({ result }) {
        // console.log(error);
        this.createAlert(result?.message, type);
      } finally {
        this.load = true;
        this.changeIsCalled(false);
      }
    },
    async getWastages() {
      try {
        const { result } = await this.getRoute("wastages");
        this.wastages = result.wastages;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getOtherProducts(q) {
      try {
        const productID = this.process_data.product_id;
        if (!productID) return;
        const { result } = await this.getRoute({
          name: "products",
          options: {
            search_key: q,
            page: 1,
            not_product_id: productID,
            store_id: this.store_id,
            unit_type: "weight",
          },
        });
        const products = result.products.data;
        const addProducts = this.process_data?.to_products?.map((e) => e.id);
        const filterProducts = products.filter(
          (e) => e.id !== productID && !addProducts.includes(e.id)
        );
        this.process_products = filterProducts;
        return { results: filterProducts };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    addNewItem() {
      if (!this.filterWastages.length) {
        this.createAlert("Wastages are not enough to add new one.", "error");
        return;
      }
      this.process_data.wastages.push({
        id: null,
        value: null,
      });
    },
    handleOtherProductsChange(id) {
      const item = this.process_products?.find((ev) => ev.id == id);
      if (item) {
        this.product_id = null;
        const processProducts = this.process_data.to_products;
        const product = processProducts?.find((ev) => ev.id == id);
        const obj = {
          id,
          name_ar: item.name_ar,
          name_en: item.name_en,
          value: null,
          image: item.image,
          unit_type: item.unit_type,
        };
        if (!product) {
          this.$set(this.process_data, "to_products", [
            ...processProducts,
            obj,
          ]);
          return this.createAlert(
            "Product (" + item["name_" + this.$i18n.locale] + ") added"
          );
        }
        this.createAlert(
          "Product (" + item["name_" + this.$i18n.locale] + ") already added"
        );
      }
    },
    DeleteProduct(id) {
      const data = this.process_data.to_products;
      const filterData = data.filter((e) => e.id !== id);
      this.$set(this.process_data, "to_products", filterData);
    },
    async updateProductProcess() {
      this.process_disabled = true;
      try {
        const body = this.process_data;
        const wastages = body?.wastages?.filter((ev) =>
          Object.values(ev).every((e) => e)
        );
        const products = body?.to_products?.filter((e) => e.value);
        const { data } = await this.axios.post("processed_products/add", {
          ...body,
          to_products: products?.length ? products : undefined,
          wastages: wastages?.length ? wastages : undefined,
        });
        this.createAlert(data.message);
        this.inventoryId = body.product_id;
        this.getItems();
        this.process_active = false;
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.process_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.process_disabled = false;
      }
    },
    async ShowProducts(item) {
      try {
        const { id, product } = item;
        const { name_ar, name_en, unit_type } = product;
        this.$set(this.disables, "view-details-" + id, true);
        const { result } = await this.getRoute("processed_products/" + id);
        this.process_product = { ...result, name_ar, name_en, unit_type };
        this.show_active = true;
      } catch ({ result }) {
        this.createAlert(result?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    removeCurrentItem(index) {
      this.process_data.wastages = this.process_data.wastages.filter(
        (e, i) => i !== index
      );
    },
  },
  components: {
    Modal,
    ContainerWrapper,
    ButtonWithLoader,
    AlertsContainerWrapper,
  },
};
</script>
