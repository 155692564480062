<template>
  <div class="p-6 pt-2">
    <div class="mx-auto table" v-if="load">
      <spinner />
    </div>
    <div class="flex items-start gap-4 flex-col xl:flex-row" v-else>
      <div class="flex-1 space-y-4 shrink-0 w-full xl:max-w-sm xl:!mt-4">
        <card title="Transaction details">
          <div class="space-y-1">
            <p class="text-xl font-bold">
              {{ generateCurrency(details.balance || 0) }}
            </p>
            <p class="text-sm text-gray-500">Closing Balance</p>
          </div>
          <div class="pt-3 border-t border-t-gray-200 mt-3">
            <router-link
              :to="'/accounting/chart/' + id + '/transactions'"
              class="text-blue-500 hover:underline flex items-center gap-2 text-sm"
            >
              <i class="fa-solid fa-dollar shrink-0"></i>
              <span>View transactions</span>
            </router-link>
          </div>
        </card>
        <card title="Account details">
          <div class="space-y-3">
            <div class="space-y-1">
              <p class="text-sm text-gray-500">Name</p>
              <p>{{ details.name || "-" }}</p>
            </div>
            <div class="space-y-1">
              <p class="text-sm text-gray-500">Description</p>
              <p>{{ details.desc || "-" }}</p>
            </div>
          </div>
        </card>
        <!-- <card title="Accounts">
          
          <div class="space-y-3">
            <ul class="tree space-y-3">
              <tree-button
                v-for="index in 3"
                :key="index"
                :id="`${id}${index}`"
              >
                <template #trigger> {{ id }}{{ index }} Sub account </template>

                <tree-button
                  v-for="idx in 3"
                  :key="idx"
                  :id="`${id}${index}${idx}`"
                >
                  <template #trigger>
                    {{ id }}{{ index }}{{ idx }} Sub sub account
                  </template>
                  <tree-button
                    v-for="idxx in 3"
                    :key="idxx"
                    :id="`${id}${index}${idx}${idxx}`"
                  >
                    <template #trigger>
                      {{ id }}{{ index }}{{ idx }}{{ idxx }} Sub sub sub account
                    </template>
                  </tree-button>
                </tree-button>
              </tree-button>
            </ul>
          </div>
        </card> -->
      </div>
      <div class="flex-1 w-full">
        <Table
          title="Latest transactions"
          :records="`${transactions.length || 0} records`"
          :has_search="false"
        >
          <template #head>
            <tr>
              <th>Reference ID</th>
              <th>Account name</th>
              <th>Type</th>
              <th>Debit</th>
              <th>Credit</th>
              <th>Balance</th>
              <th>Date</th>
            </tr>
          </template>
          <template #tbody v-if="!transactions.length">
            <tr>
              <td colspan="100%" class="!text-center">
                There are no data display
              </td>
            </tr>
          </template>
          <template #tbody v-else>
            <tr v-for="transaction in transactions" :key="transaction.id">
              <td>{{ transaction.id }}</td>
              <td>
                <router-link
                  :to="
                    '/accounting/chart/' + transaction.account?.id + '/details'
                  "
                  class="text-blue-500 hover:underline"
                  >{{ transaction.account?.code }} -
                  {{ transaction.account?.name }}</router-link
                >
              </td>
              <td>{{ transaction.account?.type }}</td>
              <td>{{ generateCurrency(transaction.debit || 0) }}</td>
              <td>{{ generateCurrency(transaction.credit || 0) }}</td>
              <td>{{ generateCurrency(transaction.balance) }}</td>
              <td
                v-html="
                  createTime(transaction.updated_at || transaction.created_at)
                "
              ></td>
            </tr>
          </template>
        </Table>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
import TreeButton from "@/components/TreeButton.vue";
import AddAccount from "@/components/accounting/AddAccount.vue";
import { mapActions } from "vuex";

export default {
  components: { Card, TreeButton, AddAccount },
  name: "AccountDetails",
  data() {
    return {
      load: true,
      details: {},
      transactions: [],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    this.load = true;
    Promise.all([this.getDetails(), this.getTransactions()]).finally(() => {
      this.load = false;
    });
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    async getDetails() {
      try {
        const { result } = await this.getRoute({
          name: "accounts/" + this.id,
        });

        this.details = result.account || {};
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getTransactions() {
      try {
        const { result } = await this.getRoute({
          name: "accounts/" + this.id + "/transactions",
          options: { page: 1 },
        });

        this.transactions = result.transactions.data || [];
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
  },
};
</script>
<style lang="scss">
.tree {
  @apply ps-4 relative;
  &::before {
    content: "";
    inset-inline-start: 0;
    @apply absolute top-0 h-full border-s border-s-gray-300;
  }
  li button {
    @apply relative;
    &::before {
      content: "";
      inset-inline-start: -16px;
      @apply absolute top-1/2 -translate-y-1/2 w-2  border-b border-gray-300;
    }
  }
}
</style>
