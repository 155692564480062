<template>
  <div
    class="z-20 fixed xl:sticky opacity-0 top-0 pointer-events-none invisible xl:opacity-100 xl:pointer-events-auto xl:visible left-full xl:left-0 bg-gray-800/20 xl:bg-transparent w-full xl:w-auto h-screen"
    @click.self="openCloseSideBar"
    ref="sideBar"
  >
    <aside
      class="bg-gray-800 overflow-y-auto flex flex-col top-0 w-screen max-w-[18rem] h-full max-h-screen transition-all"
    >
      <div class="w-full border-b border-gray-700 p-3 bg-white">
        <div>
          <img
            src="@/assets/images/logo.jpg"
            alt="logo image"
            class="w-full h-auto max-w-[8rem]"
          />
        </div>
      </div>
      <div
        class="w-full border-b border-gray-700 p-3"
        v-if="['vendor', 'vendor_user'].includes(userType)"
      >
        <form class="space-y-4">
          <div v-if="!load">
            <div
              class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
            >
              <div class="w-full space-y-3">
                <div
                  class="w-full h-10 rounded bg-gray-500 animate-pulse"
                ></div>
              </div>
            </div>
          </div>

          <select
            class="form-select w-full rounded text-sm"
            v-else-if="items.length"
            @change.prevent="changeStore"
            v-model="store_id"
          >
            <option value="" selected>Select location</option>
            <option v-for="store in items" :key="store.id" :value="store.id">
              {{ store["name_" + $i18n.locale] }}
            </option>
          </select>
          <p
            v-else
            class="text-sm text-gray-300 italic font-medium rtl:font-semibold"
          >
            There are no stores to be displayed and used. You can also go to the
            Stores page to add a new store, or if you have already done so,
            change the status to Active to be used and displayed here.
            <router-link to="/stores" class="text-primary">add now</router-link>
          </p>
        </form>
      </div>
      <ul
        class="w-full flex-1 space-y-2 overflow-y-auto py-4 px-3 links-container"
      >
        <li v-for="(item, index) in pages" :key="index">
          <div
            v-if="item.children && hasPermission(item.access)"
            class="space-y-2"
          >
            <a
              href="javascript:void(0)"
              class="flex items-center justify-center gap-4 py-2 px-3 rounded"
              @click.prevent="tree = tree === index ? null : index"
            >
              <div v-if="item.icon">
                <i :class="item.icon + ' w-4 fa-md'"></i>
              </div>
              <div class="flex-1">
                <p class="font-medium line-clamp-1 text-base text-gray-700">
                  {{ $t(item.name) }}
                </p>
              </div>
              <span
                class="shrink-0"
                :class="tree === index ? 'rotate-90 rtl:-rotate-90' : null"
              >
                <i
                  class="fa-solid fa-angle-right fa-sm rtl:hidden transition-transform"
                ></i>
                <i
                  class="fa-solid fa-angle-left fa-sm hidden rtl:inline transition-transform"
                ></i>
              </span>
            </a>
            <slide-transition>
              <ul
                class="w-full space-y-2 flex-1 links-container"
                v-if="tree === index"
              >
                <li
                  v-for="(item, index) in item.children"
                  :key="index"
                  class="animate-item-move"
                  v-show="hasPermission(item.access)"
                >
                  <router-link
                    :to="item.path"
                    class="flex gap-4 py-2 px-3 rounded"
                    exact-active-class="active-tab-pills !bg-secondary"
                  >
                    <div class="flex-1 self-center ps-8">
                      <p
                        class="font-medium rtl:font-semibold line-clamp-1 text-base"
                      >
                        {{ $t(item.name) }}
                      </p>
                    </div>
                    <div
                      v-if="
                        [
                          'Stores',
                          'Orders',
                          'Wastages',
                          'POS Devices',
                        ].includes(item.name) && hasTrialDays
                      "
                    >
                      <span
                        class="py-1 px-2 rounded-full bg-primary text-white text-[10px] inline-block font-medium ring-2 ring-gray-800"
                        v-tippy
                        content="Enabled in production mode"
                        >{{ $t("production") }}</span
                      >
                    </div>
                  </router-link>
                </li>
              </ul>
            </slide-transition>
          </div>
          <router-link
            :to="item.path"
            class="flex gap-4 py-2 px-3 rounded"
            :exact="item.path === '/'"
            exact-active-class="active-tab-pills !bg-secondary"
            v-else-if="item.access ? hasPermission(item.access) : true"
          >
            <div>
              <i :class="item.icon + ' w-4 fa-md'"></i>
            </div>
            <div class="flex-1 self-center">
              <p class="font-medium rtl:font-semibold line-clamp-1 text-base">
                {{ $t(item.name) }}
              </p>
            </div>
            <div
              v-if="
                ['Stores', 'Orders', 'Wastages', 'POS Devices'].includes(
                  item.name
                ) && hasTrialDays
              "
            >
              <span
                class="py-1 px-2 rounded-full bg-primary text-white text-[10px] inline-block font-medium ring-2 ring-gray-800"
                v-tippy
                content="Enabled in production mode"
                >{{ $t("production") }}</span
              >
            </div>
          </router-link>
        </li>
      </ul>

      <div class="p-4 border-t border-t-gray-700" v-if="hasTrialDays">
        <div class="p-3 bg-primary rounded-lg">
          <div
            class="flex items-center justify-center text-white gap-3 font-semibold text-sm"
          >
            <i class="fa-solid fa-lock"></i>
            <span> {{ $t("trial-version") }} </span>
          </div>
        </div>
      </div>
      <div class="p-4 w-full border-t border-gray-700">
        <div class="flex items-center gap-4 w-full">
          <router-link to="/settings/profile" class="flex-1 space-y-1 grid">
            <p class="text-sm font-semibold text-white line-clamp-1">
              {{ user.name || "N/A" }}
            </p>

            <p class="text-xs text-slate-300 line-clamp-1">
              {{ user.email || "N/A" }}
            </p>
          </router-link>
          <button
            class="text-white w-8 h-8 bg-gray-700 flex items-center justify-center rounded-full shrink-0"
            v-tippy
            content="Logout"
            @click.prevent="logout"
          >
            <i class="fa-solid fa-arrow-right-from-bracket"></i>
          </button>
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "SideBar",
  data() {
    return {
      load: false,
      items: [],

      tree: null,
    };
  },
  created() {
    this.getStores();
  },
  watch: {
    // $route() {
    //   this.tree = null;
    // },
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    async getStores() {
      try {
        const { result } = await this.getRoute({
          name: "stores",
          options: {
            is_active: 1,
          },
        });
        const stores = result.stores;
        this.items = stores.filter((e) => e.has_pos_devices);
        const selectedStore = JSON.parse(localStorage.getItem("store"));
        if (stores?.length >= 1 && !selectedStore) {
          localStorage.setItem("store", stores?.[0].id);
          window.location.reload();
        }
      } catch (error) {
      } finally {
        this.load = true;
      }
    },
    openCloseSideBar() {
      const sidebar = this.$refs.sideBar;
      if (!sidebar) return;
      if (sidebar.classList.contains("opacity-0")) {
        sidebar.classList.remove(
          ...["opacity-0", "pointer-events-none", "invisible", "left-full"]
        );
      } else {
        sidebar.classList.add(
          ...["opacity-0", "pointer-events-none", "invisible", "left-full"]
        );
      }
    },
    changeStore(e) {
      const val = e.target.value;
      const store = this.items.find((e) => e.id == val);
      localStorage.setItem("store", val);
      localStorage.setItem("store_module", store?.module?.module_type);
      localStorage.setItem("module", JSON.stringify(store?.module));
      this.createAlert("Location updated successfully");
      window.location.reload();
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    pages() {
      const pages = [
        {
          name: "dashboard",
          icon: "fa-solid fa-home",
          path: "/",
          access: "show dashboard",
        },
        {
          name: "expenses",
          icon: "fa-solid fa-dollar",
          path: "/expenses",
          access: "show expenses",
        },
        //{
        //  name: "POS System",
        //  icon: "fa-solid fa-cash-register",
        //  path: "/pos-system",
        //  access: "show pos_system",
        //},

        {
          name: "analytics",
          icon: "fa-solid fa-chart-line",
          access: [
            "show summary",
            "show incomes",
            "show oos_alerts",
            "show invoice_sales",
            "show item_sales",
            "show invoice_inventory",
            "show invoice_categories",
            "show invoice_refunds",
          ],
          children: [
            {
              name: "summary",
              path: "/analytics",
              access: "show summary",
            },
            {
              name: "reports",
              path: "/reports",
              access: [
                "show incomes",
                "show customers",
                "show oos_alerts",
                "show invoice_sales",
                "show item_sales",
                "show invoice_inventory",
                "show invoice_categories",
                "show invoice_refunds",
                "show invoice_wastage",
              ],
            },
          ],
        },
        {
          name: "accounting",
          icon: "fa-solid fa-book",
          access: [
            "show accounting_chart",
            "show accounting_entries",
            "show accounting_centers",
          ],
          children: [
            {
              name: "chart-of-accounts",
              path: "/accounting/chart",
              access: "show accounting_chart",
            },
            {
              name: "journal-entries",
              path: "/accounting/journal-entries",
              access: "show accounting_entries",
            },
            {
              name: "cost-centers",
              path: "/accounting/cost-centers",
              access: "show accounting_centers",
            },
          ],
        },

        {
          name: "invoice-management",
          icon: "fa-solid fa-check-to-slot",
          access: [
            "show customers",
            "show customer_groups",
            "show invoice_sales",
          ],
          children: [
            {
              name: "invoices",
              path: "/invoices",
              access: "show invoice_sales",
            },

            {
              name: "customers",
              path: "/customers",
              access: "show customers",
            },
            {
              name: "customers-group",
              path: "/customers/group",
              access: "show customer_groups",
            },
            {
              name: "settings",
              path: "/sales-settings",
              access: "show sales_settings",
            },
          ],
        },
        {
          name: "inventory-management",
          icon: "fa-solid fa-wrench",
          access: [
            "show purchase_orders",
            "show transfer_orders",
            "show refund_orders",
            "show processing_tracks",
            "show inventory_wastages",
            "show stock_count",
            "show suppliers",
          ],
          children: [
            {
              name: "purchase-orders",
              path: "/inventory-actions",
              access: "show purchase_orders",
            },
            {
              name: "payments",
              path: "/inventory-actions/payments",
              access: "show order_payments",
            },
            {
              name: "refunds",
              path: "/inventory-actions/refunds",
              access: "show refund_orders",
            },
            {
              name: "transfers",
              path: "/inventory-actions/transfers",
              access: "show transfer_orders",
            },
            {
              name: "processing",
              path: "/inventory-actions/processing",
              access: "show processing_tracks",
            },
            {
              name: "wastages",
              path: "/inventory-actions/wastages",
              access: "show inventory_wastages",
            },
            {
              name: "stocktaking",
              path: "/inventory-actions/stocktaking",
              access: "show stock_count",
            },
            {
              name: "suppliers",
              path: "/inventory-actions/suppliers",
              access: "show suppliers",
            },
          ],
        },
        {
          name: "offers",
          icon: "fa-solid fa-tags",
          path: "/discounts-offers",
          access: "show discounts",
        },

        {
          name: "notifications",
          icon: "fa-solid fa-bell",
          path: "/notifications",
          access: "show notifications",
        },
        {
          name: "support",
          icon: "fa-solid fa-headphones",
          path: "/support",
        },
        {
          name: "settings",
          icon: "fa-solid fa-gear",
          path: "/settings",
        },
      ];

      return pages.map((e) => {
        const names = ["Analytics", "Inventory actions"];
        const route = this.route(e.access);
        if (names.includes(e.name)) {
          return {
            ...e,
            path: route || e.path,
          };
        }
        return e;
      });
    },
  },
};
</script>
