<template>
  <div>
    <div class="w-full" v-if="!access_token && !id">
      <!-- <div class="w-full max-w-[6rem] mx-auto">
          <img 
            src="@/assets/images/logo.jpg"
            alt="Auth logo"
            class="w-full h-auto"
          />
        </div> -->
      <h1 class="text-primary text-4xl capitalize text-center font-bold">
        Create an account
      </h1>
      <p
        class="text-gray-600 text-center font-medium rtl:font-semibold text-sm mt-2"
      >
        Welcome to Nyqat system, fill the form
      </p>
      <ul
        class="list-none flex gap-4 overflow-x-auto pb-4 whitespace-nowrap !my-10 items-center justify-between max-w-screen-sm mx-auto"
      >
        <li v-for="(item, index) in steps" :key="index">
          <div
            class="flex items-center gap-2"
            :class="item.is_checked ? 'text-teal-600' : 'text-gray-400'"
          >
            <div>
              <i class="fa-md align-middle" :class="item.icon"></i>
            </div>
            <div class="flex-1">
              <p
                class="line-clamp-1 text-sm"
                :class="
                  item.is_checked
                    ? 'font-semibold rtl:font-bold'
                    : 'font-medium rtl:font-semibold'
                "
              >
                {{ item.title }}
              </p>
            </div>
          </div>
        </li>
      </ul>

      <!-- <form
          v-if="has_form"
          :action="`/checkout/?payment=${add_data.payment_option}&payment_option=${add_data.payment_option}`"
          class="paymentWidgets"
          :data-brands="
            add_data.payment_option === 'visa'
              ? 'VISA MASTER'
              : add_data.payment_option === 'mada'
              ? 'MADA'
              : ''
          "
        ></form> -->
      <form
        class="max-w-screen-sm w-full mx-auto space-y-4"
        v-if="!access_token && !id"
      >
        <p class="text-red-500 font-semibold text-center" v-if="error">
          {{ error }}
        </p>
        <slide-transition>
          <figure class="w-full" data-type="step-1" v-if="step_index === 1">
            <!-- v-if="step_index === " -->
            <figcaption class="w-full border-b border-b-primary pb-3">
              <p class="text-primary font-semibold rtl:font-bold">
                Choose package
              </p>
            </figcaption>
            <blockquote class="mt-6 overflow-y-auto md:max-h-[26rem]">
              <ul class="list-none space-y-4">
                <div
                  class="flex gap-4 items-center animate-pulse p-5 bg-white ring-1 ring-gray-200"
                  v-if="!load"
                >
                  <div>
                    <div class="w-9 h-9 rounded-full bg-gray-200"></div>
                  </div>
                  <div class="flex-1 max-w-xs space-y-2">
                    <div class="w-2/3 h-4 rounded bg-gray-200"></div>
                    <div class="w-full h-3 rounded bg-gray-100"></div>
                  </div>
                </div>
                <li v-for="(item, index) in packages" :key="index" v-else>
                  <label
                    :for="`package-${item.id}`"
                    class="p-5 rounded bg-gray-100 !gap-4 checkbox cursor-pointer hover:bg-gray-200 transition-[background-color]"
                  >
                    <div
                      class="shrink-0 w-14 h-14 rounded flex items-center justify-center bg-gray-100 border border-gray-200"
                      v-if="item.image"
                    >
                      <img
                        :src="
                          item.image ||
                          require('@/assets/images/placeholder.png')
                        "
                        @error="
                          (e) =>
                            (e.target.src = require('@/assets/images/placeholder.png'))
                        "
                        alt="Package image"
                        class="max-w-full max-h-full shrink-0 object-contain"
                      />
                    </div>
                    <div class="flex-1 space-y-2">
                      <h2
                        class="text-gray-600 font-semibold rtl:font-bold text-sm"
                      >
                        {{ item["name_" + $i18n.locale] }}
                        <a
                          href="javascript:void(0)"
                          @click.prevent="showDevices(item.id)"
                          v-tippy
                          content="View devices"
                          v-if="(item.devices || []).length"
                        >
                          <i class="fa-solid fa-circle-info"></i>
                        </a>
                      </h2>
                      <!-- <p v-if="(item.devices || []).length" class="text-xs">
                          (
                          {{
                              (item.devices || [])
                                .map((e) => e["name_" + $i18n.locale])
                                .join(", ")
                          }}
                          )
                        </p> -->
                      <h1 class="text-primary text-xl font-bold">
                        {{
                          (+item.total_price || 0).toFixed(2)
                            ? `${(+item.total_price || 0).toFixed(2)} SAR`
                            : "Free"
                        }}
                        <span class="text-sm">
                          per {{ item.months }}
                          {{ item.months > 1 ? "months" : "month" }}</span
                        >
                        <span class="text-sm" v-if="item.installments > 1">
                          and payment will in {{ item.installments }}
                          {{
                            item.installments > 1
                              ? "installments"
                              : "installment"
                          }}</span
                        >
                      </h1>
                      <!-- <p
                          class="text-gray-600 font-medium rtl:font-semibold text-xs"
                        >
                          9.99 SAR per month includes VAT & local taxes may
                          apply
                        </p> -->
                    </div>
                    <div class="self-start">
                      <input
                        type="radio"
                        name="packages"
                        :id="`package-${item.id}`"
                        v-model="add_data.package_id"
                        :value="item.id"
                      />
                    </div>
                  </label>
                </li>
                <li v-if="add_errors.package_id">
                  <p class="error">
                    {{ (add_errors["package_id"] || []).join(" ") }}
                  </p>
                </li>
              </ul>
            </blockquote>
          </figure>
        </slide-transition>
        <slide-transition>
          <figure
            class="w-full space-y-4"
            data-type="step-2"
            v-if="step_index === 2"
          >
            <div class="w-full space-y-3">
              <label
                for="name"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                Full name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Mohammed Abduallah"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded text-sm"
                autocomplete="off"
                v-model="add_data.vendor_name"
              />
              <span class="error">
                {{ (add_errors.vendor_name || []).join(" ") }}
              </span>
            </div>
            <div class="w-full space-y-3">
              <label
                for="email"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                E-mail address
              </label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="example@example.com"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded text-sm"
                autocomplete="off"
                v-model="add_data.email"
                v-email
              />
              <span class="error">
                {{ (add_errors.email || []).join(" ") }}
              </span>
            </div>
            <div class="w-full space-y-3">
              <label
                for="mobile"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                Mobile number
              </label>
              <vue-tel-input
                id="mobile"
                ref="customerMobile"
                v-model="add_data.mobile"
                @open="handleOpen('customerMobile')"
              ></vue-tel-input>
              <span class="error">
                {{ (add_errors.mobile || []).join(" ") }}
              </span>
            </div>
            <div class="w-full space-y-3">
              <label
                for="password"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                Password
              </label>
              <div class="relative">
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium pr-12 rtl:pr-4 rtl:pl-4 rounded text-sm"
                  autocomplete="off"
                  v-model="add_data.password"
                />
                <button
                  class="w-12 h-full flex items-center justify-center text-gray-500 text-sm absolute top-0 right-0 rtl:left-0 rtl:right-auto"
                  type="button"
                  @click.prevent="textPasswordType"
                >
                  <i class="fa-solid fa-eye"></i>
                </button>
              </div>

              <span class="error">
                {{ (add_errors.password || []).join(" ") }}
              </span>
            </div>
          </figure>
        </slide-transition>
        <slide-transition>
          <figure
            class="w-full space-y-4"
            data-type="step-3"
            v-if="step_index === 3"
          >
            <div class="w-full space-y-3">
              <label
                for="store_name"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                Store name
              </label>
              <input
                type="text"
                name="store_name"
                id="store_name"
                :placeholder="`${add_data.vendor_name} store`"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded text-sm"
                autocomplete="off"
                v-model="add_data.store_name"
              />
              <span class="error">
                {{ (add_errors.store_name || []).join(" ") }}
              </span>
            </div>

            <div class="w-full space-y-3">
              <label
                for="module"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                Module
              </label>
              <multiselect
                v-model="add_data.module_id"
                :placeholder="'search'"
                :label="`name_${$i18n.locale}`"
                track-by="id"
                :options="modules"
                :hideSelected="true"
                :clearOnSelect="true"
                :disabled="modules.length === 0"
              ></multiselect>
              <span class="error">
                {{ (add_errors.module_id || []).join(" ") }}
              </span>
            </div>
          </figure>
        </slide-transition>
        <slide-transition>
          <figure class="w-full" data-type="step-4" v-if="step_index === 4">
            <ul class="list-none space-y-4" v-if="(documents || []).length">
              <li
                class="w-full space-y-3"
                v-for="(doc, index) in documents"
                :key="index"
              >
                <figure class="space-y-4">
                  <p
                    class="text-gray-600 font-medium rtl:font-semibold text-sm"
                  >
                    {{ doc["name_" + $i18n.locale] }}
                  </p>
                  <slide-transition>
                    <figcaption
                      v-if="add_data['doc_' + doc.id]"
                      class="relative table"
                      :data-type="JSON.stringify(add_data['doc_id' + doc.id])"
                    >
                      <img
                        :src="
                          add_data['doc_' + doc.id]
                            ? convertImage(add_data['doc_' + doc.id])
                            : require('@/assets/images/placeholder.png')
                        "
                        @error="
                          (e) =>
                            (e.target.src = require('@/assets/images/placeholder.png'))
                        "
                        alt="store image"
                        class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
                      />
                      <button
                        class="w-6 h-6 rounded-full flex items-center justify-center bg-red-500 text-white absolute -top-2 -right-2 rtl:right-auto rtl:-left-2 ring-2 ring-white"
                        type="button"
                        @click.prevent="() => clearImage('doc_' + doc.id)"
                      >
                        <i class="fa-solid fa-xmark fa-sm"></i>
                      </button>
                    </figcaption>
                  </slide-transition>
                  <blockquote class="space-y-2">
                    <label class="block">
                      <span class="sr-only">Choose image</span>
                      <input
                        type="file"
                        accept="image/*"
                        class="custom-file"
                        :ref="'doc_' + doc.id"
                        @change="
                          (e) =>
                            $set(add_data, 'doc_' + doc.id, e.target.files[0])
                        "
                      />
                    </label>
                    <p class="error" v-if="add_errors['doc_' + doc.id]">
                      {{ add_errors["doc_" + doc.id].join(" ") }}
                    </p>
                  </blockquote>
                </figure>
                <!-- <p
                    class="text-gray-600 font-medium rtl:font-semibold text-sm"
                  >
                    {{ doc["name_" + $i18n.locale] }}
                  </p>
                  
                  <input
                    type="file"
                    class="custom-file"
                    @change.prevent="handleUploader($event, doc.id)"
                  />
                  <span class="error">
                    {{ (add_errors[`doc_${doc.id}`] || []).join(" ") }}
                  </span> -->
              </li>
            </ul>
            <ul class="list-none grid grid-cols-2 gap-4" v-else>
              <li class="space-y-1 col-span-2">
                <p class="font-semibold text-sm">Current package</p>
              </li>
              <li class="space-y-2">
                <h4 class="text-xs font-medium text-gray-500">Package</h4>
                <p class="font-semibold text-sm">
                  {{ selectedPackage["name_" + $i18n.locale] }} -
                  {{
                    selectedPackage.total_price
                      ? `${selectedPackage.total_price} SAR`
                      : "Free"
                  }}
                </p>
              </li>
              <li class="space-y-1 col-span-2">
                <p class="font-semibold text-sm">Personal information</p>
              </li>

              <li class="space-y-1">
                <h4 class="text-xs font-medium text-gray-500">Full name</h4>
                <p class="font-semibold text-sm">
                  {{ add_data.vendor_name }}
                </p>
              </li>
              <li class="space-y-1">
                <h4 class="text-xs font-medium text-gray-500">Mobile</h4>
                <p class="font-semibold text-sm">
                  {{ add_data.mobile }}
                </p>
              </li>
              <li class="space-y-1 col-span-2">
                <h4 class="text-xs font-medium text-gray-500">
                  E-mail address
                </h4>
                <p class="font-semibold text-sm">
                  {{ add_data.email }}
                </p>
              </li>
              <li class="space-y-1 col-span-2">
                <p class="font-semibold text-sm">Store information</p>
              </li>
              <li class="space-y-1">
                <h4 class="text-xs font-medium text-gray-500">Store name</h4>
                <p class="font-semibold text-sm">
                  {{ add_data.store_name }}
                </p>
              </li>
              <li class="space-y-1">
                <h4 class="text-xs font-medium text-gray-500">Module</h4>
                <p class="font-semibold text-sm">
                  {{ (add_data.module_id || {})["name_" + $i18n.locale] }}
                </p>
              </li>
              <li class="space-y-1 col-span-2" v-if="(documents || []).length">
                <p class="font-semibold text-sm">Documents</p>
              </li>
              <li class="col-span-2" v-if="(documents || []).length">
                <div class="inline-flex gap-2 flex-wrap">
                  <div
                    class="shrink-0 space-y-1"
                    v-for="document in documents"
                    :key="document.id"
                  >
                    <h4 class="text-xs font-medium text-gray-500">
                      {{ document["name_" + $i18n.locale] }}
                    </h4>
                    <div class="py-1 px-2 rounded bg-gray-200">
                      <a
                        :href="createImage(add_data['doc_' + document.id])"
                        class="font-semibold text-xs inline-block"
                      >
                        {{
                          (add_data["doc_" + document.id] || {}).name
                            | getSize(add_data["doc_" + document.id])
                        }}
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </figure>
        </slide-transition>
        <slide-transition>
          <!-- <figure class="w-full" data-type="step-5" v-if="step_index === 5">
              <div class="form-group">
                <div class="with-label">
                  <p class="error">
                    {{ (add_errors["payment_option"] || []).join(" ") }}
                  </p>
                  <ul class="list-none space-y-3">
                    <li>
                      <div class="actions-group items-center">
                        <div
                          class="check-with-content"
                          v-for="(payment, index) in payments"
                          :key="index"
                        >
                          <input
                            type="radio"
                            name="payment_option"
                            :id="payment"
                            :value="payment"
                            v-model="add_data.payment_option"
                            hidden
                            aria-hidden="true"
                            class="hidden"
                          />
                          <label :for="payment">
                            <img
                              :src="
                                require(`@/assets/images/payments/${payment}.png`)
                              "
                              @error="
                                $event.target.parentElement.parentElement.remove()
                              "
                              alt="payment icon"
                              title="payment icon"
                          /></label>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <label for="payment_option"> Payment method </label>
                </div>
              </div>
            </figure> -->
        </slide-transition>
        <slide-transition>
          <figure class="w-full" data-type="step-5" v-if="step_index === 5">
            <ul class="list-none grid grid-cols-2 gap-4">
              <li class="space-y-1 col-span-2">
                <p class="font-semibold text-sm">Current package</p>
              </li>
              <li class="space-y-2">
                <h4 class="text-xs font-medium text-gray-500">Package</h4>
                <p class="font-semibold text-sm">
                  {{ selectedPackage["name_" + $i18n.locale] }} -
                  {{
                    selectedPackage.total_price
                      ? `${selectedPackage.total_price} SAR`
                      : "Free"
                  }}
                </p>
              </li>
              <li class="space-y-1 col-span-2">
                <p class="font-semibold text-sm">Personal information</p>
              </li>

              <li class="space-y-1">
                <h4 class="text-xs font-medium text-gray-500">Full name</h4>
                <p class="font-semibold text-sm">
                  {{ add_data.vendor_name }}
                </p>
              </li>
              <li class="space-y-1">
                <h4 class="text-xs font-medium text-gray-500">Mobile</h4>
                <p class="font-semibold text-sm">
                  {{ add_data.mobile }}
                </p>
              </li>
              <li class="space-y-1 col-span-2">
                <h4 class="text-xs font-medium text-gray-500">
                  E-mail address
                </h4>
                <p class="font-semibold text-sm">
                  {{ add_data.email }}
                </p>
              </li>
              <li class="space-y-1 col-span-2">
                <p class="font-semibold text-sm">Store information</p>
              </li>
              <li class="space-y-1">
                <h4 class="text-xs font-medium text-gray-500">Store name</h4>
                <p class="font-semibold text-sm">
                  {{ add_data.store_name }}
                </p>
              </li>
              <li class="space-y-1">
                <h4 class="text-xs font-medium text-gray-500">Module</h4>
                <p class="font-semibold text-sm">
                  {{ (add_data.module_id || {})["name_" + $i18n.locale] }}
                </p>
              </li>
              <li class="space-y-1 col-span-2">
                <p class="font-semibold text-sm">Documents</p>
              </li>
              <li class="col-span-2">
                <div class="inline-flex gap-2 flex-wrap">
                  <div
                    class="shrink-0 space-y-1"
                    v-for="document in documents"
                    :key="document.id"
                  >
                    <h4 class="text-xs font-medium text-gray-500">
                      {{ document["name_" + $i18n.locale] }}
                    </h4>
                    <div class="py-1 px-2 rounded bg-gray-200">
                      <a
                        :href="createImage(add_data['doc_' + document.id])"
                        class="font-semibold text-xs inline-block"
                      >
                        {{
                          (add_data["doc_" + document.id] || {}).name
                            | getSize(add_data["doc_" + document.id])
                        }}
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </figure>
        </slide-transition>
        <div class="w-full space-y-4 max-w-xl mx-auto !mt-10">
          <button-with-loader
            btnClass="w-full py-3 px-4 rounded bg-primary
          text-white text-sm"
            :disabled="disabled"
            @click="handleRegisterWithSteps"
            >{{
              steps.every((e) => e.is_checked) ? "Create an account" : "Next"
            }}</button-with-loader
          >
          <!-- @click="login" -->
          <p
            class="text-gray-600 text-center font-medium rtl:font-semibold text-sm"
          >
            Already have an account?
            <router-link to="/account/login" class="text-primary underline"
              >Login!</router-link
            >
          </p>
        </div>
      </form>
    </div>
    <div class="w-full" v-else>
      <figure class="max-w-full lg:max-w-screen-sm w-full mx-auto space-y-4">
        <div class="w-full max-w-[9rem] mx-auto !mb-10">
          <img
            src="@/assets/images/email.png"
            alt="Auth logo"
            class="w-full h-auto"
          />
        </div>
        <h1 class="text-primary text-4xl capitalize text-center font-bold">
          Verify your email address
        </h1>
        <p
          class="text-gray-600 text-center font-medium rtl:font-semibold text-sm mt-2"
        >
          You're almost there! To continue using Nyqat, Please verify your email
          address by opening the mail sent to you and clicking verification link
          to complete your sign up process
        </p>
        <div class="w-full space-y-6 max-w-xl mx-auto !mt-10">
          <button-with-loader
            btnClass="w-full text-white text-sm bg-primary rounded py-3 px-4"
            :disabled="confirm_disabled"
            @click="confirmEmail"
            type="button"
            >Confirm Your email</button-with-loader
          >
          <button-with-loader
            btnClass="w-full text-gray-600 text-sm  py-3 px-4"
            :disabled="resend_disabled"
            @click="resendCode"
            type="button"
          >
            <p>
              Need us to send the verification email again ?
              <span class="text-primary"> Send again</span>
            </p>
          </button-with-loader>
        </div>
      </figure>
    </div>

    <Modal
      title="Package devices"
      v-if="devices_active"
      @close="devices_active = $event"
    >
      <template>
        <div class="p-4">
          <ul class="list-none space-y-3 divide-y divide-gray-200">
            <li
              class="flex gap-4 pt-3 first:p-0"
              v-for="(device, index) in infoPackage.devices"
              :key="index"
            >
              <div
                class="shrink-0 w-14 h-14 rounded flex items-center justify-center bg-gray-100 border border-gray-200"
              >
                <img
                  :src="
                    device.image || require('@/assets/images/placeholder.png')
                  "
                  @error="
                    (e) =>
                      (e.target.src = require('@/assets/images/placeholder.png'))
                  "
                  alt="Device image"
                  class="max-w-full max-h-full shrink-0 object-contain"
                />
              </div>
              <div class="flex-1 space-y-1">
                <p class="font-semibold rtl:font-bold text-sm">
                  {{ device["name_" + $i18n.locale] }}
                </p>
                <p class="font-medium rtl:font-semibold text-sm text-gray-600">
                  {{ device["desc_" + $i18n.locale] }}
                </p>
              </div>
              <div class="shrink-0">
                <p class="text-sm font-bold text-primary">
                  {{ (+device.price || 0).toFixed(2) }} SAR
                </p>
              </div>
            </li>
          </ul>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import Modal from "@/components/basics/Modal.vue";
import { mapActions } from "vuex";
export default {
  components: { ButtonWithLoader, Modal },
  name: "Register",
  props: {
    user_type: {
      type: String,
      default: "vendor",
    },
  },
  data() {
    return {
      disabled: false,
      add_data: {
        module_id: null,
        form_step: 1,
        user_type: this.user_type,
      },
      add_errors: {},
      error: "",
      steps: [
        {
          title: "Packages",
          icon: "fa-solid fa-list-check",
          type: "packages",
          is_checked: true,
        },
        {
          title: "Personal info",
          icon: "fa-solid fa-user-check",
          type: "personal-info",
          is_checked: false,
        },
        {
          title: "Store info",
          icon: "fa-solid fa-store",
          type: "store-info",
          is_checked: false,
        },
        {
          title: "Documents",
          icon: "fa-solid fa-folder-open",
          type: "documents",
          is_checked: false,
        },
        {
          title: "Preview",
          icon: "fa-solid fa-eye",
          type: "preview",
          is_checked: false,
        },
        // {
        //   title: "Payments",
        //   icon: "fa-solid fa-credit-card",
        //   is_checked: false,
        // },
      ],
      step_index: 1,
      modules: [],
      documents: [],
      packages: [],
      access_token: null,
      id: null,
      is_verified: false,
      confirm_disabled: false,
      resend_disabled: false,
      load: false,
      has_form: false,
      payments: ["visa", "mada", "apple", "stc"],
      infoPackage: {
        devices: [],
      },
      devices_active: false,
    };
  },
  filters: {
    getSize() {
      const [name, file] = arguments;
      const formatter = (number) =>
        Intl.NumberFormat("en-SA", {
          notation: "compact",
        }).format(number);

      return name + " - " + formatter(file.size);
      // console.log(arguments);
    },
  },
  watch: {
    devices_active(val) {
      if (!val) {
        this.infoPackage = {};
      }
    },
  },
  computed: {
    selectedPackage() {
      return this.packages?.find((e) => e.id === this.add_data.package_id);
    },
  },
  async created() {
    const registerInfo = localStorage.getItem("register-info");
    if (!registerInfo) {
      Promise.all([
        await this.getDocuments(),
        await this.getModules(),
        await this.getPackages(),
      ]).finally(() => {
        this.load = true;
      });
      return;
    }
    const { access_token, id } = JSON.parse(registerInfo);
    this.access_token = access_token;
    this.id = id;
    this.storeDataAndListenToChannel({ access_token, id });
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    async getDocuments() {
      try {
        const { result } = await this.getRoute("documents");
        this.documents = result.documents;

        if (!result?.documents?.length) {
          this.steps = this.steps.filter((e) => e.type !== "documents");
        }
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getPackages() {
      try {
        const { result } = await this.getRoute("packages");
        this.packages = result.packages;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getModules() {
      try {
        const { result } = await this.getRoute("modules");
        this.modules = result.modules;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async handleRegisterWithSteps() {
      let fd = new FormData();
      this.add_errors = {};
      this.error = null;
      this.disabled = true;

      for (const key in this.add_data) {
        const item = this.add_data[key];
        if (/doc_\w+/.test(key)) {
          fd.append(key, item, item.name);
        } else if (["module_id"].includes(key)) {
          fd.append(key, item?.id);
        } else if (key === "mobile") {
          fd.append(key, item?.replace(/[+|\s|()|-]/g, ""));
        } else {
          fd.append(key, item);
        }
      }
      if (this.step_index === 4 && this.documents?.length) {
        this.step_index = 5;
        this.steps = this.steps.map((e) => ({
          ...e,
          is_checked: true,
        }));
        this.disabled = false;
        return;
      }

      try {
        const { data } = await this.axios.post("register", fd);
        const { next_step, ...others } = data.result;
        if (next_step) {
          this.$set(this.steps[next_step - 1], "is_checked", true);
          this.add_data.form_step = next_step;
          this.step_index = next_step;
          return;
        }

        // this.has_form = true;
        // const externalScript = document.createElement("script");
        // const scriptStyle = document.createElement("script");
        // externalScript.setAttribute("src", `${others.checkout_url}`);
        // scriptStyle.innerHTML = `var wpwlOptions = {
        //                   style: "plain",
        //                   validation: true
        //               }
        //                 `;
        // document.head.appendChild(externalScript);
        // document.head.appendChild(scriptStyle);
        // this.createAlert(others?.message);

        localStorage.setItem("register-info", JSON.stringify(others));
        // this.access_token = others.access_token;
        // this.id = others.id;
        this.storeDataAndListenToChannel(others);
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.disabled = false;
      }
    },

    storeDataAndListenToChannel({ id, access_token }) {
      this.access_token = access_token;
      this.id = id;

      this.$echo.connector.pusher.config.auth.headers["Authorization"] =
        "Bearer " + access_token;

      const channel = this.$echo?.private(`user-${id}-channel`);
      channel?.listen(".verify_vendor", () => {
        this.storeVendorData(access_token);
      });
    },

    async confirmEmail() {
      this.confirm_disabled = true;
      try {
        const { data } = await this.axios.post("confirm_verify_vendor", null, {
          headers: {
            Authorization: `Bearer ${this.access_token}`,
          },
        });

        this.createAlert(data.message);
        this.storeVendorData(access_token);
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
          this.createAlert(responseError, "error");
        }
      } finally {
        this.confirm_disabled = false;
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
    storeVendorData(token) {
      this.$cookies.set("token", token);
      this.is_verified = true;
      localStorage.clear();
      window.location.reload();
      // this.$router.push("/settings");
      // this.$store.commit("SET_TOKEN", token);
    },
    async resendCode() {
      this.resend_disabled = true;
      try {
        const { data } = await this.axios.post("resend_verify_vendor", null, {
          headers: {
            Authorization: `Bearer ${this.access_token}`,
          },
        });

        this.createAlert(data.message);
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          // this.error = responseError;
          this.createAlert(responseError, "error");
        }
      } finally {
        this.resend_disabled = false;
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
    handleUploader(e, id) {
      const files = e.target.files;
      if (!files.length) {
        this.createAlert("There are no files selected", "info");
        return;
      }

      this.$set(this.add_data, `doc_${id}`, files[0]);
    },
    showDevices(id) {
      const item = this.packages.find((e) => e.id === id);
      if (!item?.devices?.length) {
        this.createAlert(
          "There are no devices available into this package",
          "info"
        );
        return;
      }
      this.infoPackage = item;
      this.devices_active = true;
    },
    clearImage(key) {
      this.$set(this.add_data, key, null);
      const uploader = this.$refs[key]?.[0];
      if (uploader) uploader.value = null;
    },
  },
};
</script>
