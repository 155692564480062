<template>
  <section class="w-full p-8">
    <main class="w-full max-w-screen-lg mx-auto text-center">
      <img
        src="@/assets/images/error.png"
        alt="permission image"
        class="w-full max-w-xs mx-auto mb-6"
      />
      <h1 class="text-2xl font-bold text-center text-primary mb-4">
        This page cannot be accessed.
      </h1>
      <p class="font-medium rtl:font-semibold text-gray-600 text-lg mb-10">
        The page you were looking for could not be found, it may have been
        removed, renamed, or never existed in the first place. Or another error
        occurred, please try again later
      </p>
      <router-link
        to="/"
        class="py-2 px-6 rounded-full bg-blue-500 text-white text-sm mb-3 inline-block mx-1 font-medium rtl:font-semibold"
      >
        Back home
      </router-link>
    </main>
  </section>
</template>
<script>
export default {
  name: "PageNotFound",
};
</script>
