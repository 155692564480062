<template>
  <div class="p-6">
    <AnalyticsContainerWrapper class="space-y-6">
      <!-- <cards-container :cards="cards" :load="!load"></cards-container> -->
      <filter-wrapper
        @apply="getItems"
        @reset="handleReset"
        ref="filterWrapper"
      >
        <div>
          <t-datepicker
            :placeholder="`From - To`"
            multiple
            :monthsPerView="2"
            v-model="pagination.date"
            range
            :clearable="false"
            userFormat="Y-m-d"
            :weekStart="6"
          />
        </div>
      </filter-wrapper>
      <Table
        title="Refunds"
        :records="`${pagination.total || 0} records`"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
        :has_search="false"
        has_export
        :exportParams="pagination"
        :fields="fields"
      >
        <template #head>
          <!-- <th>#</th> -->
          <th>Name</th>
          <th>Value</th>
        </template>
        <template #tbody v-if="!load">
          <TableLoad :rows="2" v-for="i in 4" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length">
          <tr v-for="(item, index) in items" :key="index">
            <td>
              {{ item["name_" + $i18n.locale] }}
            </td>
            <td>
              <span v-if="item.unit_type === 'weight'">
                {{ GenerateUnit(item.refunded_weight || 0) }}
              </span>
              <span v-else> {{ item.refunded_qty || 0 }} Piece </span>
            </td>
          </tr>
        </template>

        <template #tbody v-else>
          <tr>
            <td colspan="2" class="!text-center">There are no data display</td>
          </tr>
        </template>
      </Table>
    </AnalyticsContainerWrapper>
  </div>
</template>

<script>
import CardsContainer from "@/components/basics/CardsContainer.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import AnalyticsContainerWrapper from "@/components/AnalyticsContainerWrapper.vue";
import FilterWrapper from "@/components/basics/FilterWrapper.vue";
export default {
  name: "Refunds",
  components: {
    CardsContainer,
    AnalyticsContainerWrapper,
    FilterWrapper,
  },
  data() {
    return {
      items: [],
      load: false,

      pagination: {
        page: 1,
        total: 0,
        total_pages: 0,
      },
      disables: {},
      fields: [],
    };
  },
  async created() {
    this.$set(this.pagination, "date", [
      this.$route.query.date ||
        this.$moment().add(-1, "days").format("YYYY-MM-DD"),
      this.$route.query.date || this.$moment().format("YYYY-MM-DD"),
    ]);
    Promise.all([await this.getItems()]).finally(() => (this.load = true));
  },
  watch: {
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
    "pagination.page"() {
      this.getItems();
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getItems() {
      try {
        const { total, total_pages, date, ...others } = this.pagination;
        const { result } = await this.getRoute({
          name: "invoices/refunds",
          options: {
            ...others,
            search_key: this.search,
            store_id: this.store_id,
            from: date?.[0],
            to: date?.[1],
          },
        });
        const { data, pagination } = result.refunds;
        this.fields = result.export_fields;

        this.items = data;
        this.$set(this.pagination, "total", pagination?.total);
        this.$set(this.pagination, "total_pages", pagination?.total_pages);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        const filterWrapper = this.$refs.filterWrapper;
        filterWrapper?.resetAll();
        this.changeIsCalled(false);
      }
    },

    getPaymentImage(image) {
      try {
        return require(`@/assets/images/${image.toLowerCase()}.png`);
      } catch (error) {
        return require(`@/assets/images/cash.png`);
      }
    },
    handleReset() {
      const { page, ...others } = this.pagination;

      for (const key in others) {
        const item = others[key];
        if (typeof item === "number") {
          this.$set(this.pagination, key, 0);
        } else if (Array.isArray(item)) {
          this.$set(this.pagination, key, []);
        } else {
          this.$set(this.pagination, key, null);
        }
      }

      this.getItems();
    },
  },
};
</script>
