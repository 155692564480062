<template>
  <div>
    <section class="w-full p-6 space-y-6">
      <Table
        title="POS devices"
        :records="`${pagination.total || 0} records`"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
      >
        <!-- <template #actions>
          <div v-if="!hasTrialDays">
            <button
              class="
                py-1.5
                px-3
                text-sm
                flex
                items-center
                gap-2
                text-gray-600
                font-medium
                rtl:font-semibold
              "
              @click.prevent="add_active = !add_active"
            >
              <i class="fa-solid fa-plus"></i>
              <span>New device</span>
            </button>
          </div>
        </template> -->
        <template #filter>
          <ul class="flex items-center">
            <li v-for="(item, index) in status" :key="index">
              <button
                class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
                @click.prevent="
                  changeFilterStatus(item === 'all' ? null : item, index)
                "
                :class="{
                  'active-tab':
                    pagination.is_active === (item === 'all' ? null : item),
                }"
                :disabled="disables[index]"
              >
                <spinner
                  size="w-4 h-4 inline-block"
                  class="mr-2 rtl:mr-0 rtl:ml-2"
                  v-if="disables[index]"
                />
                <!-- {{ $t(item.replace(/\s/g, "_")) }} -->
                {{ item }}
              </button>
            </li>
          </ul>
        </template>
        <template #head>
          <th>Package</th>
          <th>Store</th>
          <th>Price</th>
          <!-- <th>Payment option</th> -->
          <th>Code</th>
          <th>PIN</th>
          <th>Start date</th>
          <th>End date</th>
          <th>Status</th>
          <th>Latest update</th>
          <th>Actions</th>
        </template>
        <template #tbody v-if="!load">
          <TableLoad :rows="10" v-for="i in 4" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length">
          <tr v-for="(item, index) in items" :key="index">
            <td>
              {{ (item.package || {})["name_" + $i18n.locale] || "-" }}
            </td>

            <td>
              {{ (item.store || {})["name_" + $i18n.locale] || "-" }}
            </td>
            <td>{{ item.price }} SAR</td>
            <!-- <td>
              <div class="flex items-center gap-2">
                <div v-if="(item.payment || {}).payment_option">
                  <img
                    :src="getPaymentImage((item.payment || {}).payment_option)"
                    alt="payment option"
                    width="20"
                    height="15"
                  />
                </div>
                <div>
                  {{ (item.payment || {}).payment_option }}
                </div>
              </div>
            </td> -->
            <td>{{ item.code || "-" }}</td>
            <td>{{ item.pin || "-" }}</td>

            <td v-html="createTime(item.start_date)"></td>
            <td v-html="createTime(item.end_date)"></td>
            <td>
              <p class="status capitalize" :class="item_status[item.is_active]">
                {{ item.is_active === 1 ? "Active" : "Not active" }}
              </p>
            </td>
            <td v-html="createTime(item.updated_at || item.created_at)"></td>

            <td>
              <div class="actions-group">
                <div v-if="hasPermission('delete pos_devices')">
                  <button
                    class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                    @click.prevent="deleteItem(item.id)"
                    :disabled="disables[`delete_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`delete_${item.id}`]"
                    />
                    <span v-else> Delete </span>
                  </button>
                </div>
                <div v-if="hasPermission('update pos_devices')">
                  <button
                    class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                    @click.prevent="changeStatus(item.id)"
                    :content="'Change status'"
                    v-tippy
                    :disabled="disables[`status_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`status_${item.id}`]"
                    />
                    <i class="fa-solid fa-right-left" v-else></i>
                  </button>
                </div>
                <div v-if="hasPermission('update pos_devices')">
                  <button
                    class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                    @click.prevent="viewDeviceDetails(item)"
                    content="View details"
                    v-tippy
                  >
                    <i class="fa-solid fa-eye"></i>
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </template>

        <template #tbody v-else>
          <tr>
            <td colspan="100%" class="!text-center">
              There are no data display
            </td>
          </tr>
        </template>
      </Table>
    </section>
    <transition name="scale">
      <modal title="New device" v-if="add_active" @close="handleClose">
        <template>
          <div class="p-4">
            <form
              v-if="has_form"
              :action="`/checkout/?payment=${add_data.payment_option}&payment_option=${add_data.payment_option}`"
              class="paymentWidgets"
              :data-brands="
                add_data.payment_option === 'visa'
                  ? 'VISA MASTER'
                  : add_data.payment_option === 'mada'
                  ? 'MADA'
                  : ''
              "
            ></form>
            <form class="space-y-4" v-else>
              <div class="form-group space-y-3 flex-1">
                <label for="package_id"> Package </label>
                <t-rich-select
                  :options="packages"
                  placeholder="Select"
                  :text-attribute="'name_' + $i18n.locale"
                  value-attribute="id"
                  :clearable="true"
                  :CloseOnSelect="true"
                  v-model="add_data.package_id"
                />

                <p class="error">
                  {{ (add_errors["package_id"] || []).join(" ") }}
                </p>
              </div>
              <slide-transition>
                <div v-if="package_selected" class="space-y-4">
                  <div class="form-group-with-check">
                    <div>
                      <input
                        type="checkbox"
                        id="is_main"
                        name="is_main"
                        v-model="add_data.is_main"
                      />
                    </div>
                    <label for="is_main"> Main POS </label>
                  </div>
                  <details class="bg-gray-100 rounded p-4">
                    <summary
                      class="text-xs font-semibold rtl:font-bold text-gray-600 cursor-pointer"
                    >
                      Package details
                    </summary>
                    <div class="mt-4">
                      <ul class="list-none space-y-3">
                        <li class="space-y-2">
                          <p class="font-semibold rtl:font-bold">
                            {{ package_selected["name_" + $i18n.locale] }}
                          </p>
                          <p
                            class="font-medium rtl:font-semibold text-sm text-gray-600"
                          >
                            {{ package_selected["desc_" + $i18n.locale] }}
                          </p>
                          <p class="font-bold text-sm text-gray-600">
                            Price:
                            {{
                              package_selected.price
                                ? package_selected.price +
                                  " SAR " +
                                  " / " +
                                  package_selected.months
                                : "FREE"
                            }}
                          </p>
                          <p class="font-bold text-sm text-gray-600">
                            Trial days:
                            {{
                              package_selected.trial_days
                                ? package_selected.trial_days + " Day"
                                : "N/A"
                            }}
                          </p>
                          <p class="font-bold text-sm text-primary">
                            Total price:
                            {{
                              add_data.is_main
                                ? package_selected.total_price
                                  ? package_selected.total_price +
                                    " SAR " +
                                    " / " +
                                    package_selected.months
                                  : "FREE"
                                : package_selected.total_alt_price
                                ? package_selected.total_alt_price +
                                  " SAR " +
                                  " / " +
                                  package_selected.months
                                : "FREE"
                            }}
                          </p>
                          <p
                            class="font-bold text-sm text-gray-600 italic"
                            v-if="package_selected.batches > 1"
                          >
                            {{ package_selected.batches }} installment(s) are
                            available for payment
                          </p>
                        </li>
                        <li v-if="package_selected.devices_installment">
                          <p
                            class="font-bold text-sm italic p-3 rounded bg-orange-200 text-orange-600"
                          >
                            Devices are paid in installments with the price of
                            the package in the same specified payments
                          </p>
                        </li>
                        <slide-transition>
                          <li v-if="has_devices">
                            <details>
                              <summary
                                class="text-xs font-semibold rtl:font-bold text-gray-600"
                              >
                                Devices
                              </summary>
                              <div class="mt-4">
                                <ul class="list-none space-y-3">
                                  <li
                                    class="inline-flex gap-4"
                                    v-for="(
                                      device, index
                                    ) in package_selected.devices"
                                    :key="index"
                                  >
                                    <div class="shrink-0">
                                      <img
                                        :src="
                                          device.image ||
                                          require('@/assets/images/placeholder.png')
                                        "
                                        @error="
                                          (e) =>
                                            (e.target.src = require('@/assets/images/placeholder.png'))
                                        "
                                        alt="commercial_register_image"
                                        class="w-auto max-w-[5rem] object-contain"
                                      />
                                    </div>
                                    <div class="flex-1 space-y-2">
                                      <p
                                        class="font-semibold rtl:font-bold text-sm"
                                      >
                                        {{ device["name_" + $i18n.locale] }} -
                                        {{ device.price }} SAR
                                      </p>
                                      <p
                                        class="font-medium rtl:font-semibold text-sm text-gray-600"
                                      >
                                        {{ device["desc_" + $i18n.locale] }}
                                      </p>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </details>
                          </li>
                        </slide-transition>
                      </ul>
                    </div>
                  </details>
                </div>
              </slide-transition>
              <div class="form-group">
                <div class="with-label">
                  <p class="error">
                    {{ (add_errors["payment_option"] || []).join(" ") }}
                  </p>
                  <ul class="list-none space-y-3">
                    <li>
                      <div class="actions-group items-center">
                        <div
                          class="check-with-content"
                          v-for="(payment, index) in payments"
                          :key="index"
                        >
                          <input
                            type="radio"
                            name="payment_option"
                            :id="payment"
                            :value="payment"
                            v-model="add_data.payment_option"
                            hidden
                            aria-hidden="true"
                            class="hidden"
                          />
                          <label :for="payment">
                            <img
                              :src="
                                require(`@/assets/images/payments/${payment}.png`)
                              "
                              @error="
                                $event.target.parentElement.parentElement.remove()
                              "
                              alt="payment icon"
                              title="payment icon"
                          /></label>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <label for="payment_option"> Payment method </label>
                </div>
              </div>
              <div>
                <button-with-loader
                  :disabled="add_disabled"
                  btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
                  @click="addItem"
                >
                  Submit
                </button-with-loader>
              </div>
            </form>
          </div>
        </template>
      </modal>
    </transition>

    <transition name="scale">
      <modal title="New device" v-if="edit_active" @close="handleClose">
        <template>
          <div class="p-4">
            <form
              v-if="has_form"
              :action="`/checkout/?payment=${edit_data.payment_option}&payment_option=${edit_data.payment_option}`"
              class="paymentWidgets"
              :data-brands="
                edit_data.payment_option === 'visa'
                  ? 'VISA MASTER'
                  : edit_data.payment_option === 'mada'
                  ? 'MADA'
                  : ''
              "
            ></form>
            <form class="space-y-4" v-else>
              <div class="form-group space-y-3 flex-1">
                <label for="package_id"> Package </label>
                <t-rich-select
                  :options="packages"
                  placeholder="Select"
                  :text-attribute="'name_' + $i18n.locale"
                  value-attribute="id"
                  :clearable="true"
                  v-model="edit_data.package_id"
                />

                <p class="error">
                  {{ (edit_errors["package_id"] || []).join(" ") }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <p class="error">
                    {{ (edit_errors["payment_option"] || []).join(" ") }}
                  </p>
                  <ul class="list-none space-y-3">
                    <li>
                      <div class="actions-group items-center">
                        <div
                          class="check-with-content"
                          v-for="(payment, index) in payments"
                          :key="index"
                        >
                          <input
                            type="radio"
                            name="payment_option"
                            :id="payment"
                            :value="payment"
                            v-model="edit_data.payment_option"
                            hidden
                            aria-hidden="true"
                            class="hidden"
                          />
                          <label :for="payment">
                            <img
                              :src="
                                require(`@/assets/images/payments/${payment}.png`)
                              "
                              @error="
                                $event.target.parentElement.parentElement.remove()
                              "
                              alt="payment icon"
                              title="payment icon"
                          /></label>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <label for="payment_option"> Payment method </label>
                </div>
              </div>
              <div>
                <button-with-loader
                  :disabled="edit_disabled"
                  btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
                  @click="editItem"
                >
                  Save changes
                </button-with-loader>
              </div>
            </form>
          </div>
        </template>
      </modal>
    </transition>
    <modal
      title="Device information"
      size="!max-w-screen-md"
      v-if="edit_active"
      @close="edit_active = $event"
    >
      <form class="space-y-4 p-6">
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-3">
          <div class="space-y-1">
            <p class="text-sm text-gray-600">Package</p>
            <p>
              {{ device_details.package?.["name_" + $i18n.locale] || "-" }}
              {{ generateCurrency(device_details.price || 0) }}
            </p>
          </div>
          <div class="space-y-1">
            <p class="text-sm text-gray-600">Store</p>
            <p>{{ device_details.store?.["name_" + $i18n.locale] || "-" }}</p>
          </div>

          <div class="space-y-1">
            <p class="text-sm text-gray-600">Start date</p>
            <p>{{ $moment(device_details.start_date).format("ll") }}</p>
          </div>
          <div class="space-y-1">
            <p class="text-sm text-gray-600">End date</p>
            <p>{{ $moment(device_details.end_date).format("ll") }}</p>
          </div>
          <div class="col-span-full">
            <div class="w-full bg-gray-200 p-px h-3 rounded-full">
              <div
                class="w-1/2 bg-blue-600 rounded-full h-full transition"
                :style="{
                  width: remainingDays + '%',
                }"
              ></div>
            </div>
          </div>
          <div class="space-y-1 col-span-full">
            <p class="text-sm text-gray-600">Serial no.</p>
            <p>{{ device_details.serial_no || "-" }}</p>
          </div>
        </div>

        <p class="text-sm font-medium text-gray-600">Login information</p>

        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div class="form-group space-y-3">
            <label>Code</label>
            <input
              type="text"
              disabled
              readOnly
              :placeholder="edit_data.code"
            />
            <p class="form-error">{{ edit_errors["code"] }}</p>
          </div>
          <div class="form-group space-y-3">
            <label>PIN</label>
            <input type="text" v-model="edit_data.pin" />
            <p class="form-error">{{ edit_errors["pin"] }}</p>
          </div>
        </div>

        <p class="text-sm font-medium text-gray-600">Settings</p>

        <ul class="divide-y divide-gray-200">
          <li
            v-for="role in roles"
            :key="role.key"
            class="py-3 first:pt-0 last:pb-0"
          >
            <div class="form-group-with-check">
              <input
                type="checkbox"
                data-type="switch"
                :id="role.key"
                :true-value="1"
                :false-value="0"
                v-model="edit_data[role.key]"
              />
              <label :for="role.key" class="block space-y-1 cursor-pointer">
                <p class="font-bold">{{ role.title }}</p>
                <p class="text-sm text-gray-600">
                  {{ role.content }}
                </p>
              </label>
            </div>
          </li>
        </ul>

        <button-with-loader
          btnClass="bg-primary py-2 px-4 rounded text-xs font-medium text-white"
          @click="updateDeviceSettings"
          :disabled="edit_disabled"
        >
          <span>Save changes</span>
        </button-with-loader>
      </form>
    </modal>
  </div>
</template>

<script>
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import Modal from "@/components/basics/Modal.vue";
import { pick } from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: { Modal, ButtonWithLoader },
  name: "PosShop",
  data() {
    return {
      items: [],
      add_data: {
        payment_option: null,
        is_main: 0,
      },
      add_errors: {},
      add_disabled: false,
      add_active: false,
      packages: [],
      load: false,
      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,
      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-500/10 text-red-500",
      },
      status: ["all", "active", "not active"],
      pagination: {
        is_active: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      payments: ["visa", "mada", "apple", "stc"],
      disables: {},
      has_form: false,
      roles: [
        {
          title: "Customer name required",
          content:
            "The customer's name must be entered during the sales process",
          key: "customer_name_is_required",
        },
        {
          title: "Customer mobile number required",
          content:
            "The customer's mobile number must be entered during the sales process",
          key: "customer_mobile_is_required",
        },
        {
          title: "Automatically issue invoices",
          content: "Automatically issue invoices from the platform",
          key: "automatic_issue_invoices",
        },
        {
          title: "Allow refund",
          content: "Allow refund previous sales process",
          key: "allow_refund",
        },
      ],
      show_details: false,
      device_details: {},
    };
  },
  async created() {
    Promise.all([await this.getPackages(), await this.getItems()]).finally(
      () => {
        this.load = true;
      }
    );
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
    add_active(val, _) {
      if (!val) {
        const uploader = this.$refs.uploader;
        this.add_data = {};
        this.add_errors = {};
        uploader.value = null;
      }
    },
    show_details(val, _) {
      if (!val) {
        this.device_details = {};
        this.add_errors = {};
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
    has_devices() {
      const devices = this.packages.find(
        (e) => e.id === this.add_data?.package_id
      )?.devices;
      return !!devices?.length;
    },
    package_selected() {
      const item = this.packages.find(
        (e) => e.id === this.add_data?.package_id
      );
      return item;
    },
    remainingDays() {
      const start_date = this.device_details.start_date;
      const end_date = this.device_details.end_date;
      console.log(start_date, end_date);
      if (!(start_date && end_date)) return 0;
      const totalDays = this.$moment(new Date(end_date)).diff(
        this.$moment(new Date(start_date)),
        "days"
      );
      const remainingFromToday = this.$moment(new Date(end_date)).diff(
        this.$moment(),
        "days"
      );
      console.log(
        "remaining from start and end date of current device package => ",
        totalDays
      );
      console.log(
        "remaining from today and end date of current device package => ",
        remainingFromToday
      );
      return parseFloat(((remainingFromToday / totalDays) * 100).toFixed(2));
    },
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),

    async getPackages() {
      try {
        const { result } = await this.getRoute("packages");
        this.packages = result.packages;
      } catch (error) {}
    },

    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;

        const { result } = await this.getRoute({
          name: "pos_devices",
          options: {
            ...others,
            is_active: others.is_active
              ? others.is_active === "active"
                ? 1
                : 0
              : null,
            search_key: this.search,
            store_id: this.store_id,
          },
        });
        const { data, pagination } = result.pos_devices;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.changeIsCalled(false);
      }
    },
    async changeStatus(id) {
      const allNotActive = this.items
        .filter((e) => e.id !== id)
        .every((e) => !e.is_active);

      if (allNotActive || this.items.length === 1) {
        this.createAlert(
          "There must be at least one package for purchases to start",
          "info"
        );
        return;
      }
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(
          `pos_devices/update_is_active/${id}`
        );
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      if (this.items.length === 1) {
        this.createAlert(
          "You can't delete current package, there must be at least one package for purchases to start",
          "info"
        );
        return;
      }
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { data } = await this.axios.post(`pos_devices/delete/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async updateDeviceSettings() {
      try {
        this.edit_disabled = true;
        this.edit_errors = {};

        const { data } = await this.axios.post(
          "pos_devices/update",
          this.edit_data
        );

        this.edit_data = {};
        this.edit_active = false;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.edit_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.edit_disabled = false;
      }
    },
    async addItem() {
      try {
        this.add_disabled = true;
        this.add_errors = {};

        const { data } = await this.axios.post(
          "pos_devices/add",
          this.add_data
        );
        if (data.result) {
          this.has_form = true;
          const externalScript = document.createElement("script");
          const scriptStyle = document.createElement("script");
          externalScript.setAttribute("src", `${data.result.checkout_url}`);
          scriptStyle.innerHTML = `var wpwlOptions = {
                          style: "plain",
                          validation: true
                      }
                        `;
          document.head.appendChild(externalScript);
          document.head.appendChild(scriptStyle);
          this.createAlert(data?.message);
          return;
        }
        this.add_data = {};
        this.add_active = false;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.add_disabled = false;
      }
    },
    showUpdateLogin(item) {
      const { id, code, pin } = item;
      this.login_data = {
        id,
        code,
        pin,
      };
      this.login_active = true;
    },
    handleClose() {
      if (this.has_form) {
        this.createAlert(
          "You can't close current dialog before payment done.",
          "error"
        );
        return;
      }
      this.add_data = {};
      this.add_error = {};
      this.login_data = {};
      this.login_errors = {};
      this.edit_data = {};
      this.edit_errors = {};
      this.has_form = false;
      this.add_active = false;
      this.edit_active = false;
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "is_active", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
    getPaymentImage(image) {
      try {
        return require(`@/assets/images/payments/${image}.png`);
      } catch (error) {
        return require(`@/assets/images/placeholder.png`);
      }
    },
    viewDeviceDetails(item) {
      this.device_details = item;
      const data = pick(item, [
        "customer_name_is_required",
        "customer_mobile_is_required",
        "allow_refund",
        "code",
        "pin",
        "id",
      ]);
      this.edit_data = data;
      this.edit_active = true;
    },
  },
};
</script>
<style lang="scss">
.check-with-content {
  label {
    @apply py-3 px-5 flex items-center justify-center bg-gray-100 rounded-md hover:bg-gray-200 transition cursor-pointer;
    img {
      @apply w-full max-w-[3rem] max-h-[1.5rem];
    }
  }
  input:checked + label {
    @apply bg-primary/10 hover:bg-primary/20 border-2 border-primary;
  }
}
</style>
