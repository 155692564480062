<template>
  <div class="p-6">
    <CategorizationsContainerWrapper class="space-y-6">
      <Table
        title="Categories group"
        :records="`${pagination.total || 0} records`"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
      >
        <template #actions>
          <div v-if="hasPermission('add categories_group')">
            <button
              class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
              @click.prevent="add_active = !add_active"
            >
              <i class="fa-solid fa-plus"></i>
              <span>New group</span>
            </button>
          </div>
        </template>
        <template #filter>
          <ul class="flex items-center">
            <li v-for="(item, index) in status" :key="index">
              <button
                class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
                @click.prevent="
                  changeFilterStatus(item === 'all' ? null : item, index)
                "
                :class="{
                  'active-tab':
                    pagination.is_active === (item === 'all' ? null : item),
                }"
                :disabled="disables[index]"
              >
                <spinner
                  size="w-4 h-4 inline-block"
                  class="mr-2 rtl:mr-0 rtl:ml-2"
                  v-if="disables[index]"
                />
                <!-- {{ $t(item.replace(/\s/g, "_")) }} -->
                {{ item }}
              </button>
            </li>
          </ul>
        </template>
        <template #head>
          <th>Name</th>
          <th>Categories</th>
          <th>Status</th>
          <th>Latest update</th>
          <th>Actions</th>
        </template>
        <template #tbody v-if="!load">
          <TableLoad :rows="5" v-for="i in 4" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length">
          <tr v-for="(item, index) in items" :key="index">
            <td>
              {{ item["name_" + $i18n.locale] }}
            </td>
            <td>
              <div class="actions-group !flex-wrap min-w-[15rem]">
                <span
                  class="status text-gray-600 bg-gray-100"
                  v-for="(category, index) in item.categories"
                  :key="index"
                  >{{ category["name_" + $i18n.locale] }}</span
                >

                <span class="text-gray-600" v-if="!item.categories.length"
                  >-</span
                >
              </div>
            </td>
            <td>
              <p class="status capitalize" :class="item_status[item.is_active]">
                {{ item.is_active === 1 ? "Active" : "Not active" }}
              </p>
            </td>

            <td v-html="createTime(item.updated_at || item.created_at)"></td>

            <td>
              <div class="actions-group" v-if="hasPermission('update categories_group') || hasPermission('edit categories_group') || hasPermission('delete categories_group')">
               
                <div v-if="hasPermission('update categories_group')">
                  <button
                    class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                    @click.prevent="changeStatus(item.id)"
                    :content="'Change status'"
                    v-tippy
                    :disabled="disables[`status_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`status_${item.id}`]"
                    />
                    <i class="fa-solid fa-right-left" v-else></i>
                  </button>
                </div>
                <div v-if="hasPermission('edit categories_group')">
                  <button
                    class="w-8 h-8 bg-sky-500 text-white rounded"
                    @click.prevent="showUpdate(item.id)"
                    :content="'Update'"
                    v-tippy
                  >
                    <i class="fa-solid fa-pen"></i>
                  </button>
                </div>
                 <div v-if="hasPermission('delete categories_group')">
                  <button
                    class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                    @click.prevent="deleteItem(item.id)"
                    :disabled="disables[`delete_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`delete_${item.id}`]"
                    />
                    <span v-else> Delete </span>
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </template>

        <template #tbody v-else>
          <tr>
            <td colspan="5" class="!text-center">There are no data display</td>
          </tr>
        </template>
      </Table>
      <Modal
        title="Add category"
        v-if="add_active"
        @close="
          (e) => {
            add_active = e;
          }
        "
      >
        <template>
          <form class="p-6 space-y-4">
            <div class="form-group">
              <div class="with-label">
                <p class="error" v-if="add_errors.name_en">
                  {{ add_errors.name_en.join(" ") }}
                </p>
                <input
                  type="text"
                  name="name_en"
                  id="name_en"
                  placeholder="Name"
                  required
                  autocomplete="off"
                  v-model="add_data.name_en"
                  v-ltr
                />
                <label for="name_en">Name | en</label>
              </div>
            </div>

            <div class="form-group">
              <div class="with-label">
                <p class="error" v-if="add_errors.name_ar">
                  {{ add_errors.name_ar.join(" ") }}
                </p>

                <input
                  type="text"
                  name="name_ar"
                  id="name_ar"
                  placeholder="الاسم"
                  required
                  autocomplete="off"
                  v-model="add_data.name_ar"
                  v-rtl
                />
                <label for="name_ar">Name | ar</label>
              </div>
            </div>

            <div class="w-full space-y-3">
              <label
                for="categories"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                Categories
              </label>
              <select-with-limit
                :options="categories"
                placeholder="Select"
                :text="'name_' + $i18n.locale"
                id="id"
                v-model="add_data.category_ids"
              />
              <span class="error">
                {{ (add_errors.category_ids || []).join(" ") }}
              </span>
            </div>
            <div>
              <button-with-loader
                :disabled="add_disabled"
                btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
                @click="addItem"
              >
                Add
              </button-with-loader>
            </div>
          </form>
        </template>
      </Modal>
      <Modal
        :title="JSON.parse(JSON.stringify(edit_data['name_' + $i18n.locale]))"
        v-if="edit_active"
        @close="
          (e) => {
            edit_active = e;
          }
        "
      >
        <template>
          <form class="p-6 space-y-4">
            <div class="form-group">
              <div class="with-label">
                <p class="error" v-if="edit_errors.name_en">
                  {{ edit_errors.name_en.join(" ") }}
                </p>
                <input
                  type="text"
                  name="name_en"
                  id="name_en"
                  placeholder="Name"
                  required
                  autocomplete="off"
                  v-model="edit_data.name_en"
                  v-ltr
                />
                <label for="name_en">Name | en</label>
              </div>
            </div>
            <div class="form-group">
              <div class="with-label">
                <p class="error" v-if="edit_errors.name_ar">
                  {{ edit_errors.name_ar.join(" ") }}
                </p>

                <input
                  type="text"
                  name="name_ar"
                  id="name_ar"
                  placeholder="الاسم"
                  required
                  autocomplete="off"
                  v-model="edit_data.name_ar"
                  v-rtl
                />
                <label for="name_ar">Name | ar</label>
              </div>
            </div>

            <div class="w-full space-y-3">
              <label
                for="categories"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                Categories
              </label>
              <select-with-limit
                :options="categories"
                placeholder="Select"
                :text="'name_' + $i18n.locale"
                id="id"
                v-model="edit_data.category_ids"
              />
              <span class="error">
                {{ (edit_errors.category_ids || []).join(" ") }}
              </span>
            </div>
            <div>
              <button-with-loader
                :disabled="edit_disabled"
                btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
                @click="editItem"
              >
                Save changes
              </button-with-loader>
            </div>
          </form>
        </template>
      </Modal>
    </CategorizationsContainerWrapper>
  </div>
</template>
<script>
import Modal from "@/components/basics/Modal.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import CategorizationsContainerWrapper from "@/components/products/CategorizationsContainerWrapper.vue";
export default {
  name: "CategoriesGroup",
  data() {
    return {
      load: false,
      items: [],
      categories: [],
      add_data: {},
      add_errors: {},
      add_disabled: false,
      add_active: false,

      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,

      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-500/10 text-red-500",
      },
      status: ["all", "active", "not active"],
      pagination: {
        is_active: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      disables: {},
    };
  },
  async created() {
    Promise.all([await this.getCategories(), await this.getItems()]).finally(
      () => (this.load = true)
    );
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
    add_active(val, _) {
      if (!val) {
        const uploader = this.$refs.uploader;
        this.add_data = {};
        this.add_errors = {};
        uploader.value = null;
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getCategories() {
      try {
        const { result } = await this.getRoute("categories");
        this.categories = result.categories;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;

        const { result } = await this.getRoute({
          name: "category_groups",
          options: {
            ...others,
            is_active: others.is_active
              ? others.is_active === "active"
                ? 1
                : 0
              : null,
            search_key: this.search,
            store_id: this.store_id,
          },
        });
        const { data, pagination } = result.category_groups;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.changeIsCalled(false);
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(
          `category_groups/update_is_active/${id}`
        );
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { data } = await this.axios.post(`category_groups/delete/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async addItem() {
      try {
        this.add_disabled = true;
        this.add_errors = {};
        let fd = new FormData();

        for (const key in this.add_data) {
          const item = this.add_data[key];
          if (["category_ids"].includes(key)) {
            item.forEach((e, i) => {
              fd.append(`${key}[${i}]`, e);
            });
          } else if (key === "image") {
            if (item) {
              fd.append(key, item, item?.name);
            }
          } else {
            fd.append(key, item);
          }
        }

        const { data } = await this.axios.post("category_groups/add", fd);
        this.add_data = {};
        this.add_active = false;
        // this.$refs.uploader.value = null;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.add_disabled = false;
      }
    },
    async editItem() {
      try {
        this.edit_disabled = true;
        this.edit_errors = {};
        let fd = new FormData();

        for (const key in this.edit_data) {
          const item = this.edit_data[key];
          if (["category_ids"].includes(key)) {
            item.forEach((e, i) => {
              fd.append(`${key}[${i}]`, e);
            });
          } else if (key === "image") {
            if (item && typeof item === "object") {
              fd.append(key, item, item?.name);
            } else {
              fd.delete(key);
            }
          } else {
            fd.append(key, item);
          }
        }

        const { data } = await this.axios.post("category_groups/update", fd);
        this.edit_data = {};
        this.edit_active = false;
        // this.$refs.uploader.value = null;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.edit_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.edit_disabled = false;
      }
    },
    clearImage(type) {
      const uploader = this.$refs.uploader;
      if (!uploader) return;
      uploader.value = null;
      this.$set(this[type], "image", null);
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id);
      if (!item) {
        this.createAlert("Item does not existed", "info");
        return;
      }
      this.edit_data = {
        ...item,
        category_ids: item?.categories?.map((e) => e.id),
      };
      this.edit_active = true;
    },

    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = "asc";
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == "asc" ? "desc" : "asc";
      }
      this.pagination.sort_key = key;
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`);
      });
    },

    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "is_active", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
  },
  components: { Modal, ButtonWithLoader, CategorizationsContainerWrapper },
};
</script>
