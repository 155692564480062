<template>
  <div class="p-6">
    <section class="w-full space-y-6">
      <Table
        title="Customer groups"
        :records="`${pagination.total || 0} records`"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
      >
        <template #actions>
          <div v-if="!hasTrialDays && hasPermission('add customer_groups')">
            <button
              class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
              @click.prevent="add_active = !add_active"
            >
              <i class="fa-solid fa-plus"></i>
              <span>New group</span>
            </button>
          </div>
        </template>
        <template #filter>
          <ul class="flex items-center">
            <li v-for="(item, index) in status" :key="index">
              <button
                class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
                @click.prevent="
                  changeFilterStatus(item === 'all' ? null : item, index)
                "
                :class="{
                  'active-tab':
                    pagination.is_active === (item === 'all' ? null : item),
                }"
                :disabled="disables[index]"
              >
                <spinner
                  size="w-4 h-4 inline-block"
                  class="mr-2 rtl:mr-0 rtl:ml-2"
                  v-if="disables[index]"
                />
                <!-- {{ $t(item.replace(/\s/g, "_")) }} -->
                {{ item }}
              </button>
            </li>
          </ul>
        </template>
        <template #head>
          <th>Name</th>
          <th>Customers</th>
          <th>Products</th>
          <th>Status</th>
          <th>Latest update</th>
          <th>Actions</th>
        </template>
        <template #tbody v-if="!load">
          <TableLoad :rows="6" v-for="i in 4" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length">
          <tr v-for="(item, index) in items" :key="index">
            <td>
              {{ item["name_" + $i18n.locale] }}
            </td>
            <td>
              <div class="actions-group !flex-wrap min-w-[15rem]">
                <span
                  class="status text-gray-600 bg-gray-100"
                  v-for="(customer, index) in (item.customers || []).slice(
                    0,
                    3
                  )"
                  :key="index"
                  >{{ customer.name || "N/A" }}</span
                >

                <span
                  class="status text-gray-600 bg-gray-100"
                  v-if="(item.customers || []).length > 3"
                  >+{{ (item.customers || []).length - 3 }}</span
                >
                <span
                  class="text-gray-600"
                  v-if="!(item.customers || []).length"
                  >-</span
                >
              </div>
            </td>
            <td>
              <div class="actions-group !flex-wrap min-w-[15rem]">
                <span
                  class="status text-gray-600 bg-gray-100"
                  v-for="(product, index) in (item.products || []).slice(0, 3)"
                  :key="index"
                  >{{ product["name_" + $i18n.locale] || "N/A" }}</span
                >

                <span
                  class="status text-gray-600 bg-gray-100"
                  v-if="(item.products || []).length > 3"
                  >+{{ (item.products || []).length - 3 }}</span
                >
                <span class="text-gray-600" v-if="!(item.products || []).length"
                  >-</span
                >
              </div>
            </td>
            <td>
              <p class="status capitalize" :class="item_status[item.is_active]">
                {{ item.is_active === 1 ? "Active" : "Not active" }}
              </p>
            </td>

            <td v-html="createTime(item.updated_at || item.created_at)"></td>

            <td>
              <div class="actions-group">
                <div v-if="hasPermission('update customer_groups')">
                  <button
                    class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                    @click.prevent="changeStatus(item.id)"
                    :content="'Change status'"
                    v-tippy
                    :disabled="disables[`status_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`status_${item.id}`]"
                    />
                    <i class="fa-solid fa-right-left" v-else></i>
                  </button>
                </div>
                <div v-if="hasPermission('update customer_groups')">
                  <button
                    class="w-8 h-8 bg-red-500/10 text-red-500 rounded"
                    @click.prevent="showProductsDiscount(item.id)"
                    :content="'Discounted products'"
                    v-tippy
                  >
                    <i class="fa-solid fa-tags"></i>
                  </button>
                </div>
                <div v-if="hasPermission('edit customer_groups')">
                  <button
                    class="w-8 h-8 bg-sky-500 text-white rounded"
                    @click.prevent="showUpdate(item.id)"
                    :content="'Update'"
                    v-tippy
                  >
                    <i class="fa-solid fa-pen"></i>
                  </button>
                </div>
                <div v-if="hasPermission('delete customer_groups')">
                  <button
                    class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                    @click.prevent="deleteItem(item.id)"
                    :disabled="disables[`delete_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`delete_${item.id}`]"
                    />
                    <span v-else> Delete </span>
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </template>

        <template #tbody v-else>
          <tr>
            <td colspan="8" class="!text-center">There are no data display</td>
          </tr>
        </template>
      </Table>
    </section>
    <Modal
      title="New group"
      v-if="add_active"
      @close="
        (e) => {
          add_active = e;
        }
      "
    >
      <template>
        <form class="p-6 space-y-4">
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors.name_ar">
                {{ add_errors.name_ar.join(" ") }}
              </p>
              <input
                type="text"
                name="name_ar"
                id="name_ar"
                placeholder="Name"
                required
                autocomplete="off"
                v-model="add_data.name_ar"
              />
              <label for="name_ar">Name | ar</label>
            </div>
          </div>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors.name_en">
                {{ add_errors.name_en.join(" ") }}
              </p>
              <input
                type="text"
                name="name_en"
                id="name_en"
                placeholder="Name"
                required
                autocomplete="off"
                v-model="add_data.name_en"
              />
              <label for="name_en">Name | en</label>
            </div>
          </div>

          <div class="w-full space-y-3">
            <label
              for="store"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Customers
            </label>
            <select-with-limit
              placeholder="..."
              :text="'name'"
              id="id"
              v-model="add_data.customer_ids"
              :options="customers"
            />
            <span class="error">
              {{ (add_errors.customer_ids || []).join(" ") }}
            </span>
          </div>
          <div>
            <button-with-loader
              :disabled="add_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="addItem"
            >
              Add
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
    <Modal
      :title="
        JSON.parse(
          JSON.stringify(edit_data['name_' + $i18n.locale] || 'Update group')
        )
      "
      v-if="edit_active"
      @close="
        (e) => {
          edit_active = e;
        }
      "
    >
      <template>
        <form class="p-6 space-y-4">
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors.name_ar">
                {{ edit_errors.name_ar.join(" ") }}
              </p>
              <input
                type="text"
                name="name_ar"
                id="name_ar"
                placeholder="Name"
                required
                autocomplete="off"
                v-model="edit_data.name_ar"
              />
              <label for="name_ar">Name | ar</label>
            </div>
          </div>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors.name_en">
                {{ edit_errors.name_en.join(" ") }}
              </p>
              <input
                type="text"
                name="name_en"
                id="name_en"
                placeholder="Name"
                required
                autocomplete="off"
                v-model="edit_data.name_en"
              />
              <label for="name_en">Name | en</label>
            </div>
          </div>

          <div class="w-full space-y-3">
            <label
              for="store"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Customers
            </label>
            <select-with-limit
              placeholder="..."
              :text="'name'"
              id="id"
              v-model="edit_data.customer_ids"
              :options="customers"
            />
            <span class="error">
              {{ (edit_errors.customer_ids || []).join(" ") }}
            </span>
          </div>
          <div>
            <button-with-loader
              :disabled="edit_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="editItem"
            >
              Save changes
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
    <Modal
      title="Discounted products"
      v-if="discount_active"
      @close="
        (e) => {
          discount_active = e;
        }
      "
      size="!max-w-screen-2xl"
    >
      <template>
        <form class="p-6 space-y-4">
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div
              class="space-y-4"
              v-for="(product, index) in discount_data.products"
              :key="index"
            >
              <div class="form-group">
                <div class="with-label">
                  <p
                    class="error"
                    v-if="discount_errors['products.' + index + '.id']"
                  >
                    {{ discount_errors["products." + index + ".id"].join(" ") }}
                  </p>
                  <div class="flex gap-4">
                    <t-rich-select
                      :options="products"
                      placeholder="Select"
                      text-attribute="name_with_price"
                      value-attribute="id"
                      :clearable="true"
                      :closeOnSelect="true"
                      v-model="product.id"
                      class="flex-1"
                    />
                    <button
                      class="w-10 rounded bg-red-500 text-white shrink-0"
                      :disabled="discount_data.products.length <= 1"
                      @click.prevent="removeCurrentItem(index)"
                    >
                      <i class="fa-solid fa-minus fa-sm"></i>
                    </button>
                  </div>
                  <label>Product</label>
                </div>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <p
                    class="error"
                    v-if="discount_errors['products.' + index + '.max_value']"
                  >
                    {{
                      discount_errors["products." + index + ".max_value"].join(
                        " "
                      )
                    }}
                  </p>
                  <input
                    type="number"
                    placeholder="1"
                    min="1"
                    required
                    autocomplete="off"
                    v-model="product.max_value"
                  />
                  <label
                    >Max value (
                    {{ product.unit_type === "weight" ? "KG" : "Piece" }}
                    )</label
                  >
                </div>
              </div>
              <details class="w-full border border-gray-200 rounded p-4">
                <summary class="text-sm font-semibold text-gray-600">
                  Discount
                </summary>
                <div class="space-y-4 mt-4">
                  <div class="actions-group !gap-4">
                    <div class="flex-1">
                      <div class="form-group">
                        <div class="with-label">
                          <p
                            class="error"
                            v-if="
                              discount_errors[
                                'products.' + index + '.discount_type'
                              ]
                            "
                          >
                            {{
                              discount_errors[
                                "products." + index + ".discount_type"
                              ].join(" ")
                            }}
                          </p>
                          <select
                            v-model="product.discount_type"
                            class="custom-select"
                          >
                            <option value="" selected disabled>
                              -- select --
                            </option>
                            <option value="fixed">Fixed</option>
                            <option value="percentage">Percentage</option>
                          </select>
                          <label>Discount type</label>
                        </div>
                      </div>
                    </div>
                    <div class="flex-1">
                      <div class="form-group">
                        <div class="with-label">
                          <p
                            class="error"
                            v-if="
                              discount_errors[
                                'products.' + index + '.discount_value'
                              ]
                            "
                          >
                            {{
                              discount_errors[
                                "products." + index + ".discount_value"
                              ].join(" ")
                            }}
                          </p>
                          <input
                            type="number"
                            placeholder="1"
                            min="1"
                            required
                            autocomplete="off"
                            v-model="product.discount_value"
                          />
                          <label>Discount value</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="actions-group !gap-4">
                    <div class="flex-1">
                      <div class="form-group">
                        <div class="with-label">
                          <p
                            class="error"
                            v-if="
                              discount_errors[
                                'products.' + index + '.discount_start_date'
                              ]
                            "
                          >
                            {{
                              discount_errors[
                                "products." + index + ".discount_start_date"
                              ].join(" ")
                            }}
                          </p>
                          <t-datepicker
                            placeholder="xxxx-xx-xx"
                            v-model="product.discount_start_date"
                            :clearable="false"
                            userFormat="Y-m-d"
                            :weekStart="6"
                          />
                          <label f>Start date</label>
                        </div>
                      </div>
                    </div>
                    <div class="flex-1">
                      <div class="form-group">
                        <div class="with-label">
                          <p
                            class="error"
                            v-if="
                              discount_errors[
                                'products.' + index + '.discount_end_date'
                              ]
                            "
                          >
                            {{
                              discount_errors[
                                "products." + index + ".discount_end_date"
                              ].join(" ")
                            }}
                          </p>
                          <t-datepicker
                            placeholder="xxxx-xx-xx"
                            v-model="product.discount_end_date"
                            :clearable="false"
                            userFormat="Y-m-d"
                            :weekStart="6"
                            :minDate="product.start_date"
                          />
                          <label>End date</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </details>
            </div>
          </div>
          <p
            class="error"
            v-html="Object.values(discount_errors).join('<br />')"
          ></p>
          <div v-if="products.length" class="flex items-center justify-end">
            <button
              class="bg-teal-600 text-white text-xs rounded font-semibold py-2 px-4"
              @click.prevent="addNewItem"
            >
              Add new
            </button>
          </div>

          <div>
            <button-with-loader
              :disabled="discount_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="updateDiscountedProducts"
            >
              Save changes
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
    <!-- <AnalyticsContainerWrapper>
    </AnalyticsContainerWrapper> -->
  </div>
</template>
<script>
import Modal from "@/components/basics/Modal.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import AnalyticsContainerWrapper from "@/components/AnalyticsContainerWrapper.vue";
export default {
  name: "CustomerGroups",
  data() {
    return {
      load: false,
      items: [],
      stores: [],
      add_data: {},
      add_errors: {},
      add_disabled: false,
      add_active: false,

      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,

      discount_data: {},
      discount_errors: {},
      discount_disabled: false,
      discount_active: false,

      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-500/10 text-red-500",
      },
      status: ["all", "active", "not active"],
      pagination: {
        is_active: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      disables: {},
      genders: ["male", "female"],
      customers: [],
      products: [],
      item: {
        id: null,
        discount_type: "fixed",
        discount_value: null,
        discount_start_date: null,
        discount_end_date: null,
      },
    };
  },
  async created() {
    await Promise.all([
      this.getItems(),
      this.getCustomers(),
      this.getProducts(),
    ]).finally(() => (this.load = true));
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
    add_active(val, _) {
      if (!val) {
        this.add_data = {};
        this.add_errors = {};
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
    filterProducts() {
      const selectedProducts = this.discount_data.products;
      const mapProducts = selectedProducts.map((e) => e.id);
      const result = this.products.filter((e) => !mapProducts.includes(e.id));

      return result;
    },
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getCustomers() {
      try {
        const { result } = await this.getRoute({
          name: "customers",
          options: {
            store_id: this.store_id,
          },
        });
        this.customers = result.customers;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getProducts() {
      try {
        const { result } = await this.getRoute({
          name: "products",
          options: {
            store_id: this.store_id,
          },
        });
        this.products = result.products?.map((product) => ({
          ...product,
          name_with_price:
            product["name_" + this.$i18n.locale] +
            " ( " +
            product.price +
            " SAR )",
        }));
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;

        const { result } = await this.getRoute({
          name: "customer_groups",
          options: {
            ...others,
            is_active: others.is_active
              ? others.is_active === "active"
                ? 1
                : 0
              : null,
            search_key: this.search || this.$route.query.search,
            store_id: this.store_id,
          },
        });
        const { data, pagination } = result.customer_groups;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.changeIsCalled(false);
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(
          `customer_groups/update_is_active/${id}`
        );
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { data } = await this.axios.post(`customer_groups/delete/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async addItem() {
      try {
        this.add_disabled = true;
        this.add_errors = {};

        const { data } = await this.axios.post(
          "customer_groups/add",
          this.add_data
        );
        this.add_data = {};
        this.add_active = false;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.add_disabled = false;
      }
    },
    async editItem() {
      try {
        this.edit_disabled = true;
        this.edit_errors = {};

        const { data } = await this.axios.post(
          "customer_groups/update",
          this.edit_data
        );
        this.edit_data = {};
        this.edit_active = false;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.edit_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.edit_disabled = false;
      }
    },
    async updateDiscountedProducts() {
      try {
        this.discount_disabled = true;
        this.discount_errors = {};

        const { data } = await this.axios.post(
          "customer_groups/update_discounted_products",
          this.discount_data
        );
        this.discount_data = {};
        this.discount_active = false;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.discount_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.discount_disabled = false;
      }
    },

    showUpdate(id) {
      const item = this.items.find((e) => e.id === id);
      if (!item) {
        this.createAlert("Item does not existed", "info");
        return;
      }
      this.edit_data = {
        ...item,
        customer_ids: item.customers?.map((e) => e.id),
      };
      this.edit_active = true;
    },

    showProductsDiscount(id) {
      const item = this.items.find((e) => e.id === id);

      if (!item) {
        this.createAlert("Item does not existed", "info");
        return;
      }
      this.discount_data = {
        customer_group_id: id,
        products: item.products?.length
          ? item.products
          : [JSON.parse(JSON.stringify(this.item))],
      };
      this.discount_active = true;
    },

    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = "asc";
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == "asc" ? "desc" : "asc";
      }
      this.pagination.sort_key = key;
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`);
      });
    },

    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "is_active", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
    addNewItem() {
      if (!this.products.length) {
        this.createAlert("Products are not enough to add new one.", "error");
        return;
      }
      this.discount_data.products.push(JSON.parse(JSON.stringify(this.item)));
    },
    removeCurrentItem(index) {
      if (index === 0 && this.discount_data.products.length === 1) {
        this.createAlert("One products at least should be added!!", "error");
        return;
      }
      this.discount_data.products.splice(index, 1);
    },
  },
  components: { Modal, ButtonWithLoader, AnalyticsContainerWrapper },
};
</script>
