<template>
  <div>
    <wrapper>
      <form class="w-full max-w-xl mx-auto space-y-4 mt-10">
        <div class="w-full max-w-[6rem] mx-auto">
          <img
            src="@/assets/images/logo.jpg"
            alt="Auth logo"
            class="w-full h-auto"
          />
        </div>
        <h1 class="text-primary text-4xl capitalize text-center font-bold">
          Welcome to Nyqat portal
        </h1>
        <p
          class="text-gray-600 text-center font-medium rtl:font-semibold text-sm"
        >
          Login into your account and start your business
        </p>
        <div class="w-full space-y-3 !mt-10">
          <p class="text-red-500 font-semibold text-center" v-if="error">
            {{ error }}
          </p>
          <label
            for="email"
            class="font-medium rtl:font-semibold text-gray-600 text-sm"
          >
            E-mail address
          </label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="example@example.com"
            class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded text-sm"
            autocomplete="off"
            v-model="add_data.email_mobile"
            v-email
          />
          <span class="error">
            {{ (add_errors.email_mobile || []).join(" ") }}
          </span>
        </div>
        <div class="w-full space-y-3">
          <label
            for="password"
            class="font-medium rtl:font-semibold text-gray-600 text-sm"
          >
            Password
          </label>
          <div class="relative">
            <input
              type="password"
              name="password"
              id="password"
              placeholder="••••••••"
              class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium pr-12 rtl:pr-4 rtl:pl-4 rounded text-sm"
              autocomplete="off"
              v-model="add_data.password"
            />
            <button
              class="w-12 h-full flex items-center justify-center text-gray-500 text-sm absolute top-0 right-0 rtl:left-0 rtl:right-auto"
              type="button"
              @click.prevent="textPasswordType"
            >
              <i class="fa-solid fa-eye"></i>
            </button>
          </div>
          <router-link
            to="/account/forgot-password"
            class="text-primary underline text-sm my-2 table [margin-inline-start:auto]"
            >Forgot password?</router-link
          >
          <span class="error">
            {{ (add_errors.password || []).join(" ") }}
          </span>
        </div>
        <div class="w-full space-y-4">
          <button-with-loader
            btnClass="w-full py-3 px-4 rounded bg-primary
          text-white"
            :disabled="disabled"
            @click="login"
            >Login</button-with-loader
          >
          <p
            class="text-gray-600 text-center font-medium rtl:font-semibold text-sm"
          >
            Don’t have an account?
            <router-link to="/account/register" class="text-primary underline"
              >Sign up!</router-link
            >
          </p>
        </div>
      </form>
    </wrapper>
  </div>
</template>
<script>
import Wrapper from "@/components/auth/Wrapper.vue";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  components: { Wrapper, ButtonWithLoader },
  name: "login",
  data() {
    return {
      disabled: false,
      add_data: {
        email_mobile: "",
      },
      add_errors: {},
      error: "",
    };
  },
  computed: {
    ...mapGetters("Cart", ["cart"]),
    ...mapGetters("warehouseCart", ["wareCart"]),
  },
  methods: {
    ...mapMutations("root", ["SET_HANDOVER"]),
    ...mapMutations("Cart", ["GENERATE_CART"]),
    ...mapMutations("warehouseCart", ["GENERATE_WARE_CART"]),
    async login() {
      this.disabled = true;
      let loginData = {
        ...this.add_data,
        email_mobile: this.add_data.email_mobile.replace(/[+|\s|()|-]/g, ""),
      };
      try {
        const { data } = await this.axios.post("login", loginData);
        const { profile, access_token } = data.result;
        const generateCartKey = `${profile?.user_type}_${profile?.id}`;
        this.$store.commit("SET_USER_DATA");
        this.$cookies.set("token", access_token);
        this.createAlert(data.message);
        this.SET_HANDOVER(true);
        if (!this.cart(generateCartKey)) {
          this.GENERATE_CART(generateCartKey);
        }
        if (!this.wareCart(generateCartKey)) {
          this.GENERATE_WARE_CART(generateCartKey);
        }
        window.location.reload();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.disabled = false;
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
  },
};
</script>
