<template>
  <section class="w-full p-6 space-y-6">
    <analytics-container-wrapper class="space-y-6">
      <filter-wrapper
        @apply="getItems"
        @reset="handleReset"
        ref="filterWrapper"
      >
        <div>
          <t-datepicker
            :placeholder="`From - To`"
            multiple
            :monthsPerView="2"
            v-model="pagination.date"
            range
            :clearable="false"
            userFormat="Y-m-d"
            :weekStart="6"
          />
        </div>
      </filter-wrapper>
      <Table
        title="Categories"
        :records="`${pagination.total || 0} records`"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
        has_export
        has_download_pdf
        :exportParams="pagination"
        :fields="fields"
      >
        <template #head>
          <th>Name</th>
          <th>Total QTY</th>
          <th>Total Weight</th>
          <th>Total Price</th>
        </template>
        <template #tbody v-if="!load">
          <TableLoad :rows="4" v-for="i in 4" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length">
          <tr v-for="(item, index) in items" :key="index">
            <td>{{ item["name_" + $i18n.locale] }}</td>
            <td>{{ item.total_qty || 0 }} Piece(s)</td>
            <td>{{ GenerateUnit(item.total_weight || 0) }}</td>
            <td>{{ item.total_price || 0 }} SAR</td>
          </tr>
        </template>

        <template #tbody v-else>
          <tr>
            <td colspan="4" class="!text-center">
              There are no data to display
            </td>
          </tr>
        </template>
      </Table>
    </analytics-container-wrapper>
  </section>
</template>
<script>
import Modal from "@/components/basics/Modal.vue";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import AnalyticsContainerWrapper from "@/components/AnalyticsContainerWrapper.vue";
import FilterWrapper from "@/components/basics/FilterWrapper.vue";
export default {
  name: "Categories",
  data() {
    return {
      load: false,
      items: [],
      currentPage: 1,
      rows: 0,
      totalPages: 0,
      perPage: 1,
      active: false,
      product_id: null,
      delete_disabled: false,
      status_disabled: [],
      error: "",
      search_key: "",
      search_disabled: false,
      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-500/10 text-red-500",
      },
      add_data: {
        is_inventory: 1,
      },
      add_errors: {},
      add_error: null,
      add_disabled: false,
      show_active: false,
      status: ["all", "active", "not active"],
      pagination: {
        is_active: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      indexes: [],
      fields: [],
    };
  },
  async created() {
    this.$set(this.pagination, "date", [
      this.$route.query.date ||
        this.$moment().add(-1, "days").format("YYYY-MM-DD"),
      this.$route.query.date || this.$moment().format("YYYY-MM-DD"),
    ]);
    await Promise.all([this.getItems()]);
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),

    async getItems() {
      try {
        const { total, total_pages, date, ...others } = this.pagination;
        const user = this.$store.state.user;

        const { result } = await this.getRoute({
          name: "invoices/categories",
          options: {
            ...others,
            search_key: this.search || this.$route.query.search,
            vendor_id: user.user_type === "vendor" ? user?.id : null,
            // store_id: user.user_type === "cashier" ? user?.id : null,
            store_id: this.store_id,
            from: date?.[0],
            to: date?.[1],
          },
        });

        const { data, pagination } = result.categories;
        this.fields = result.export_fields;

        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch (error) {
        console.log(error);
        // this.createAlert(result?.message, type);
      } finally {
        const filterWrapper = this.$refs.filterWrapper;
        filterWrapper?.resetAll();

        this.load = true;
        this.changeIsCalled(false);
      }
    },
    handleReset() {
      const { page, ...others } = this.pagination;

      for (const key in others) {
        const item = others[key];
        if (typeof item === "number") {
          this.$set(this.pagination, key, 0);
        } else if (Array.isArray(item)) {
          this.$set(this.pagination, key, []);
        } else {
          this.$set(this.pagination, key, null);
        }
      }

      this.getItems();
    },
  },
  components: {
    Modal,
    ButtonWithLoader,
    AnalyticsContainerWrapper,
    FilterWrapper,
  },
};
</script>
