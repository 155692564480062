<template>
  <card class="!p-0 relative">
    <div class="flex flex-col min-h-full">
      <img
        class="w-full h-full object-cover flex-1"
        :src="product.image || require('@/assets/images/placeholder.png')"
        @error="$event.target.src = require('@/assets/images/placeholder.png')"
        :alt="product['name_' + $i18n.locale]"
      />
      <div class="p-4 flex gap-4 items-start">
        <div class="flex-1">
          <p class="font-semibold text-sm">
            <span v-if="hasDiscount && product.discount_price"
              >{{ generateCurrency(product.discount_price || 0) }}
            </span>
            <span
              :class="
                hasDiscount && product.discount_price
                  ? 'text-red-500 line-through'
                  : undefined
              "
              >{{ generateCurrency(product.price || 0) }}</span
            >
          </p>
          <p class="text-sm line-clamp-1">
            {{ product["name_" + $i18n.locale] }}
          </p>
        </div>
        <button
          type="button"
          class="flex items-center justify-center bg-primary text-white text-sm rounded-full shrink-0 w-8 h-8"
          v-tippy
          content="Add to cart"
          @click.prevent="pushProduct"
        >
          <i class="fa-solid fa-cart-plus"></i>
        </button>
      </div>
    </div>

    <p
      class="absolute top-0 -m-2 py-1.5 px-3 rounded-full bg-red-500 text-xs text-white z-[5] font-semibold transition ring-2 ring-white"
      v-if="hasDiscount && product.discount_price"
    >
      {{ discountPercentage }}% discount
    </p>
    <div
      class="w-full h-full absolute inset-0 bg-gradient-to-t from-white z-10 flex items-end justify-center text-center p-6 !m-0"
      v-if="product.tracking_stock ? product.stock <= 0 : false"
    >
      <p class="text-sm text-red-500 font-semibold">NOT AVAILABLE</p>
    </div>
  </card>
</template>

<script>
import { mapMutations } from "vuex";
import Card from "../Card.vue";
export default {
  components: { Card },
  name: "ProductCard",
  props: {
    product: { type: Object, default: {} },
  },
  methods: {
    ...mapMutations("pos_system", ["addNewProduct"]),
    pushProduct() {
      this.addNewProduct(this.product);
    },
  },
  computed: {
    discountPercentage() {
      const { discount_value, price } = this.product || {};

      return parseFloat(((discount_value / price) * 100).toFixed(2));
    },
    hasDiscount() {
      const { discount_end_date, discount_start_date } = this.product || {};
      const startDate = new Date(discount_start_date).getTime();
      const endDate = new Date(discount_end_date).getTime();
      const today = new Date().getTime();

      if (!(discount_end_date && discount_start_date)) return;

      return today > startDate && today <= endDate;
    },
  },
};
</script>
