<template>
  <div>
    <wrapper>
      <form class="w-full max-w-xl mx-auto space-y-4 mt-10">
        <div class="w-full max-w-[6rem] mx-auto">
          <img
            src="@/assets/images/logo.jpg"
            alt="Auth logo"
            class="w-full h-auto"
          />
        </div>
        <figure v-if="hasCode">
          <h1 class="text-primary text-4xl capitalize text-center font-bold">
            Verification code
          </h1>
          <p
            class="text-gray-600 text-center font-medium rtl:font-semibold text-sm my-3"
          >
            Type your verification code that sent via email address to reset
            your account password.
          </p>
          <p class="text-red-500 font-semibold text-center" v-if="error">
            {{ error }}
          </p>
          <div class="w-full space-y-3 !my-10">
            <div class="grid grid-cols-6 gap-4">
              <div class="w-full form-group" v-for="i in 6" :key="i">
                <input
                  type="number"
                  :name="`code_${i}`"
                  :id="`code_${i}`"
                  min="1"
                  max="1"
                  minlength="1"
                  maxlength="1"
                  required
                  placeholder="0"
                  class="text-center"
                  v-one
                />
              </div>
            </div>
            <span class="error">
              {{ (add_errors.code || []).join(" ") }}
            </span>
          </div>
          <button-with-loader
            btnClass="w-full py-3 px-4 rounded bg-primary
          text-white"
            :disabled="check_disabled"
            @click="checkCode"
            >Check code
          </button-with-loader>
        </figure>
        <figure v-else class="space-y-4">
          <h1 class="text-primary text-4xl capitalize text-center font-bold">
            Reset password
          </h1>

          <div class="w-full space-y-3">
            <p class="text-red-500 font-semibold text-center" v-if="error">
              {{ error }}
            </p>
            <label
              for="password"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              New password
            </label>
            <div class="relative">
              <input
                type="password"
                name="password"
                id="password"
                placeholder="••••••••"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium pr-12 rtl:pr-4 rtl:pl-4 rounded text-sm"
                autocomplete="off"
                v-model="add_data.password"
              />
              <button
                class="w-12 h-full flex items-center justify-center text-gray-500 text-sm absolute top-0 right-0 rtl:left-0 rtl:right-auto"
                type="button"
                @click.prevent="textPasswordType"
              >
                <i class="fa-solid fa-eye"></i>
              </button>
            </div>

            <span class="error">
              {{ (add_errors.password || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="password_confirmation"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Confirmation password
            </label>
            <div class="relative">
              <input
                type="password"
                name="password_confirmation"
                id="password_confirmation"
                placeholder="••••••••"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium pr-12 rtl:pr-4 rtl:pl-4 rounded text-sm"
                autocomplete="off"
                v-model="add_data.password_confirmation"
              />
              <button
                class="w-12 h-full flex items-center justify-center text-gray-500 text-sm absolute top-0 right-0 rtl:left-0 rtl:right-auto"
                type="button"
                @click.prevent="textPasswordType"
              >
                <i class="fa-solid fa-eye"></i>
              </button>
            </div>

            <span class="error">
              {{ (add_errors.password_confirmation || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-4">
            <button-with-loader
              btnClass="w-full py-3 px-4 rounded bg-primary
          text-white"
              :disabled="disabled"
              @click="resetPassword"
              >Reset password</button-with-loader
            >
          </div>
        </figure>
      </form>
    </wrapper>
  </div>
</template>
<script>
import Wrapper from "@/components/auth/Wrapper.vue";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
export default {
  components: { Wrapper, ButtonWithLoader },
  name: "ResetPassword",
  data() {
    return {
      disabled: false,
      add_data: {
        // code: null,
      },
      add_errors: {},
      error: "",
      hasCode: true,
      check_disabled: false,
    };
  },
  directives: {
    one: {
      bind(el, _, vNode) {
        el.addEventListener("input", (e) => {
          const value = e.target.value;
          const idCode = parseInt(e.target.id?.replace(/code_/gi, ""));

          if (!value) return;
          if (value.length >= 1) {
            const lastItem = value[value.length - 1];
            if (!/^\d+$/g.test(lastItem)) {
              e.target.value = null;
              return;
            }
            e.target.value = lastItem;
          }

          if (idCode === 6) return;
          document.querySelector(`#code_${idCode + 1}`).focus();
        });

        //Dealing with the code during the pasta
        el.addEventListener("paste", (e) => {
          e.stopPropagation();
          e.preventDefault();
          const clipboard = e.clipboardData || window.clipboardData;
          const data = clipboard?.getData("text");
          const elem = e.target;

          if (!clipboard) {
            elem.value = null;
            return;
          }
          elem.value = "0";
          if (!/^\d+$/g.test(data)) {
            vNode.context.createAlert(
              "Verification code should be number",
              "info"
            );
            elem.value = null;
            return;
          }

          for (const index in Array(6).fill()) {
            const input = document.querySelector(
              `#code_${parseInt(index) + 1}`
            );
            if (!input) return;
            input.value = data[index];
          }
        });
      },
    },
  },
  methods: {
    async resetPassword() {
      this.disabled = true;

      try {
        const { data } = await this.axios.post(
          "reset_password/save",
          this.add_data
        );
        this.add_data = {};
        localStorage.removeItem("email");
        this.createAlert(data.message);

        this.$router.push("/account/login");
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.disabled = false;
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
    async checkCode() {
      this.check_disabled = true;
      this.error = null;
      this.add_errors = {};
      try {
        const code = await this.getVerificationCodeFromInput();
        const { data } = await this.axios.post("reset_password/save", {
          code,
          email: localStorage.getItem("email"),
        });
        this.add_data = {
          code,
          email: localStorage.getItem("email"),
          password: null,
          password_confirmation: null,
        };
        this.createAlert(data.message);

        this.hasCode = false;
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.check_disabled = false;
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
    getVerificationCodeFromInput() {
      return new Promise((resolve) => {
        let result = [];
        for (const index in Array(6).fill()) {
          const input = document.querySelector(`#code_${parseInt(index) + 1}`);
          if (!input) return;
          result.push(input.value);
        }
        resolve(result?.join(""));
      });
    },
  },
};
</script>
