<template>
  <div class="p-6">
    <!-- <container-wrapper :component-name="component" @change="component = $event">
      <component :is="component" />
    </container-wrapper> -->

    <ul class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
      <li class="col-span-full">
        <p class="text-sm font-medium text-gray-600">
          {{ $t("general-settings") }}
        </p>
      </li>
      <li v-for="(page, index) in pages" :key="index">
        <PagesCard :page="page" />
      </li>
      <li class="col-span-full">
        <p class="text-sm font-medium text-gray-600">
          {{ $t("inventory-settings") }}
        </p>
      </li>
      <li v-for="(page, index) in inventoryPages" :key="index">
        <PagesCard :page="page" />
      </li>
      <li class="col-span-full">
        <p class="text-sm font-medium text-gray-600">
          {{ $t("advanced-settings") }}
        </p>
      </li>
      <li v-for="(page, index) in advancedPages" :key="index">
        <PagesCard :page="page" />
      </li>
    </ul>
  </div>
</template>
<script>
import PagesCard from "@/components/PagesCard.vue";
import ContainerWrapper from "@/components/settings/ContainerWrapper.vue";
import DiscountSettings from "@/components/settings/DiscountSettings.vue";
import PersonalAccount from "@/components/settings/PersonalAccount.vue";
import ResetFactory from "@/components/settings/ResetFactory.vue";
export default {
  components: {
    PersonalAccount,
    DiscountSettings,
    ResetFactory,
    ContainerWrapper,
    PagesCard,
  },
  data() {
    return {
      component: "PersonalAccount",
    };
  },
  computed: {
    pages() {
      const items = [
        {
          title: this.$t("personal-info"),
          content: this.$t("personal-info-content"),
          icon: "fa-solid fa-user",
          url: "/settings/profile",
          style: "bg-white text-black",
        },
        {
          title: this.$t("store-info"),
          content: this.$t("store-info-content"),
          icon: "fa-solid fa-store",
          url: "/settings/store-information",
          style: "bg-white text-black",
        },

        {
          title: this.$t("users"),
          content: this.$t("users-content"),
          icon: "fa-solid fa-user-group",
          url: "/settings/users",
          style: "bg-white text-black",
          permissions: "show users",
        },
      ];
      return this.filterPagesBasedOnPermissions(items, []);
    },
    inventoryPages() {
      const items = [
        {
          title: this.$t("inventory"),
          content: this.$t("inventory-content"),

          icon: "fa-solid fa-tags",
          url: "/inventory-setup",
          style: "bg-white text-black",
          permissions: "show inventory",
        },

        {
          title: this.$t("categorizations"),
          content: this.$t("categorizations-content"),
          icon: "fa-solid fa-cubes",
          url: "/settings/categorizations",
          style: "bg-white text-black",
          permissions: "show categories_group",
        },

        {
          title: this.$t("brands"),
          content: this.$t("brands-content"),

          icon: "fa-solid fa-tags",
          url: "/settings/brands",
          style: "bg-white text-black",
          permissions: "show brands",
        },
        {
          title: this.$t("wastages"),
          content: this.$t("wastages-content"),

          icon: "fa-solid fa-scale-balanced",
          url: "/settings/wastages",
          style: "bg-white text-black",
          permissions: "show wastages",
        },
        {
          title: this.$t("prices-list"),
          content: this.$t("prices-list-content"),

          icon: "fa-solid fa-list",
          url: "/settings/prices-list",
          style: "bg-white text-black",
          permissions: "show prices_list",
        },
      ];
      return this.filterPagesBasedOnPermissions(items, []);
    },
    advancedPages() {
      const items = [
        {
          title: this.$t("devices"),
          content: this.$t("devices-content"),

          icon: "fa-solid fa-display",
          url: "/settings/devices",
          style: "bg-white text-black",
          permissions: "show pos_devices",
        },

        {
          title: this.$t("receipts"),
          content: this.$t("receipts-content"),

          icon: "fa-solid fa-receipt",
          url: "/settings/receipt",
          style: "bg-white text-black",
          permissions: "show receipts",
        },
        {
          title: this.$t("taxes"),
          content: this.$t("taxes-content"),

          icon: "fa-solid fa-percent",
          url: "/settings/taxes",
          style: "bg-white text-black",
          permissions: "show taxes",
        },

        {
          title: this.$t("safe-track"),
          content: this.$t("safe-track-content"),

          icon: "fa-solid fa-shuffle",
          url: "/settings/safe-track",
          style: "bg-white text-black",
          permissions: "show safe_track",
        },
        {
          title: this.$t("email-config"),
          content: this.$t("email-config-content"),

          icon: "fa-solid fa-envelope",
          url: "/settings/email-configuration",
          style: "bg-white text-black",
          permissions: "show email_configuration",
        },
        {
          title: this.$t("roles"),
          content: this.$t("roles-content"),

          icon: "fa-solid fa-shield-halved",
          url: "/settings/roles",
          style: "bg-white text-black",
          permissions: "show roles",
        },
      ];
      return this.filterPagesBasedOnPermissions(items, []);
    },
  },
  methods: {
    filterPagesBasedOnPermissions(items, restItems) {
      if (["vendor", "wholesaler"].includes(this.userType)) {
        return new Array().concat(restItems, items);
      } else if (this.userType === "vendor_user") {
        return items
          .filter((ev) => ev.permissions !== "users")
          .filter((e) =>
            e.permissions ? this.hasPermission(e.permissions) : true
          );
      } else {
        return items;
      }
    },
  },
  name: "SettingsPage",
};
</script>
