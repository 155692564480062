<template>
  <div>
    <Modal
      title="Update products values"
      size="!max-w-screen-xl"
      v-if="active"
      @close="hide"
    >
      <div class="space-y-6 p-6">
        <div class="space-y-1">
          <p class="text-lg font-semibold text-gray-800">Products scale</p>
          <p class="text-sm text-gray-500">
            Choose the product from the side menu so that the product is
            displayed in the attached quantities within the product, then enter
            the real values
          </p>
        </div>
        <div class="flex gap-4 items-start">
          <div class="flex-1 max-w-xs">
            <ul class="space-y-2">
              <li v-for="product in item.products" :key="product.id">
                <input
                  type="radio"
                  hidden
                  name="products-group"
                  :id="'approve-update-product-' + product.id"
                  class="peer"
                  :value="product.id"
                  v-model="product_id"
                  @change="onProductSelect"
                />
                <label
                  :for="'approve-update-product-' + product.id"
                  class="flex gap-2 py-2 px-3 rounded-lg bg-gray-50 text-gray-600 text-sm font-medium items-center cursor-pointer border transition peer-checked:border-primary peer-checked:bg-primary/5"
                >
                  <img
                    :src="
                      product.image ||
                      require('@/assets/images/placeholder.png')
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    class="w-8 h-8 rounded-full object-cover"
                    alt="product image"
                  />
                  <div>
                    <p
                      class="text-sm font-medium text-gray-800 max-w-[15rem] line-clamp-2"
                    >
                      {{ product["name_" + $i18n.locale] }}
                    </p>
                    <p class="text-xs text-gray-500">
                      <span v-if="product.unit_type === 'weight'">
                        {{ GenerateUnit(product.weight || 0) }}</span
                      >
                      <span v-else>{{ product.qty || 0 }} Piece</span>
                    </p>
                  </div>
                </label>
              </li>
            </ul>
          </div>
          <div class="flex-1 grid overflow-x-auto">
            <table class="styled-table border border-gray-200">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product</th>
                  <th>Unit price</th>
                  <th>
                    {{ unit_type === "weight" ? "Weight (KG)" : "Quantity" }}
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-show="!products.length">
                  <td colspan="100%">
                    <p class="text-sm text-center text-gray-600 font-medium">
                      No products added
                    </p>
                  </td>
                </tr>
                <tr v-for="(product, index) in products" :key="product.id">
                  <td>{{ index + 1 }}x</td>
                  <td>
                    <p
                      class="max-w-[15rem] min-w-[15rem] line-clamp-2 !whitespace-normal"
                    >
                      {{ product.product?.["name_" + $i18n.locale] }}
                    </p>
                  </td>
                  <td>
                    <p class="text-gray-800 font-semibold text-sm">
                      {{ product.product?.price }} SAR
                    </p>
                  </td>

                  <td>
                    <Controllable
                      v-model="product.weight"
                      v-if="product.product?.unit_type === 'weight'"
                    />
                    <Controllable v-model="product.qty" v-else />
                  </td>
                  <td>
                    <button
                      type="button"
                      class="py-2 px-3 rounded bg-red-500 text-white font-medium text-sm inline-flex items-center gap-2"
                      @click.prevent="deleteProduct(product.id)"
                    >
                      <i class="fa-solid fa-trash fa-sm"></i>
                      <span>Delete</span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td colspan="2"></td>
                  <td>
                    <p class="text-gray-800 font-semibold text-sm">
                      Total {{ getTotal.total }} SAR
                      <span
                        class="line-through text-red-500"
                        v-if="getTotal.total !== originPrice"
                        >{{ originPrice }} SAR</span
                      >
                    </p>
                  </td>
                  <td colspan="2">
                    <p
                      class="text-gray-800 font-semibold text-sm"
                      v-if="unit_type === 'weight'"
                    >
                      Total {{ GenerateUnit(getWeights) }}
                      <span
                        class="line-through text-red-500"
                        v-if="getWeights !== findProduct?.weight || 0"
                        >{{ findProduct?.weight || 0 }} SAR</span
                      >
                    </p>
                  </td>
                </tr>
                <tr>
                  <td colspan="2"></td>
                  <td>
                    <p class="text-gray-800 font-semibold text-sm">
                      Total price {{ getAllTotal.total }} SAR
                      <span
                        class="line-through text-red-500"
                        v-if="getAllTotal.total !== getAllTotal.origin"
                        >{{ getAllTotal.origin }} SAR</span
                      >
                    </p>
                  </td>
                  <td colspan="2"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <button
          type="button"
          class="py-2 px-3 rounded bg-primary text-white font-medium text-sm flex items-center gap-2 ms-auto"
          @click.prevent="updateValues"
          :disabled="add_disabled"
        >
          <i class="fa-solid fa-check fa-sm"></i>
          <span>Save changes</span>
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "../basics/Modal.vue";
import Controllable from "../Controllable.vue";
import Expanded from "../Expanded.vue";
import short from "short-uuid";

export default {
  name: "ApproveAndUpdateValues",
  components: { Modal, Controllable, Expanded },
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      products: [],
      products_list: [],
      add_disabled: false,
      add_errors: {},
      product_id: null,
      unit_type: "normal",
      active: false,
    };
  },
  mounted() {},
  watch: {
    products_list: {
      handler(val = []) {
        const products = this.products.map((e) => e.product_id);
        const id = [...new Set(products)];
        const filterProducts = val.filter((e) => e.product_id == id);
        this.products = filterProducts;
      },
      deep: true,
      immediate: true,
    },
    item: {
      handler(val) {
        const products = val?.products;
        const generated =
          products?.map((e) => this.generateProduct(e.id)) || [];
        const concat = new Array().concat(...generated);
        this.products_list = concat;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    getTotal() {
      const products = this.products;
      return this.collectTotal(products);
    },
    getAllTotal() {
      const products = this.products_list;
      return this.collectTotal(products);
    },
    originPrice() {
      let total = 0;
      const product = this.item?.products?.find((e) => e.id == this.product_id);
      if (!product) return 0;

      const { weight = 0, price = 0, qty = 0, unit_type } = product;

      total = price * (unit_type === "weight" ? weight : qty);
      return parseFloat((total || 0).toFixed(2));
    },
    findProduct() {
      return this.item.products?.find((e) => e.id == this.product_id);
    },
    getWeights() {
      let total = 0;
      const products = this.products;
      for (const product of products) {
        const { weight = 0 } = product;
        total += weight;
      }

      return total || 0;
    },
  },
  methods: {
    deleteProduct(id) {
      const filterProducts = this.products_list.filter((e) => e.id !== id);
      this.products_list = filterProducts;
    },
    onProductSelect(ev) {
      const { value } = ev.target;
      this.product_id = +value;
      const filterProducts = this.products_list.filter(
        (e) => e.product_id == value
      );
      const product = this.item.products.find((e) => e.id == value);
      this.unit_type = product.unit_type;
      this.products = filterProducts;
    },
    collectTotal(products = []) {
      let total = 0;
      let newTotal = 0;

      const originProducts = this.item?.products || [];

      for (const product of originProducts) {
        const { weight = 0, qty = 0, price = 0, unit_type } = product;

        if (unit_type === "weight") {
          total += price * weight;
          continue;
        }
        total += price * qty;
      }

      for (const product of products) {
        const { unit_type, price = 0 } = product?.product;
        const { weight = 0, qty = 0 } = product;

        if (unit_type === "weight") {
          newTotal += price * weight;
          continue;
        }
        newTotal += price * qty;
      }

      return {
        total: parseFloat(newTotal.toFixed(2)),
        origin: parseFloat(total.toFixed(2)),
      };
    },
    generateProduct(id) {
      const product = this.item.products.find((e) => e.id == id);
      if (!product) return this.createAlert("Product not found", "info");

      const {
        name_ar,
        name_en,
        unit_type,
        price = 0,
        qty = 0,
        weight = 0,
      } = product;

      const item = {
        product_id: id,
        product: {
          name_ar,
          name_en,
          unit_type,
          price,
          qty,
          ...(unit_type === "weight" && { weight }),
        },

        ...(unit_type === "weight" ? { weight: weight / qty } : { qty }),
      };

      const size = unit_type === "weight" ? qty : 1;

      const generateProducts = Array.from({ length: size })
        .fill(item)
        .map((e) => {
          const uuid = short.generate();
          return { ...e, id: uuid };
        });

      return generateProducts;
    },
    collectProducts() {
      let result = [];
      const products = this.item?.products || [];
      const list = this.products_list;

      const mapProducts = products.map((product) => {
        let weights = 0;
        let QTYs = 0;
        const { id, unit_type } = product;
        const items = list.filter((e) => e.product_id == id);

        for (const item of items) {
          const { weight = 0, qty = 0 } = item;
          if (unit_type === "weight") {
            weights += weight;
            continue;
          }
          QTYs += qty;
        }

        return {
          id: product.id,
          ...(unit_type === "weight"
            ? { weight: weights, qty: product.qty }
            : { qty: QTYs }),
        };
      });

      result = mapProducts;

      return result;
    },
    async updateValues() {
      try {
        this.add_disabled = true;
        const { id, status } = this.item;
        const products = this.collectProducts();
        const item = {
          order_id: id,
          products,
        };
        const pathname =
          status === "created"
            ? "orders/set_actual_values"
            : status === "received"
            ? "orders/save"
            : null;
        if (!pathname)
          return this.createAlert("Can not do this process in this status");

        const { data } = await this.axios.post(pathname, item);
        this.createAlert(data.message);
        window.location.reload();
      } catch (error) {
        const err = error?.response?.data;
        const message = err.message;
        if (message instanceof Object) {
          this.add_errors = message;
          return;
        }
        this.createAlert(message, "error");
      } finally {
        this.add_disabled = false;
      }
    },
    hide() {
      this.products = [];
      // this.products_list = [];
      this.add_disabled = false;
      this.add_errors = {};
      // this.product_id = null;
      // this.unit_type = "normal";
      this.active = false;
    },
    show() {
      this.active = true;
    },
  },
};
</script>
