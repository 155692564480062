<template>
  <div>
    <form class="w-full max-w-xl mx-auto space-y-4">
      <div class="w-full max-w-[6rem] mx-auto">
        <img
          src="@/assets/images/logo.jpg"
          alt="Auth logo"
          class="w-full h-auto"
        />
      </div>
      <h1 class="text-primary text-4xl capitalize text-center font-bold">
        Welcome to Nyqat portal.
      </h1>
      <p
        class="text-gray-600 text-center font-medium rtl:font-semibold text-sm"
      >
        Create an account and start your business
      </p>
      <div class="w-full space-y-3 !mt-10">
        <p class="text-red-500 font-semibold text-center" v-if="error">
          {{ error }}
        </p>
        <label
          for="name"
          class="font-medium rtl:font-semibold text-gray-600 text-sm"
        >
          Full name
        </label>
        <input
          type="text"
          name="name"
          id="name"
          placeholder="example@example.com"
          class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded text-sm"
          autocomplete="off"
          v-model="add_data.name"
        />
        <span class="error">
          {{ (add_errors.name || []).join(" ") }}
        </span>
      </div>
      <div class="w-full space-y-3">
        <label
          for="email"
          class="font-medium rtl:font-semibold text-gray-600 text-sm"
        >
          E-mail address
        </label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="example@example.com"
          class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded text-sm"
          autocomplete="off"
          v-model="add_data.email"
          v-email
        />
        <span class="error">
          {{ (add_errors.email || []).join(" ") }}
        </span>
      </div>
      <div class="w-full space-y-3">
        <label
          for="mobile"
          class="font-medium rtl:font-semibold text-gray-600 text-sm"
        >
          Mobile number
        </label>
        <vue-tel-input
          id="mobile"
          ref="customerMobile"
          v-model="add_data.mobile"
          @open="handleOpen('customerMobile')"
        ></vue-tel-input>
        <span class="error">
          {{ (add_errors.mobile || []).join(" ") }}
        </span>
      </div>
      <div class="w-full space-y-3">
        <label
          for="module"
          class="font-medium rtl:font-semibold text-gray-600 text-sm"
        >
          Module
        </label>
        <multiselect
          v-model="add_data.module_ids"
          :placeholder="'search'"
          :label="`name_${$i18n.locale}`"
          track-by="id"
          :options="modules"
          :hideSelected="true"
          :clearOnSelect="true"
          :disabled="modules.length === 0"
          multiple
        ></multiselect>
        <span class="error">
          {{ (add_errors.module_ids || []).join(" ") }}
        </span>
      </div>
      <div class="w-full space-y-3">
        <label
          for="password"
          class="font-medium rtl:font-semibold text-gray-600 text-sm"
        >
          Password
        </label>
        <div class="relative">
          <input
            type="password"
            name="password"
            id="password"
            placeholder="••••••••"
            class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium pr-12 rtl:pr-4 rtl:pl-4 rounded text-sm"
            autocomplete="off"
            v-model="add_data.password"
          />
          <button
            class="w-12 h-full flex items-center justify-center text-gray-500 text-sm absolute top-0 right-0 rtl:left-0 rtl:right-auto"
            type="button"
            @click.prevent="textPasswordType"
          >
            <i class="fa-solid fa-eye"></i>
          </button>
        </div>
        <router-link
          to="/account/forgot-password"
          class="text-primary underline text-sm my-2 table [margin-inline-start:auto]"
          >Forgot password?</router-link
        >
        <span class="error">
          {{ (add_errors.password || []).join(" ") }}
        </span>
      </div>
      <div class="w-full space-y-4">
        <button-with-loader
          btnClass="w-full py-3 px-4 rounded bg-primary
          text-white"
          :disabled="disabled"
          @click="register"
          >Create an account</button-with-loader
        >
        <p
          class="text-gray-600 text-center font-medium rtl:font-semibold text-sm"
        >
          Already have an account?
          <router-link to="/account/login" class="text-primary underline">
            Login!
          </router-link>
        </p>
      </div>
    </form>
  </div>
</template>
<script>
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: { ButtonWithLoader },
  name: "SupplierRegister",
  props: {
    user_type: {
      type: String,
      default: "supplier",
    },
  },
  data() {
    return {
      disabled: false,
      add_data: {
        email: "",
        module_ids: [],
        user_type: this.user_type,
      },
      add_errors: {},
      error: "",
      modules: [],
    };
  },
  created() {
    this.getModules();
  },
  computed: {
    ...mapGetters("Cart", ["cart"]),
    ...mapGetters("warehouseCart", ["wareCart"]),
  },
  methods: {
    ...mapMutations("root", ["SET_HANDOVER"]),
    ...mapMutations("Cart", ["GENERATE_CART"]),
    ...mapMutations("warehouseCart", ["GENERATE_WARE_CART"]),
    ...mapActions("getting", ["getRoute"]),
    async getModules() {
      try {
        const { result } = await this.getRoute("modules");
        this.modules = result.modules;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async register() {
      this.disabled = true;
      let loginData = {
        ...this.add_data,
        mobile: this.add_data.mobile?.replace(/[+|\s|()|-]/g, ""),
        module_ids: this.add_data?.module_ids?.map((e) => e.id),
      };
      try {
        const { data } = await this.axios.post("register", loginData);
        this.add_data = {
          module_ids: [],
        };
        this.$router.push("/account/login");
        this.createAlert(data.message);
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.disabled = false;
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
    changeRoute() {
      // console.log('change route from login component..')
      this.$emit("action", "register");
    },
  },
};
</script>
