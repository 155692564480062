<template>
  <div>
    <SubPagesHeader :center="false">
      <div class="mx-auto table" v-if="loaded">
        <spinner />
      </div>
      <section v-else>
        <div
          class="flex flex-col gap-4 md:hidden py-20 items-center justify-center"
        >
          <div
            class="w-20 h-20 rounded-full bg-gray-100 text-gray-500 flex items-center justify-center"
          >
            <i class="fa-solid fa-text-slash fa-xl"></i>
          </div>
          <div class="space-y-1 text-center max-w-screen-sm">
            <p class="text-base text-gray-600">
              The screen size is not sufficient for use to complete the process
              of adding the product, the size must be greater than 768 pixels in
              order to be able to add
            </p>
          </div>
        </div>
        <div class="space-y-4 hidden md:block">
          <slide-transition>
            <div class="space-y-4">
              <card title="Basic information">
                <template #actions>
                  <p
                    class="py-2 px-3 text-blue-600 bg-blue-50 border border-blue-200 text-xs rounded"
                  >
                    {{ productTypeTitle }}
                  </p>
                </template>
                <figure class="space-y-4">
                  <p class="text-sm font-medium rtl:font-semibold">Image</p>
                  <slide-transition>
                    <figcaption
                      v-if="basic_product_data?.image"
                      class="relative table"
                    >
                      <img
                        :src="
                          basic_product_data?.image
                            ? typeof basic_product_data?.image  == 'object'
                              ? convertImage(basic_product_data?.image)
                              : require('@/assets/images/placeholder.png')
                            : require('@/assets/images/placeholder.png')
                        "
                        @error="
                          (e) =>
                            (e.target.src = require('@/assets/images/placeholder.png'))
                        "
                        alt="store image"
                        class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
                      />
                      <button
                        class="w-6 h-6 rounded-full flex items-center justify-center bg-red-500 text-white absolute -top-2 -right-2 rtl:right-auto rtl:-left-2 ring-2 ring-white"
                        type="button"
                        @click.prevent="
                          () => clearImage('basic_product_data', 'uploader')
                        "
                        v-if="
                          typeof basic_product_data?.image  == 'object'
                        "
                      >
                        <i class="fa-solid fa-xmark fa-sm"></i>
                      </button>
                    </figcaption>
                  </slide-transition>
                  <blockquote class="space-y-2">
                    <label class="block">
                      <span class="sr-only">Choose image</span>
                      <input
                        type="file"
                        accept="image/*"
                        class="custom-file"
                        ref="uploader"
                        @change="
                          (e) =>
                            $set(basic_product_data, 'image', e.target.files[0])
                        "
                      />
                    </label>
                    <p class="error" v-if="add_errors.image">
                      {{ add_errors.image.join(" ") }}
                    </p>
                  </blockquote>
                </figure>
                <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <div
                    class="col-span-full grid grid-cols-1 md:grid-cols-2 gap-3"
                  >
                    <div
                      class="form-group-with-check"
                      v-if="
                        add_data.unit_type === 'weight' &&
                        product_type !== 'bundle'
                      "
                    >
                      <div>
                        <input
                          type="checkbox"
                          id="unit_type"
                          name="unit_type"
                          v-model="add_data.unit_type"
                          true-value="weight"
                          false-value="normal"
                          :disabled="add_data.unit_type === 'weight'"
                        />
                      </div>
                      <label for="unit_type">
                        <p class="font-semibold text-base">
                          Product by weight?
                        </p>
                        <p class="font-medium text-gray-500 text-sm">
                          The product will be of a weight type
                        </p>
                      </label>
                    </div>
                    <div
                      class="form-group-with-check"
                      v-if="product_type !== 'bundle'"
                    >
                      <div>
                        <input
                          type="checkbox"
                          id="tracking_stock"
                          name="tracking_stock"
                          v-model="basic_product_data.tracking_stock"
                          :true-value="1"
                          :false-value="0"
                        />
                      </div>
                      <label for="tracking_stock">
                        <p class="font-semibold text-base">
                          <!-- Disable inventory management -->
                          Limited stock
                        </p>
                        <p class="font-medium text-gray-500 text-sm">
                          It will be tracked through the quantity or weight
                          added during purchasing and selling operations
                        </p>
                      </label>
                    </div>
                    <div
                      class="form-group-with-check"
                      v-if="product_type !== 'bundle'"
                    >
                      <div>
                        <input
                          type="checkbox"
                          id="is_purchasable"
                          name="is_purchasable"
                          v-model="basic_product_data.is_purchasable"
                          :true-value="1"
                          :false-value="0"
                        />
                      </div>
                      <label for="is_purchasable">
                        <p class="font-semibold text-base">Purchasable</p>
                        <p class="font-medium text-gray-500 text-sm">
                          Prices and item code are required since you want to
                          make it purchasable.
                        </p>
                      </label>
                    </div>
                    <div class="form-group-with-check">
                      <div>
                        <input
                          type="checkbox"
                          id="is_sellable"
                          name="is_sellable"
                          v-model="basic_product_data.is_sellable"
                          :true-value="1"
                          :false-value="0"
                          :disabled="product_type === 'bundle'"
                        />
                      </div>
                      <label for="is_sellable">
                        <p class="font-semibold text-base">Sellable</p>
                        <p class="font-medium text-gray-500 text-sm">
                          The product will be shown under Products
                        </p>
                      </label>
                    </div>
                    <div class="form-group-with-check">
                      <div>
                        <input
                          type="checkbox"
                          id="cost_price_includes_vat"
                          name="cost_price_includes_vat"
                          v-model="basic_product_data.cost_price_includes_vat"
                          :true-value="1"
                          :false-value="0"
                        />
                      </div>
                      <label for="cost_price_includes_vat">
                        <p class="font-semibold text-base">
                          Cost prices includes VAT
                        </p>
                        <p class="font-medium text-gray-500 text-sm">
                          All prices include VAT, which is 15%.
                        </p>
                      </label>
                    </div>
                    <div class="form-group-with-check">
                      <div>
                        <input
                          type="checkbox"
                          id="sale_price_includes_vat"
                          name="sale_price_includes_vat"
                          v-model="basic_product_data.sale_price_includes_vat"
                          :true-value="1"
                          :false-value="0"
                        />
                      </div>
                      <label for="sale_price_includes_vat">
                        <p class="font-semibold text-base">
                          Sale prices includes VAT
                        </p>
                        <p class="font-medium text-gray-500 text-sm">
                          All prices include VAT, which is 15%.
                        </p>
                      </label>
                    </div>
                  </div>
                  <div class="form-group space-y-3 flex-1">
                    <label for="name_en"> Primary name</label>
                    <input
                      type="text"
                      name="name_en"
                      id="name_en"
                      v-model="basic_product_data.name_en"
                    />
                    <p class="error">
                      {{ (add_errors["name_en"] || []).join(" ") }}
                    </p>
                  </div>
                  <div class="form-group space-y-3 flex-1">
                    <label for="name_ar"> Secondary name </label>
                    <input
                      type="text"
                      name="name_ar"
                      id="name_ar"
                      v-model="basic_product_data.name_ar"
                    />
                    <p class="error">
                      {{ (add_errors["name_ar"] || []).join(" ") }}
                    </p>
                  </div>
                  <div class="form-group space-y-3 flex-1 col-span-full">
                    <label for="initial_stock"> Stock </label>
                    <input
                      type="text"
                      name="initial_stock"
                      id="initial_stock"
                      v-model="basic_product_data.initial_stock"
                      :disabled="!basic_product_data.tracking_stock"
                      :placeholder="
                        !!basic_product_data.tracking_stock
                          ? '0'
                          : 'Unlimited stock'
                      "
                    />
                    <p class="error">
                      {{ (add_errors["initial_stock"] || []).join(" ") }}
                    </p>
                  </div>

                  <!-- <div class="form-group space-y-3 flex-1">
                  <label for="desc_en"> Description | En </label>
                  <textarea
                    name="desc_en"
                    id="desc_en"
                    placeholder="..."
                    v-model="basic_product_data.desc_en"
                  ></textarea>
                  <p class="error">
                    {{ (add_errors["desc_en"] || []).join(" ") }}
                  </p>
                </div>
                <div class="form-group space-y-3 flex-1">
                  <label for="desc_ar"> Description | Ar </label>
                  <textarea
                    name="desc_ar"
                    id="desc_ar"
                    placeholder="..."
                    v-model="basic_product_data.desc_ar"
                  ></textarea>
                  <p class="error">
                    {{ (add_errors["desc_ar"] || []).join(" ") }}
                  </p>
                </div> -->

                  <div class="form-group space-y-3">
                    <label for="cost_price">
                      Cost price (
                      {{
                        generateCurrency(
                          collectPriceViaVat(
                            basic_product_data.cost_price,
                            !!basic_product_data.cost_price_includes_vat
                          )
                        )
                      }}
                      )</label
                    >
                    <input
                      type="number"
                      name="cost_price"
                      id="cost_price"
                      v-model.number="basic_product_data.cost_price"
                      step="any"
                      :placeholder="
                        !basic_product_data.is_purchasable
                          ? 'Allow product to be purchasable'
                          : '0.00'
                      "
                      :disabled="
                        product_type === 'bundle' ||
                        !basic_product_data.is_purchasable
                      "
                    />

                    <p class="error">
                      {{ (add_errors["cost_price"] || []).join(" ") }}
                    </p>
                  </div>
                  <div class="form-group space-y-3">
                    <label for="sale_price">
                      Sale price (
                      {{
                        generateCurrency(
                          collectPriceViaVat(
                            basic_product_data.sale_price,
                            !!basic_product_data.sale_price_includes_vat
                          )
                        )
                      }}
                      )</label
                    >
                    <input
                      type="number"
                      name="sale_price"
                      id="sale_price"
                      v-model.number="basic_product_data.sale_price"
                      :placeholder="
                        !basic_product_data.is_sellable
                          ? 'Allow product to be sellable'
                          : '0.00'
                      "
                      :disabled="!basic_product_data.is_sellable"
                    />

                    <p class="error">
                      {{ (add_errors["sale_price"] || []).join(" ") }}
                    </p>
                  </div>
                  <template v-if="product_type !== 'bundle'">
                    <div class="form-group space-y-3 flex-1">
                      <div class="flex items-center gap-4 justify-between">
                        <label for="sku"> SKU </label>
                        <a
                          href="javascript:void(0)"
                          class="text-teal-600 font-semibold flex items-center justify-start gap-2 text-xs"
                          @click="generateSKU"
                        >
                          <i class="fa-solid fa-sync fa-sm"></i>
                          <span>Generate</span>
                        </a>
                      </div>
                      <input
                        type="text"
                        name="sku"
                        id="sku"
                        v-model="basic_product_data.sku"
                        placeholder="..."
                      />
                      <p class="error">
                        {{ (add_errors["sku"] || []).join(" ") }}
                      </p>
                    </div>
                    <div
                      class="form-group space-y-3 flex-1"
                      v-if="add_data.unit_type === 'weight'"
                    >
                      <div class="flex items-center gap-4 justify-between">
                        <label for="item_code"> Item code </label>
                        <a
                          href="javascript:void(0)"
                          class="text-teal-600 font-semibold flex items-center justify-start gap-2 text-xs"
                          @click="generateItemCodeAndBarcode('item_code')"
                        >
                          <i class="fa-solid fa-sync fa-sm"></i>
                          <span>Generate</span>
                        </a>
                      </div>
                      <input
                        type="number"
                        name="item_code"
                        id="item_code"
                        v-model="basic_product_data.item_code"
                        placeholder="12345"
                      />
                      <p class="error">
                        {{ (add_errors["item_code"] || []).join(" ") }}
                      </p>
                    </div>
                    <div class="form-group space-y-3 flex-1" v-else>
                      <div class="flex items-center gap-4 justify-between">
                        <label for="barcode"> Barcode </label>
                        <a
                          href="javascript:void(0)"
                          class="text-teal-600 font-semibold flex items-center justify-start gap-2 text-xs"
                          @click="generateItemCodeAndBarcode('barcode')"
                        >
                          <i class="fa-solid fa-sync fa-sm"></i>
                          <span>Generate</span>
                        </a>
                      </div>
                      <input
                        type="text"
                        name="barcode"
                        id="barcode"
                        v-model="basic_product_data.barcode"
                        placeholder="..."
                      />
                      <p class="error">
                        {{ (add_errors["barcode"] || []).join(" ") }}
                      </p>
                    </div>
                  </template>

                  <div
                    class="form-group space-y-3 flex-1 col-span-full"
                    v-if="stores.length > 1"
                  >
                    <label for="selected_stores"> Available in stores </label>
                    <select-with-limit
                      :options="stores"
                      placeholder="Select"
                      :text="'name_' + $i18n.locale"
                      id="id"
                      v-model="selected_stores"
                    />
                    <p class="error" v-if="add_errors.sub_products">
                      {{ add_errors.sub_products.join(" ") }}
                    </p>
                  </div>
                </div>
              </card>

              <slide-transition>
                <card title="Options" v-show="product_type === 'variable'">
                  <template #actions>
                    <button
                      type="button"
                      class="py-2 px-3 text-blue-600 bg-blue-50 border border-blue-200 text-xs rounded"
                      @click.prevent="addNewOption"
                      v-if="
                        basic_product_data.name_ar || basic_product_data.name_en
                      "
                    >
                      New option
                    </button>
                  </template>
                  <ul class="space-y-4">
                    <li v-for="(option, index) in options" :key="index">
                      <div class="grid grid-cols-1 sm:grid-cols-2 gap-3">
                        <div class="form-group space-y-3">
                          <label for="name_en"> Primary name</label>
                          <input
                            type="text"
                            name="name_en"
                            id="name_en"
                            v-model="option.name_en"
                          />
                          <p class="error">
                            {{
                              (
                                add_errors["options." + index + ".name_en"] ||
                                []
                              ).join(" ")
                            }}
                          </p>
                        </div>
                        <div class="form-group space-y-3">
                          <label for="name_ar"> Secondary name </label>
                          <div class="flex items-center gap-3">
                            <input
                              type="text"
                              name="name_ar"
                              id="name_ar"
                              v-model="option.name_ar"
                            />
                            <button
                              type="button"
                              class="w-8 h-8 rounded bg-red-100 text-red-600 border border-red-200 items-center justify-center flex"
                              @click.prevent="removeOption(option.id)"
                            >
                              <i class="fa-solid fa-trash fa-xs"></i>
                            </button>
                          </div>
                          <p class="error">
                            {{
                              (
                                add_errors["options." + index + ".name_ar"] ||
                                []
                              ).join(" ")
                            }}
                          </p>
                        </div>

                        <div class="col-span-full">
                          <Expanded
                            :title="'Values ( ' + option.values?.length + ' )'"
                          >
                            <template #action>
                              <button
                                type="button"
                                class="py-2 px-3 text-blue-600 bg-blue-50 border border-blue-200 text-xs rounded"
                                @click.prevent="addNewValue(index)"
                              >
                                New value
                              </button>
                            </template>
                            <ul class="space-y-2">
                              <li
                                v-for="(value, idx) in option.values"
                                :key="idx + index"
                              >
                                <div
                                  class="grid grid-cols-1 sm:grid-cols-2 gap-3 ps-4"
                                >
                                  <div class="form-group space-y-3">
                                    <label for="name_en"> Primary name</label>
                                    <input
                                      type="text"
                                      name="name_en"
                                      id="name_en"
                                      v-model="value.name_en"
                                    />
                                    <p class="error">
                                      {{
                                        (
                                          add_errors[
                                            "options." +
                                              index +
                                              ".values." +
                                              idx +
                                              ".name_en"
                                          ] || []
                                        ).join(" ")
                                      }}
                                    </p>
                                  </div>
                                  <div class="form-group space-y-3">
                                    <label for="name_ar">
                                      Secondary name
                                    </label>
                                    <div class="flex items-center gap-3">
                                      <input
                                        type="text"
                                        name="name_ar"
                                        id="name_ar"
                                        v-model="value.name_ar"
                                        class="shrink-0 flex-1"
                                      />
                                      <button
                                        type="button"
                                        class="w-8 h-8 rounded bg-red-100 text-red-600 border border-red-200 items-center justify-center flex"
                                        @click.prevent="removeValue(index, idx)"
                                      >
                                        <i class="fa-solid fa-trash fa-xs"></i>
                                      </button>
                                    </div>
                                    <p class="error">
                                      {{
                                        (
                                          add_errors[
                                            "options." +
                                              index +
                                              ".values." +
                                              idx +
                                              ".name_en"
                                          ] || []
                                        ).join(" ")
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </li>
                            </ul>

                            <p
                              class="text-sm text-center text-gray-600"
                              v-if="!option.values.length"
                            >
                              There are no values added, press on new value
                              button above to add new one.
                            </p>
                            <p class="error text-center">
                              {{
                                (
                                  add_errors["options." + index + ".values"] ||
                                  []
                                ).join(" ")
                              }}
                            </p>
                          </Expanded>
                        </div>
                      </div>
                    </li>

                    <li v-if="!options.length">
                      <p class="text-sm text-center text-gray-600">
                        There are no options added, press on new option button
                        above to add new one.
                      </p>
                    </li>
                    <p class="error text-center">
                      {{ (add_errors["options"] || []).join(" ") }}
                    </p>
                  </ul>
                </card>
              </slide-transition>

              <slide-transition>
                <Card
                  title="Bundle products"
                  v-show="product_type === 'bundle'"
                >
                  <template #actions>
                    <p class="text-sm font-semibold">
                      {{ generateCurrency(productsPrice) }}
                    </p>
                  </template>
                  <template>
                    <div class="space-y-4">
                      <div class="form-group space-y-3">
                        <label for="product_id"> Products </label>
                        <t-rich-select
                          :fetch-options="getOtherProducts"
                          placeholder="Select"
                          :text-attribute="'name_' + $i18n.locale"
                          value-attribute="id"
                          :clearable="true"
                          :closeOnSelect="false"
                          v-model="product_id"
                          @change="handleOtherProductsChange"
                        />
                      </div>
                      <ul
                        class="divide-y divide-gray-200"
                        v-show="add_data.products_of_bundle?.length"
                      >
                        <li
                          v-for="(
                            product, index
                          ) in add_data.products_of_bundle"
                          :key="index"
                          class="py-3 first:pt-0 last:pb-0"
                        >
                          <div class="flex items-center gap-4">
                            <button
                              type="button"
                              @click.prevent="removeBundleProduct(index)"
                            >
                              <i
                                class="fa-solid fa-trash fa-sm text-red-500"
                              ></i>
                            </button>
                            <div class="inline-flex gap-2 items-center flex-1">
                              <img
                                :src="
                                  product.image ||
                                  require('@/assets/images/placeholder.png')
                                "
                                @error="
                                  (e) =>
                                    (e.target.src = require('@/assets/images/placeholder.png'))
                                "
                                alt="product name"
                                class="w-8 h-8 rounded-full object-cover shrink-0"
                              />
                              <div class="flex-1">
                                <p class="text-sm line-clamp-1 text-gray-600">
                                  {{ product["name_" + $i18n.locale] }}
                                </p>
                                <p class="text-xs text-gray-400">
                                  {{
                                    parseFloat((product.price || 0).toFixed(2))
                                  }}
                                  SAR
                                </p>
                              </div>
                            </div>
                            <div class="shrink-0">
                              <Controllable
                                v-model="product.value"
                                v-if="product.unit_type === 'normal'"
                              />
                              <div class="form-group" v-else>
                                <input
                                  type="number"
                                  v-model.number="product.value"
                                  class="!text-center !px-0 !w-14 !h-auto"
                                  placeholder="0"
                                />
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </template>
                </Card>
              </slide-transition>

              <slide-transition>
                <card
                  title="Prices & Packages"
                  v-if="product_type !== 'bundle'"
                >
                  <div class="space-y-3">
                    <div
                      class="space-y-1"
                      v-if="
                        product_type === 'simple'
                          ? basic_product_data.name_ar ||
                            basic_product_data.name_en
                          : product_type === 'variable'
                          ? options.length
                          : false
                      "
                    >
                      <p class="font-semibold">Package sizes</p>
                      <p class="text-sm text-gray-500">
                        Package data will be listed based on the size of the
                        packages above
                      </p>
                    </div>
                    <v-tags
                      v-model="pack"
                      :tags="packages"
                      placeholder="ex: 5"
                      class="v-tags"
                      @tags-changed="(newTags) => (packages = newTags)"
                    />
                  </div>
                  <div class="grid overflow-auto">
                    <table class="styled-table border border-gray-200 rounded">
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>Name</th>
                          <th>SKU</th>
                          <th>
                            {{
                              add_data.unit_type === "weight"
                                ? "Item code"
                                : "Barcode"
                            }}
                          </th>
                          <th>Stock</th>
                          <th>Purchasable</th>
                          <th>Sale able</th>
                          <th>Cost price</th>
                          <th>Sale price</th>
                        </tr>
                      </thead>
                      <tbody class="align-baseline">
                        <tr
                          class="align-middle"
                          v-for="(item, index) in subProducts"
                          :key="item.id"
                        >
                          <td>
                            <ViewImageWithUploader
                              @delete="showImageUploader(item, 'imageUploader')"
                              :file="item.image"
                            />
                          </td>

                          <td>
                            <div class="form-group space-y-2">
                              <input
                                type="text"
                                class="text-sm !w-fit"
                                placeholder="Type name"
                                v-model="item.name"
                              />
                            </div>
                          </td>

                          <td>
                            <div class="form-group">
                              <div class="inline-flex gap-4 items-center">
                                <input
                                  type="text"
                                  class="text-sm !w-fit flex-1 shrink-0"
                                  placeholder="Type SKU"
                                  v-model="item.sku"
                                />
                                <a
                                  href="javascript:void(0)"
                                  @click="generateSubProductsSKU(index)"
                                  v-tippy
                                  content="Generate"
                                  class="shrink-0"
                                >
                                  <i class="fa-solid fa-sync fa-lg"></i>
                                </a>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <div class="inline-flex gap-4 items-center">
                                <input
                                  type="text"
                                  class="text-sm !w-fit flex-1 shrink-0"
                                  placeholder="Type item code"
                                  v-model="item.item_code"
                                  v-if="add_data.unit_type === 'weight'"
                                />
                                <input
                                  type="text"
                                  class="text-sm !w-fit flex-1 shrink-0"
                                  placeholder="Type barcode"
                                  v-model="item.barcode"
                                  v-else
                                />
                                <a
                                  href="javascript:void(0)"
                                  @click="generateSubProductsCode(index)"
                                  v-tippy
                                  content="Generate"
                                  class="shrink-0"
                                >
                                  <i class="fa-solid fa-sync fa-lg"></i>
                                </a>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="form-group space-y-2">
                              <div class="flex gap-4">
                                <input
                                  type="number"
                                  class="text-sm !w-fit"
                                  :placeholder="
                                    item.tracking_stock
                                      ? '0'
                                      : 'Unlimited stock'
                                  "
                                  v-model.number="item.initial_stock"
                                  :disabled="!item.tracking_stock"
                                />
                                <div class="form-group-with-check shrink-0">
                                  <input
                                    type="checkbox"
                                    data-type="switch"
                                    :true-value="1"
                                    :false-value="0"
                                    v-tippy
                                    content="Limited stock"
                                    v-model="item.tracking_stock"
                                  />
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="form-group-with-check">
                              <input
                                type="checkbox"
                                data-type="switch"
                                :true-value="1"
                                :false-value="0"
                                v-model="item.is_purchasable"
                                @change.prevent="
                                  item.cost_price = $event.target.checked
                                    ? item.cost_price
                                    : null
                                "
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-group-with-check">
                              <input
                                type="checkbox"
                                data-type="switch"
                                :true-value="1"
                                :false-value="0"
                                v-model="item.is_sellable"
                                @change.prevent="
                                  item.sale_price = $event.target.checked
                                    ? item.sale_price
                                    : null
                                "
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-group space-y-2">
                              <input
                                type="number"
                                class="text-sm !w-fit"
                                v-model.number="item.cost_price"
                                :placeholder="
                                  !item.is_purchasable
                                    ? 'Allow item to be purchasable'
                                    : '0.00'
                                "
                                :disabled="!item.is_purchasable"
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-group space-y-2">
                              <input
                                type="number"
                                class="text-sm !w-fit"
                                v-model.number="item.sale_price"
                                :placeholder="
                                  !item.is_sellable
                                    ? 'Allow item to be purchasable'
                                    : '0.00'
                                "
                                :disabled="!item.is_sellable"
                              />
                            </div>
                          </td>
                        </tr>

                        <tr v-if="!subProducts.length">
                          <td colspan="100%">
                            <p class="text-sm text-center text-gray-600">
                              There are no data to display
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <ImageUploader
                    ref="imageUploader"
                    @change="onUploaderChangeImage($event, 'subProducts')"
                  />
                </card>
              </slide-transition>

              <card title="Additional details">
                <div class="w-ful grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div class="form-group space-y-3">
                    <div class="flex items-center gap-4 justify-between">
                      <label for="category_id"> Category </label>
                      <!-- <router-link
                      to="/settings/categorizations/categories"
                      class="inline-flex gap-2 items-center text-sm text-teal-600 font-medium"
                    >
                      <i class="fa-solid fa-plus fa-xs align-middle"></i>
                      <span>New category</span>
                    </router-link> -->
                    </div>
                    <t-rich-select
                      :options="categories"
                      placeholder="Select"
                      :text-attribute="'name_' + $i18n.locale"
                      value-attribute="id"
                      :clearable="true"
                      :closeOnSelect="true"
                      v-model="add_data.category_id"
                    />
                    <p class="error">
                      {{ (add_errors["category_id"] || []).join(" ") }}
                    </p>
                  </div>
                  <div class="form-group space-y-3">
                    <div class="flex items-center gap-4 justify-between">
                      <label for="supplier"> Supplier </label>
                    </div>
                    <!-- <router-link
                      to="/settings/suppliers"
                      class="inline-flex gap-2 items-center text-sm text-teal-600 font-medium"
                    >
                      <i class="fa-solid fa-plus fa-xs align-middle"></i>
                      <span>New supplier</span>
                    </router-link> -->
                    <t-rich-select
                      :options="suppliers"
                      placeholder="Select"
                      text-attribute="name"
                      value-attribute="id"
                      :clearable="true"
                      :closeOnSelect="true"
                      v-model="add_data.supplier_id"
                    />
                    <p class="error">
                      {{ (add_errors["supplier_id"] || []).join(" ") }}
                    </p>
                  </div>
                </div>

                <div class="form-group space-y-3 col-span-full">
                  <div class="flex items-center gap-4 justify-between">
                    <label for="brand_id"> Brand </label>
                    <!-- <router-link
                      to="/settings/brands"
                      class="inline-flex gap-2 items-center text-sm text-teal-600 font-medium"
                    >
                      <i class="fa-solid fa-plus fa-xs align-middle"></i>
                      <span>New brand</span>
                    </router-link> -->
                  </div>
                  <t-rich-select
                    :options="brands"
                    placeholder="Select"
                    :text-attribute="'name_' + $i18n.locale"
                    value-attribute="id"
                    :clearable="true"
                    :closeOnSelect="true"
                    v-model="add_data.brand_id"
                  />
                  <p class="error">
                    {{ (add_errors["brand_id"] || []).join(" ") }}
                  </p>
                </div>
              </card>
            </div>
          </slide-transition>
        </div>
      </section>
      <template #action>
        <button-with-loader
          btnClass="w-auto py-2 px-4 rounded bg-primary
    text-white text-xs hidden md:flex"
          type="submit"
          @click="addProduct"
          :disabled="add_disabled"
          v-show="basic_product_data.name_ar || basic_product_data.name_en"
          >Save & create</button-with-loader
        >
      </template>
    </SubPagesHeader>
  </div>
</template>

<script>
import SubPagesHeader from "@/components/basics/SubPagesHeader.vue";
import Card from "@/components/Card.vue";
import Expanded from "@/components/Expanded.vue";
import Modal from "@/components/basics/Modal.vue";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import AddUpdatePackage from "@/components/Inventory/AddUpdatePackage.vue";
import AddUpdateOption from "@/components/Inventory/AddUpdateOption.vue";
import CardView from "@/components/Inventory/CardView.vue";
import { mapActions } from "vuex";
import Prices from "@/components/Inventory/Prices.vue";
import { serialize } from "object-to-formdata";
import ViewImageWithUploader from "@/components/Inventory/ViewImageWithUploader.vue";
import ImageUploader from "@/components/ImageUploader.vue";
import Controllable from "@/components/Controllable.vue";
import productMixin from "@/mixins/product";
import Tabs from "@/components/basics/Tabs.vue";
import { v4 as uuid } from "uuid";
import _ from "lodash";

export default {
  name: "NewUpdateProductPage",
  mixins: [productMixin],
  components: {
    SubPagesHeader,
    Card,
    Expanded,
    Modal,
    ButtonWithLoader,
    AddUpdatePackage,
    AddUpdateOption,
    CardView,
    Prices,
    ViewImageWithUploader,
    ImageUploader,
    Controllable,
    Tabs,
  },
  data() {
    return {
      loaded: true,
      add_data: {
        unit_type: "normal",
      },
      add_errors: {},
      add_error: {},
      add_disabled: false,
      types: [
        { title: "One product", content: "Normal product", key: "simple" },
        {
          title: "Product with options",
          content: "Product included custom options",
          key: "variable",
        },
        {
          title: "Bundle",
          content: "Add product with bundles",
          key: "bundle",
        },
      ],
      add_package_data: { image: null },
      add_package_active: false,
      add_package_errors: {},
      options: [],
      packages: [],
      option_packages: [],
      add_option_data: {},
      add_option_active: false,
      add_option_errors: {},
      add_price_data: {},
      add_price_active: false,
      add_price_errors: {},
      selected_package_item: {},
      units: ["normal", "weight"],
      selected_stores: [],
      stores: [],
      brands: [],
      suppliers: [],
      categories: [],
      add_disabled: false,
      basic_product_data: {
        stores: [],
        name_en: "1",
        name_ar: "1",
        type: "child",
      },
      change_image_data: {},
      options_with_basic_product: [],
      options_with_package: [],
      all_packages: [],
      product_type: "simple",
      same_prices: 0,
      product_id: null,
      bundle_products: [],
      id: this.$route.params.slug,
    };
  },

  async created() {
    await this.getProduct();
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),

    async getProduct() {
      try {
        const { result } = await this.getRoute({
          name: "products/" + this.id,
        });
        const product = result.product;
        const sub_products = product.sub_products?.map((e) => {
          const object = {
            ...e,
            id: uuid(),
            initial_stock: e.stock,
            name: e.name_ar || e.name_en,
          };

          return object;
        });

        this.selected_stores = product.stores?.map((e) => e.id);

        this.add_data = {
          ...product,
          brand_id: product.brand?.id,
          category_id: product.category?.id,
          supplier_id: product.supplier?.id,
        };
        const { stock: initial_stock, ...other } = _.pick(product, [
          "image",
          "name_ar",
          "name_en",
          "desc_ar",
          "desc_en",
          "sku",
          "barcode",
          "item_code",
          "cost_price",
          "cost_price_includes_vat",
          "sale_price",
          "sale_price_includes_vat",
          "id",
          "is_purchasable",
          "is_sellable",
          "tracking_stock",
          "service_fees_value",
          "service_fees_type",
          "stock",
        ]);
        // this.same_prices = 1;
        this.basic_product_data = {
          ...other,
          initial_stock,
        };

        this.product_type = product.product_type;

        this.add_data.products_of_bundle = product.products_of_bundle || [];

        setTimeout(() => {
          const packages = _.uniqBy(
            _.filter(sub_products, { type: "package" }),
            "package_size"
          );
          this.packages = packages.map((pack) => ({
            text: pack.package_size,
            id: pack.id,
          }));
          this.options = (product.options || []).map((opt) => {
            const values = opt.values.map((val) => ({
              ...val,
              id: uuid(),
            }));
            return {
              ...opt,
              id: uuid(),
              values,
            };
          });
        }, 500);
        setTimeout(() => {
          this.$set(this.$data, "subProducts", sub_products);
        }, 600);
      } catch ({ result, type }) {
        console.log(result);
        this.createAlert(result?.message, type);
        this.$router.push("/inventory-setup");
      }
    },

    // collectProductData() {
    //   return new Promise((resolve) => {
    //     let result = {};
    //     const data = this.add_data;
    //     const basic = this.basic_product_data;
    //     const isSamePrice = this.same_prices;
    //     const sub_products = this.allPackages
    //       .map((e) => (e.image instanceof File ? e : { ...e, image: null }))
    //       .map((e) => {
    //         if (isSamePrice) {
    //           // const firstPackage = arr[0];
    //           // const firstStore = firstPackage?.stores?.[0];
    //           const stores = e.stores.map((store, index, arr) => {
    //             if (index === 0) return store;

    //             const { id, ...others } = arr[0];

    //             return {
    //               ...store,
    //               ...others,
    //             };
    //           });
    //           return {
    //             ...e,
    //             stores,
    //           };
    //         }
    //         return e;
    //       });

    //     const options = this.options;
    //     result = {
    //       ...data,
    //       ...basic,
    //       ...(sub_products.length && { sub_products }),
    //       ...(options.length && { options }),
    //       product_type: this.product_type,
    //       image: basic.image instanceof File ? basic.image : null,
    //     };

    //     let fd = serialize(result, {
    //       indices: true,
    //       booleansAsIntegers: true,
    //       nullsAsUndefineds: true,
    //     });

    //     const keys = [];
    //     for (const key of fd.keys()) {
    //       keys.push(key);
    //     }

    //     const findType = keys.find((key) => /unit_type/gi.test(key));
    //     const unitType = fd.get(findType);
    //     const filterKeys = keys.filter((key) =>
    //       unitType === "weight"
    //         ? /barcode/gi.test(key)
    //         : /item_code/gi.test(key)
    //     );
    //     filterKeys.forEach((key) => fd.delete(key));

    //     console.log(unitType, filterKeys);

    //     resolve(fd);
    //   });
    // },

    async addProduct() {
      try {
        this.add_disabled = true;
        const product = await this.collectProductData();
        product.append("id", this.id);
        const { data } = await this.axios.post("products/update", product);
        this.createAlert(data.message);
        this.$router.push("/inventory-setup");
      } catch (error) {
        console.log(error);
        const err = error.response.data;
        const { result, message } = err;
        const isObject = message instanceof Object;
        if (isObject) {
          this.add_errors = message;
          return;
        }
        this.createAlert(message, "error");
      } finally {
        this.add_disabled = false;
      }
    },

    isImage(file) {
      return file instanceof File;
    },
  },
};
</script>

<style lang="scss" scoped></style>
