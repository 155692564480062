<template>
  <div>
    <div
      id="app"
      class="min-h-screen"
      :class="{
        'xl:flex flex-row justify-between': $store.state.token,
      }"
      v-if="
        ![
          'UpdateProduct',
          'CreateProduct',
          'NewJournalEntries',
          'ViewJournalEntryDetails',
          'UpdateJournalEntries',
          'POSSystem',
          'AddExpenses',
          'InvoiceTemplates',
          'NewOpeningBalances',
          'UpdateInventoryProduct',
          'CreateInventoryProduct',
          'CreateBundleProduct',
          'UpdateBundleProduct',
          'OrderDetails',
          'TransferOrderDetails',
          'RefundOrderDetails',
          'InvoiceDetails',
          'ItemProducts',
        ].includes($route.name)
      "
    >
      <side-bar
        v-if="
          $store.state.token &&
          !['Login', 'Register', 'ForgotPassword', 'ResetPassword'].includes(
            $route.name
          )
        "
        :active="active"
        ref="sideBar"
        @close="active = $event"
      />
      <div
        class="flex-1 sidebar-move"
        :class="{
          'pb-24': ![
            'Login',
            'Register',
            'ForgotPassword',
            'ResetPassword',
          ].includes($route.name),
        }"
      >
        <nav
          class="w-full p-6 bg-gray-50 sticky top-0 z-10"
          v-if="$store.state.token"
        >
          <ul class="list-none flex items-center gap-4">
            <li class="shrink-0 block xl:hidden">
              <button
                @click.prevent="openCloseSideBar"
                type="button"
                class="w-9 h-9 rounded-full bg-white flex items-center justify-center text-gray-600"
              >
                <i class="fa-solid fa-bars fa-md"></i>
              </button>
            </li>
            <li class="flex-1">
              <div class="space-y-1">
                <p class="text-xl font-bold text-gray-600 capitalize">
                  {{ $route.meta.title }}
                </p>

                <nav v-if="$breadcrumbs.length > 1" aria-label="breadcrumb">
                  <ul class="inline-flex items-center flex-wrap">
                    <li
                      v-for="(crumb, key) in $breadcrumbs"
                      :key="key"
                      class="breadcrumb-item"
                      aria-current="page"
                    >
                      <span
                        v-if="key !== 0"
                        class="text-sm font-medium text-gray-600 mx-0.5"
                      >
                        /
                      </span>
                      <router-link
                        :to="{ path: crumb.path }"
                        class="text-sm font-medium text-gray-500"
                        exact-active-class="!text-primary !font-semibold"
                        >{{
                          crumb.meta.breadcrumb.label
                            ? crumb.meta.breadcrumb.label
                            : crumb.name
                        }}</router-link
                      >
                      <!-- getBreadcrumb(crumb.meta.breadcrumb) -->
                    </li>
                  </ul>
                </nav>
              </div>
            </li>
            <li
              v-if="
                $route.query.location ||
                $route.query.from === 'incomes' ||
                [
                  'UpdateProduct',
                  'CreateProduct',
                  'NewJournalEntries',
                  'ViewJournalEntryDetails',
                  'UpdateJournalEntries',
                  'POSSystem',
                  'AddExpenses',
                  'InvoiceTemplates',
                  'NewOpeningBalances',
                  'UpdateInventoryProduct',
                  'CreateInventoryAlert',
                  'InvoiceDetails',
                  'ItemProducts',
                  'OrderDetails',
                  'TransferOrderDetails',
                  'RefundOrderDetails',
                  'SingleTicket',
                ].includes($route.name)
              "
            >
              <a
                href="javascript:void(0)"
                @click.prevent="$router.go(-1)"
                v-tippy
                content="Back"
                class="inline-flex items-center justify-center text-gray-600 gap-2 text-sm font-medium"
              >
                <span>{{
                  [
                    "UpdateProduct",
                    "CreateProduct",
                    "NewJournalEntries",
                    "ViewJournalEntryDetails",
                    "UpdateJournalEntries",
                    "POSSystem",
                    "AddExpenses",
                    "InvoiceTemplates",
                    "NewOpeningBalances",
                    "UpdateInventoryProduct",
                    "CreateInventoryAlert",
                  ].includes($route.name)
                    ? "Cancel"
                    : "Back"
                }}</span>
                <i class="fa-solid fa-angle-right rtl:hidden fa-md"></i>
                <i class="fa-solid fa-angle-right rtl:inline hidden fa-md"></i>
              </a>
            </li>
          </ul>
        </nav>
        <!-- banner -->
        <div class="p-6 pb-0" v-if="false">
          <div
            class="w-full rounded-xl bg-primary p-10 flex items-center justify-center gap-10 text-white"
          >
            <div class="flex-1">
              <h1 class="text-xl font-bold">
                {{ new Date() | handleDate }}, {{ getUser.name || "" }}
              </h1>
              <h4 class="font-bold">Welcome to your business</h4>
              <p class="text-sm font-medium rtl:font-semibold mt-2 mb-4">
                If your account has been activated, this means that you have
                started the launch steps, and in order to start those steps, you
                must first follow the following steps.
              </p>
              <ul class="list-none space-y-2">
                <li class="flex gap-3 items-center">
                  <span class="shrink-0">
                    <i class="fa-regular fa-circle"></i>
                  </span>
                  <p class="text-sm font-medium rtl:font-semibold">
                    Active your store and select it as primary one to start
                    tracking orders to link with the current package
                  </p>
                </li>
                <li class="flex gap-3 items-center">
                  <span class="shrink-0">
                    <i class="fa-regular fa-circle"></i>
                  </span>
                  <p class="text-sm font-medium rtl:font-semibold">
                    Create wastages or activate some of them to be added if
                    necessary
                  </p>
                </li>
                <li class="flex gap-3 items-center">
                  <span class="shrink-0">
                    <i class="fa-regular fa-circle"></i>
                  </span>
                  <p class="text-sm font-medium rtl:font-semibold">
                    Add some cashiers to create new orders
                  </p>
                </li>
                <li class="flex gap-3 items-center">
                  <span class="shrink-0">
                    <i class="fa-regular fa-circle"></i>
                  </span>
                  <p class="text-sm font-medium rtl:font-semibold">
                    Feed your store with the right products to start selling and
                    don't wait for the products to run out of stock
                  </p>
                </li>
              </ul>
              <p class="text-sm font-medium rtl:font-semibold mt-4 italic">
                " This alert is hidden if all steps are completed, the account
                is activated, and the store is linked to the current package "
              </p>
            </div>
            <!-- src="https://cdn3d.iconscout.com/3d/premium/thumb/rocket-launch-5520886-4608645.png" -->
            <img
              src="@/assets/images/launch-image-banner.png"
              alt="banner image"
              class="shrink-0 max-w-[16rem] flex-1 object-contain hidden md:block"
            />
          </div>
        </div>
        <!-- banner -->
        <!-- trial days card -->
        <div class="p-6 pb-0" v-if="isAfter">
          <figure class="w-full bg-white rounded ring-1 ring-gray-200">
            <blockquote class="p-6 space-y-2">
              <h1 class="text-xl font-bold">
                {{ new Date() | handleDate }}, {{ getUser.name || "" }}
              </h1>
              <h4 class="font-semibold text-gray-600">
                Welcome to your business
              </h4>
              <p class="font-semibold text-gray-600 text-sm">
                <vue-countdown
                  :time="day * 24 * 60 * 60 * 1000"
                  v-slot="{ days, hours }"
                  @start="getPercentage"
                  @progress="
                    (e) => {
                      getPercentage();
                      handleProgress(e);
                    }
                  "
                >
                  Time Remaining：{{ days }} days, {{ hours }} hours.
                </vue-countdown>
              </p>
              <div
                class="w-full h-2 rounded overflow-hidden bg-gray-100 relative"
              >
                <span
                  class="block h-full rounded top-1/2 -translate-y-1/2 bg-primary absolute"
                  :style="{
                    width: percentage + '%',
                  }"
                ></span>
              </div>
            </blockquote>
          </figure>
        </div>
        <!-- ./trial days card -->
        <router-view />
      </div>

      <!-- pos cart -->
      <PosCart ref="posCart" />
      <!-- ./pos cart -->
    </div>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import Modal from "@/components/basics/Modal.vue";
import Footer from "@/components/layouts/Footer.vue";
import Header from "@/components/layouts/Header.vue";
import SideBar from "@/components/layouts/SideBar.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ButtonWithLoader from "./components/basics/ButtonWithLoader.vue";
import PosCart from "./components/basics/PosCart.vue";
export default {
  components: {
    Header,
    Footer,
    Modal,
    ButtonWithLoader,
    SideBar,
    PosCart,
  },
  data() {
    return {
      active: false,
      store_id: parseInt(localStorage.getItem("store")) || null,
      stores: [],
      percentage: 0,
    };
  },
  watch: {
    "$route.name"() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      document.title = this.$route.meta.title;
      this.resetPagination();
      this.updateSearch(undefined);
    },
    getMessage(val) {
      if (val) {
        this.checkPermissions({
          title: "New reply from ticket",
          body: val.body,
        });
      }
    },
  },
  filters: {
    handleDate(value) {
      const hour = new Date(value).getHours();
      if (hour >= 12 && hour < 17) {
        return "Good afternoon";
      } else if (hour < 12) {
        return "Good morning";
      } else if (hour >= 17) {
        return "Good evening";
      } else {
        return "Good evening";
      }
      // return hour;
    },
  },
  computed: {
    ...mapGetters("root", ["is_handover", "is_first", "enabled_notifications"]),

    ...mapGetters({
      getUser: "getUserInfo",
      getMessage: "chat/getMessage",
    }),
    ...mapGetters("Cart", {
      globalCart: "cart",
    }),
    user() {
      return this.$store.state.user || {};
    },
    cart() {
      const user = this.$store.state.user;
      const store_id = parseInt(localStorage.getItem("store")) || null;

      return (
        this.globalCart(`${user.user_type}_${user.id}`)?.filter(
          (e) => e.store?.id === store_id
        ) || []
      );
    },
    day() {
      return this.$moment(new Date(this.user?.trial_end_date)).diff(
        this.$moment(new Date()),
        "days",
        true
      );
    },
    TotalDays() {
      return this.$moment(new Date(this.user?.trial_end_date)).diff(
        this.$moment(this.user?.trial_start_date),
        "days",
        true
      );
    },
    isAfter() {
      const date =
        new Date(this.user?.trial_end_date).getTime() - new Date().getTime();
      return isNaN(date) ? false : date > 0;
    },
    fullPath() {
      let result;
      const path = this.$route.path;

      console.log(path);

      result = path
        .split("/")
        .filter((e) => e)
        .map((e) => '<a href="' + e + '">' + e + "</a>")
        .join("/");

      return result;
    },
  },
  async created() {
    // if (!this.enabled_notifications) {
    //   this.checkPermissions({
    //     title: "Hi there!",
    //     body: "The notification has been successfully accessed",
    //   });
    // }
    // get stores
    const token = this.$store.state.token || this.$cookies.get("token");
    if (token) {
      Promise.all([await this.getStores()]);
      if (this.is_handover) {
        this.$handover.show("open");
        // console.log(this.$handover);
      }
    }
  },

  methods: {
    ...mapMutations("root", ["SET_HANDOVER", "SET_IS_FIRST"]),
    ...mapMutations("chat", ["UPDATE_MESSAGE"]),
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["resetPagination", "updateSearch"]),
    openWaPopup() {
      document.querySelector(".wa-box").classList.toggle("hidden");
    },
    openBar() {
      this.active = true;
    },
    async getStores() {
      try {
        const { result } = await this.getRoute("stores");
        this.stores = result.stores;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    openCart() {
      this.$refs.posCart?.openCloseCart();
    },
    openCloseSideBar() {
      const sidebar = this.$refs.sideBar?.$el;
      if (!sidebar) return;
      if (sidebar.classList.contains("opacity-0")) {
        sidebar.classList.remove(
          ...["opacity-0", "pointer-events-none", "invisible", "left-full"]
        );
      } else {
        sidebar.classList.add(
          ...["opacity-0", "pointer-events-none", "invisible", "left-full"]
        );
      }
    },
    async getPercentage() {
      let percentage = await new Promise((resolve) => {
        let interval;
        interval = setInterval(() => {
          let result = 0;
          const totalDays = Math.floor(this.TotalDays);
          const remainingDays = Math.floor(this.day);
          result = (remainingDays / totalDays) * 100;
          if (result === 0) {
            clearInterval(interval);
            return;
          }

          resolve(result);
        }, 1000);
      });
      this.percentage = percentage;
    },
    handleProgress(e) {
      let keys = {},
        needed_keys = ["days", "hours", "minutes", "seconds"],
        done = false;

      for (const key of Object.keys(e).filter((k) => needed_keys.includes(k))) {
        keys[key] = e[key];
      }

      done = Object.values(keys).every((k) => k === 0);
      if (!done) return;
      window.location.reload();
    },
  },
};
</script>
