<template>
  <modal
    :title="title || 'New account'"
    size="!max-w-screen-md"
    @close="show_active = false"
    v-show="show_active"
  >
    <form class="space-y-4 p-6">
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div class="form-group space-y-3">
          <label class="form-label">Name | ar</label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="..."
            v-model="add_data.name"
          />
          <p class="form-error">{{ add_errors.name }}</p>
        </div>
        <div class="form-group space-y-3">
          <label class="form-label">Name | en</label>
          <input
            type="text"
            name="alt_name"
            id="alt_name"
            placeholder="..."
            v-model="add_data.alt_name"
          />
          <p class="form-error">{{ add_errors.alt_name }}</p>
        </div>
        <div class="form-group space-y-3">
          <label class="form-label">Description | ar</label>
          <textarea
            name="desc"
            id="desc"
            placeholder="..."
            v-model="add_data.desc"
          ></textarea>
          <p class="form-error">{{ add_errors.desc }}</p>
        </div>
        <div class="form-group space-y-3">
          <label class="form-label">Description | en</label>
          <textarea
            name="alt_desc"
            id="alt_desc"
            placeholder="..."
            v-model="add_data.alt_desc"
          ></textarea>
          <p class="form-error">{{ add_errors.alt_desc }}</p>
        </div>
        <div class="form-group space-y-3 col-span-full">
          <label class="form-label">Type</label>
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div class="form-group-with-check">
              <input
                type="radio"
                name="type"
                id="debit"
                value="debit"
                v-model="add_data.type"
              />
              <label for="debit">Debit</label>
            </div>
            <div class="form-group-with-check">
              <input
                type="radio"
                name="type"
                id="credit"
                value="credit"
                v-model="add_data.type"
              />
              <label for="credit">Credit</label>
            </div>
            <div class="form-group-with-check col-span-full">
              <input
                type="checkbox"
                name="is_details"
                id="is_details"
                v-model="add_data.is_details"
                :true-value="1"
                :false-value="0"
              />
              <label for="is_details">Detailed account</label>
            </div>
          </div>
          <p class="form-error">{{ add_errors.is_details }}</p>
        </div>
      </div>
      <button-with-loader
        :disabled="add_disabled"
        btnClass="bg-primary p-3 py-2 rounded text-xs font-medium rtl:font-semibold text-white"
        @click="addAccount"
      >
        Submit
      </button-with-loader>
    </form>
  </modal>
</template>

<script>
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import Modal from "../basics/Modal.vue";

export default {
  components: { Modal, ButtonWithLoader },
  name: "AddAccount",
  props: {
    id: {
      type: [String, Number],
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      show_active: false,
      add_data: {},
      add_errors: {},
      add_disabled: false,
    };
  },
  watch: {
    show_active(val) {
      if (!val) {
        this.add_data = {};
        this.add_errors = {};
        this.add_disabled = false;
      }
    },
  },
  methods: {
    async addAccount() {
      try {
        this.add_disabled = true;
        const { data } = await this.axios.post("accounts/add", {
          ...this.add_data,
          parent_id: this.id,
        });
        this.createAlert(data.message);
        this.show_active = false;
        this.$emit("change", true);
      } catch (error) {
        const response = error.response?.data;
        const message = response.message;
        if (message instanceof Object) {
          this.add_errors = message;
        } else {
          this.createAlert(message, "error");
        }
      } finally {
        this.add_disabled = false;
      }
    },
    show() {
      this.show_active = true;
    },
    hide() {
      this.show_active = false;
    },
  },
};
</script>
