<template>
  <div class="min-h-screen flex flex-col">
    <div class="flex-1">
      <div class="xl:container">
        <div class="space-y-8 px-6 py-10">
          <div class="space-y-2 text-center">
            <p class="text-3xl font-bold">Cashier device</p>
            <p class="text-base text-gray-500">
              Select the cashier device through which you want to make sales,
              and you will be directed to the sales page
            </p>
          </div>
          <div v-if="load" class="mx-auto block max-w-fit">
            <loading-snack />
          </div>
          <ul
            class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4"
            v-else
          >
            <li v-for="device in devices" :key="device.id">
              <input
                type="radio"
                name="devices"
                :id="device.id"
                :value="device.id"
                v-model="device_id"
                class="peer"
                hidden
              />
              <label
                :for="device.id"
                class="w-full flex items-center p-6 border-2 border-gray-200 rounded-lg transition cursor-pointer gap-4 peer-checked:border-primary peer-checked:bg-primary/5"
              >
                <span class="shrink-0">
                  <i
                    class="fa-regular fa-circle text-gray-400 fa-lg"
                    v-if="device.id !== device_id"
                  ></i>
                  <i
                    class="fa-solid fa-check-circle text-primary fa-lg"
                    v-else
                  ></i>
                </span>
                <div class="flex-1">
                  <p class="text-base font-bold">
                    {{ device.serial_no || device.code }}
                  </p>
                  <p class="text-sm line-clamp-1 text-gray-500">
                    {{ device.store?.["name_" + $i18n.locale] }}
                  </p>
                </div>
              </label>
            </li>
            <li v-if="!devices.length" class="col-span-full">
              <p class="text-sm text-gray-500 text-center">
                There are no devices
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <bottom-controlling :disabled="!device_id" @click="updateSelectedDevice" />
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import LoadingSnack from "../basics/LoadingSnack.vue";
import BottomControlling from "./BottomControlling.vue";
export default {
  components: { LoadingSnack, BottomControlling },
  name: "SelectCashierDevice",
  data() {
    return {
      device_id: null,
      devices: [],
      load: true,
    };
  },
  created() {
    this.getDevices();
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("pos_system", ["updateDevice"]),
    async getDevices() {
      try {
        this.load = true;
        const { result } = await this.getRoute({
          name: "pos_devices",
          options: {
            is_active: 1,
          },
        });
        this.devices = result.pos_devices;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.load = false;
      }
    },
    updateSelectedDevice() {
      const device = this.devices.find((dev) => dev.id == this.device_id);
      console.log("device saved!!");
      this.updateDevice(device);
    },
  },
};
</script>
