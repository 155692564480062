<template>
  <section
    class="w-full relative flex flex-col-reverse md:flex-row items-stretch min-h-screen bg-white"
  >
    <aside
      class="relative bg-auth bg-no-repeat bg-cover before:bg-primary/50 w-full md:w-1/3 2xl:max-w-screen-md flex items-end justify-end p-6 py-20"
    >
      <figure class="w-full relative flex flex-col gap-4">
        <figcaption
          class="flex self-start p-2.5 rounded-full bg-primary gap-3 [padding-inline-end:2rem]"
        >
          <div>
            <div
              class="w-8 h-8 rounded-full flex items-center justify-center bg-black/50"
            >
              <i class="fa-solid fa-bolt-lightning text-white fa-sm"></i>
            </div>
          </div>
          <div class="flex-1 self-center">
            <p
              class="text-white font-semibold rtl:font-bold line-clamp-1 text-sm"
            >
              Point-of-sale
            </p>
          </div>
        </figcaption>
        <blockquote
          class="self-start p-4 rounded-lg bg-white/20 backdrop-blur-lg"
        >
          <p class="text-white font-semibold rtl:font-bold text-sm">
            Today, we create innovative solutions to the challenges that
            consumers face in both their everyday lives and events.
          </p>
        </blockquote>
      </figure>
    </aside>
    <main
      class="w-full md:w-2/3 flex-1 py-20 px-8 flex items-center justify-center"
    >
      <slot></slot>
    </main>
  </section>
</template>
<script>
export default {
  name: "Wrapper",
};
</script>
<style lang="scss">
.bg-auth {
  background-image: url("@/assets/images/pos-auth.jpg");
  &::before {
    content: "";
    @apply absolute inset-0 w-full h-full;
  }
}
</style>
